import { Token } from '@dolomite-exchange/v2-sdk'
import { SPECIAL_ASSET_MAP, SpecialAssetSymbol } from '../special-assets'
import { BRIDGED_USDC, USDC } from '../../tokens/USDC'
import { DAI } from '../../tokens/DAI'
import { USDT } from '../../tokens/USDT'
import { MIM } from '../../tokens/MIM'
import { ChainId, ChainIdMap } from '../../chainId'
import { GRAI } from '../../tokens/GRAI'
import { CHAIN_ID_TO_ZAP_MAP } from '../../../apollo/client'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

export function getGmCollateralTokens(symbol: SpecialAssetSymbol, indexToken: Token | undefined): Token[] {
  const tokens = getGmDebtTokens(indexToken)
  if (tokens.length === 0 || !indexToken) {
    return []
  }
  return [...tokens, SPECIAL_ASSET_MAP[symbol]!.isolationModeInfo!.getWrappedToken(indexToken.chainId)!]
}

export function getGmDebtTokens(indexToken: Token | undefined): Token[] {
  if (!indexToken || indexToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  const chainId = indexToken.chainId as ChainId
  return [
    USDC[chainId],
    BRIDGED_USDC[chainId]!,
    DAI[chainId]!,
    GRAI[chainId]!,
    USDT[chainId]!,
    MIM[chainId]!,
    indexToken,
  ]
}

export function getGmUsdValidInputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  const chainId = longToken.chainId as ChainId
  return [USDC[chainId], longToken]
}

export function getGmUsdValidOutputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  const chainId = longToken.chainId as ChainId
  return [USDC[chainId], longToken]
}

export function getGmValidInputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  return [longToken]
}

export function getGmValidOutputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  return [longToken]
}

export function getPendlePtInterestRateUrl(
  chainId: ChainId,
  isolationModeTokenAddressMap: ChainIdMap<string | undefined>,
): string | null {
  const isolationModeAddress = isolationModeTokenAddressMap[chainId]
  if (!isolationModeAddress) {
    return null
  }
  const ptMarket = CHAIN_ID_TO_ZAP_MAP[chainId]?.getPendleMarketByIsolationModeAddress(isolationModeAddress)
  if (!ptMarket) {
    return null
  }

  return `${DOLOMITE_API_SERVER_URL}/interest-rates/${chainId}/pendle-v2/${ptMarket}/pt`
}
