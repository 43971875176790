import React, { useEffect, useMemo, useState } from 'react'
import { PageSizeContext } from '../../pages/History'
import { useTranslation } from 'react-i18next'
import { ColumnTitles, ConnectBtn, ConnectWalletButton, DataRows } from './styled'
import Web3Status from '../../components/Web3Status'
import Column from '../../components/Orders/Column'
import DataRow from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { Fraction } from '@dolomite-exchange/v2-sdk'
import { getEtherscanLink } from '../../utils'
import { useAmmMintDataByWallet } from '../../types/ammMintData'
import { useAmmBurnDataByWallet } from '../../types/ammBurnData'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { TableLoader } from '../Loader'

export default function Pooling() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: mints, loading: mintsLoading } = useAmmMintDataByWallet(account, 0) // TODO - add pagination
  const { data: burns, loading: burnsLoading } = useAmmBurnDataByWallet(account, 0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    isLoading && !mintsLoading && !burnsLoading && setIsLoading(false)
  }, [mintsLoading, burnsLoading, isLoading])

  const poolingActions = useMemo(() => {
    return mints.concat(burns).sort((transfer1, transfer2) => {
      return transfer2.transaction.timestamp.getTime() - transfer1.transaction.timestamp.getTime()
    })
  }, [mints, burns])

  const widths = {
    widths: [15, 12, 10, 10, 10],
    starts: [3, 29, 51, 70, 87],
    mobileWidths: [15, 15, 15],
    mobileStarts: [3, 53, 82],
  }

  return (
    <PageSizeContext.Consumer>
      {({ x }) => {
        const isMobile = x < 960
        const isTablet = 660 < x && x < 960
        return (
          <>
            <ColumnTitles>
              <Column width={20} mobileWidth={25} textAlign={'left'}>
                {t('action')}
              </Column>
              <Column width={12} mobileWidth={20} textAlign={'right'} hideOnMobile>
                {t('totalValue')}
              </Column>
              <Column width={12} mobileWidth={20} textAlign={'right'} hideOnMobile>
                {t('amount')}
              </Column>
              <Column width={12} mobileWidth={40} textAlign={'right'}>
                {isMobile ? t('amounts') : t('amount')}
              </Column>
              {/*<Column width={15} hideOnMobile={true} textAlign={isMobile && !isTablet ? 'right' : 'left'}>
                {t('transaction')}
              </Column>*/}
              <Column width={10} mobileWidth={25} textAlign={'right'}>
                {t('date')}
              </Column>
            </ColumnTitles>
            <DataRows>
              {account ? (
                isLoading ? (
                  <TableLoader
                    rows={isMobile ? 5 : isTablet ? 4 : 3}
                    height={30}
                    spacing={55}
                    marginTop={20}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    widths={widths}
                  />
                ) : (
                  poolingActions.map((action, index) => (
                    <DataRow
                      key={index}
                      columns={[
                        {
                          width: 20,
                          mobileWidth: 25,
                          textAlign: 'left',
                          type: 'link',
                          data: `${action.isMint ? 'Add' : 'Withdraw'} ${
                            isMobile
                              ? ''
                              : `${cleanCurrencySymbol(action.token0)} and ${cleanCurrencySymbol(action.token1)}`
                          }`,
                          link: getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction'),
                        },
                        {
                          width: 12,
                          mobileWidth: 20,
                          textAlign: 'right',
                          type: 'amount',
                          data: action.amountUSD as Fraction,
                          isUSD: true,
                          hideOnMobile: true,
                        },
                        {
                          width: 12,
                          mobileWidth: 40,
                          textAlign: 'right',
                          type: isMobile ? 'amountsStacked' : 'amount',
                          data: action.amount0 as Fraction,
                          token: action.token0,
                          amount2: isMobile ? (action.amount1 as Fraction) : undefined,
                          token2: isMobile ? action.token1 : undefined,
                        },
                        {
                          width: 12,
                          mobileWidth: 35,
                          textAlign: 'right',
                          type: 'amount',
                          data: action.amount1 as Fraction,
                          token: action.token1,
                          hideOnMobile: true,
                        },
                        /*{
                          width: 15,
                          textAlign: isMobile && !isTablet ? 'right' : 'left',
                          type: 'transaction',
                          data: action.transaction.transactionHash,
                          hideOnMobile: true,
                        },*/
                        {
                          width: 10,
                          mobileWidth: 25,
                          textAlign: 'right',
                          type: 'time',
                          data: undefined,
                          date: action.transaction.timestamp,
                        },
                      ]}
                      enableExpanding={false}
                      history
                      contentHeight={36}
                    />
                  ))
                )
              ) : (
                <ConnectWalletButton>
                  <ConnectBtn>
                    <Web3Status />
                  </ConnectBtn>
                </ConnectWalletButton>
              )}
            </DataRows>
          </>
        )
      }}
    </PageSizeContext.Consumer>
  )
}
