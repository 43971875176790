import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PageTitle from '../../components/common/PageTitle'
import BarChart, { VolumeWindow } from '../../components/Stats/Charts/BarChart'
import LineChart from '../../components/Stats/Charts/LineChart'
import {
  Button,
  ChartLabel,
  ChartRow,
  ChartText,
  ChartTextLarge,
  ChartTextMedium,
  ChartValue,
  RowFixed,
} from '../../components/Stats/Charts/styled'
import { SimpleLoader } from '../../components/Loader'
import { useTopPairData } from '../../types/topPairData'
import { useTopTokenData } from '../../types/topTokenData'
import { DolomiteDayData, useCurrentYearDolomiteDayDataData } from '../../types/dolomiteDayData'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import useTheme from '../../hooks/useTheme'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { ZERO_FRACTION, ChainId } from '../../constants'
import { sumFractions } from '../../utils/numberOperations'
import TOSPopover from '../../components/TOSPopover'
import { useCurrentDayDolomiteHourData } from '../../types/dolomiteHourData'
import useApprovedToS from '../../hooks/useApprovedToS'
import { useActiveWeb3React } from '../../hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { formatAmount } from '../../utils/formatAmount'
import { tryParseAmount } from '../../state/trade/hooks'
import { useInterestRateData } from '../../types/interestRateData'
import TokenTable from '../../components/Stats/Tables/TokenTable'
import TradeTable from '../../components/Stats/Tables/TradeTable'
import TransferTable from '../../components/Stats/Tables/TransferTable'
import Liquidations from '../../components/Stats/Tables/Liquidations'
import PoolTable from '../../components/Stats/Tables/PoolTable'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useLiquidationDataByTime } from '../../types/liquidationData'
import { useTransfersByTime } from '../../types/depositWithdrawalData'
import { useTradeDataByTime } from '../../types/tradeData'

dayjs.extend(weekOfYear)

const TRADING = 0
const TRANSFERS = 1
const LIQUIDATIONS = 2

const StatsWrapper = styled.div`
  margin: 25px auto 35px;
  width: calc(90% - 80px);
  max-width: 1000px;

  @media screen and (max-width: 960px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 215px);
  }
`

const OverviewWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`

const ChartWrapper = styled.div`
  width: 49%;
  display: inline-block;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const StatTitle = styled.div`
  margin-top: 10px;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: 15px !important;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => <Tab classes={{ root: 'root', selected: 'selected' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const Trades = styled.div`
  width: 100%;
  margin: 10px 0 30px;

  @media screen and (max-width: 880px) {
    margin-bottom: 90px;
  }
`

// export const TopMovers = styled.div`
//   background-color: ${({ theme }) => theme.bg1};
//   border-radius: 8px;
//   margin: 30px 0 10px;
//   width: 100%;
//   font-family: 'Open Sans', sans-serif;
//   box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
//   padding: 10px 15px;
// `
//
// const MoverTitle = styled.div`
//   color: ${({ theme }) => theme.text3};
//   font-weight: 600;
//   margin: 5px;
// `
//
// export const ScrollableWrapper = styled.div`
//   display: grid;
//   grid-auto-rows: auto;
//   grid-row-gap: 12px;
// `
//
// export const ScrollableRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   overflow-x: auto;
//   white-space: nowrap;
//   ::-webkit-scrollbar {
//     display: none;
//   }
// `

export const FooterSpacing = styled.div`
  height: 0;
  @media (max-width: 660px) {
    height: 100px;
  }
`

const StatLoaderWrapper = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`

export function unixToDate(unix: number, format = 'YYYY-MM-DD'): string {
  return dayjs
    .unix(unix)
    .utc()
    .format(format)
}

function unixToType(unix: number, type: 'month' | 'week') {
  const date = dayjs.unix(unix).utc()

  switch (type) {
    case 'month':
      return date.format('YYYY-MM')
    case 'week':
      return unixToDate(Math.floor(unix / 604800) * 604800)
  }
}

export interface ChartData {
  time: string
  timeRaw: number
  value: number
}

export const StatLoader = ({
  data,
  loading,
  x,
  y,
  top,
  height,
}: {
  data: any
  loading: boolean
  x?: number
  y?: number
  top?: number
  height?: number
}) => (
  <StatLoaderWrapper height={height}>
    {loading ? (
      <SimpleLoader
        width={x ?? 150}
        height={y ?? 28}
        spacing={y ? (y && top ? y + top : y) : 37}
        marginTop={top ?? 9}
      />
    ) : (
      data
    )}
  </StatLoaderWrapper>
)

function useGroupedVolumeData(chartData: DolomiteDayData[] | undefined, type: 'month' | 'week') {
  return useMemo(() => {
    if (chartData) {
      const data: Record<string, ChartData> = {}
      chartData.forEach(
        ({ dayStartUnix, dailyTradeVolumeUSD }: { dayStartUnix: number; dailyTradeVolumeUSD: Fraction }) => {
          const volumeUSD = parseFloat(dailyTradeVolumeUSD.toFixed(2))
          const group = unixToType(dayStartUnix, type)
          if (data[group]) {
            data[group].value += volumeUSD
          } else {
            data[group] = {
              time: group,
              timeRaw: dayStartUnix,
              value: volumeUSD,
            }
          }
        },
      )

      return Object.values(data)
    } else {
      return []
    }
  }, [chartData, type])
}

export const PageSizeContext = React.createContext({
  x: window.innerWidth,
  y: window.innerHeight,
})

export default function Stats() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { data: pools, loading: isPoolLoading } = useTopPairData()
  const { data: topTokens, loading: isTokenLoading } = useTopTokenData()
  const [fiatMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => topTokens.map(t => t.token), [topTokens]))
  const { data: interestRateMap } = useInterestRateData()
  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.daily)
  const [loading, setLoading] = useState<boolean>(true)
  const { data: dayData } = useCurrentYearDolomiteDayDataData()
  const [supplyLabel1, setSupplyLabel1] = useState<number | undefined>()
  const [supplyLabel2, setSupplyLabel2] = useState<string | undefined>()
  const [borrowLabel1, setBorrowLabel1] = useState<number | undefined>()
  const [borrowLabel2, setBorrowLabel2] = useState<string | undefined>()
  const [volumeLabel1, setVolumeLabel1] = useState<number | undefined>()
  const [volumeLabel2, setVolumeLabel2] = useState<string | undefined>()
  const [poolLiquidityLabel1, setPoolLiquidityLabel1] = useState<number | undefined>()
  const [poolLiquidityLabel2, setPoolLiquidityLabel2] = useState<string | undefined>()
  const [selectedTab, setSelectedTab] = useState<number>(TRADING)
  const { data: liquidationData, loading: isLiquidationsLoading } = useLiquidationDataByTime()
  const { data: transferData, loading: isTransfersLoading } = useTransfersByTime()
  const { data: tradeData, loading: isTradesLoading } = useTradeDataByTime()
  const theme = useTheme()
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const supplyLabel1Fraction = useMemo(() => {
    const value = tryParseAmount(supplyLabel1?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [supplyLabel1])
  const borrowLabel1Fraction = useMemo(() => {
    const value = tryParseAmount(borrowLabel1?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [borrowLabel1])
  const volumeLabel1Fraction = useMemo(() => {
    const value = tryParseAmount(volumeLabel1?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [volumeLabel1])
  const poolLiquidityLabel1Fraction = useMemo(() => {
    const value = tryParseAmount(poolLiquidityLabel1?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [poolLiquidityLabel1])
  const updateSize = useCallback(
    () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
      }),
    [],
  )
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [updateSize])

  useEffect(() => {
    if (typeof dayData !== 'undefined') {
      setLoading(false)
    }
  }, [dayData])

  const poolIncreaseRef = useRef<HTMLDivElement>(null)
  const [poolIncreaseSet, setPoolIncreaseSet] = useState(false)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()

  useEffect(() => {
    if (!poolIncreaseSet && poolIncreaseRef && poolIncreaseRef.current) {
      setInterval(() => {
        if (poolIncreaseRef.current) {
          if (
            poolIncreaseRef.current.scrollLeft + poolIncreaseRef.current.offsetWidth !==
            poolIncreaseRef.current.scrollWidth
          ) {
            poolIncreaseRef.current.scrollTo(poolIncreaseRef.current.scrollLeft + 1, 0)
          } else {
            poolIncreaseRef.current.scrollTo(0, 0)
          }
        }
      }, 30)
      setPoolIncreaseSet(true)
    }
  }, [poolIncreaseRef, poolIncreaseSet])

  const tokenIncreaseRef = useRef<HTMLDivElement>(null)
  const [tokenIncreaseSet, setTokenIncreaseSet] = useState(false)

  useEffect(() => {
    if (!tokenIncreaseSet && tokenIncreaseRef && tokenIncreaseRef.current) {
      setInterval(() => {
        if (tokenIncreaseRef.current) {
          if (
            tokenIncreaseRef.current.scrollLeft + tokenIncreaseRef.current.offsetWidth !==
            tokenIncreaseRef.current.scrollWidth
          ) {
            tokenIncreaseRef.current.scrollTo(tokenIncreaseRef.current.scrollLeft + 1, 0)
          } else {
            tokenIncreaseRef.current.scrollTo(0, 0)
          }
        }
      }, 30)
      setTokenIncreaseSet(true)
    }
  }, [tokenIncreaseRef, tokenIncreaseSet])

  const formattedPoolData = useMemo(() => {
    if (dayData) {
      return dayData.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.ammLiquidityUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [dayData])
  const currentPoolLiquidity = useMemo(() => {
    return dayData ? parseFloat(dayData[dayData.length - 1]?.ammLiquidityUSD?.toFixed(0)) : 0
  }, [dayData])
  const formattedSupplyData = useMemo(() => {
    if (dayData) {
      return dayData.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.supplyLiquidityUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [dayData])
  const currentSupplyLiquidity = useMemo(() => {
    if (Object.keys(fiatMap).length !== topTokens.length || topTokens.length === 0) {
      return dayData ? parseFloat(dayData[dayData.length - 1]?.supplyLiquidityUSD?.toFixed(0)) : 0
    }

    return topTokens.reduce((memo, token) => {
      const fiatValue = fiatMap[token.token.address] ?? ZERO_FRACTION
      return memo + parseFloat(token.supplyLiquidity.multiply(fiatValue).toFixed(0))
    }, 0)
  }, [dayData, fiatMap, topTokens])

  const formattedBorrowData = useMemo(() => {
    if (dayData) {
      return dayData.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.borrowLiquidityUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [dayData])
  const currentBorrowLiquidity = useMemo(() => {
    if (Object.keys(fiatMap).length !== topTokens.length || topTokens.length === 0) {
      return dayData ? parseFloat(dayData[dayData.length - 1]?.borrowLiquidityUSD?.toFixed(0)) : 0
    }

    return topTokens.reduce((memo, token) => {
      const fiatValue = fiatMap[token.token.address] ?? ZERO_FRACTION
      return memo + parseFloat(token.borrowLiquidity.multiply(fiatValue).toFixed(0))
    }, 0)
  }, [dayData, fiatMap, topTokens])

  const formattedVolumeData = useMemo(() => {
    if (dayData) {
      return dayData.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.dailyTradeVolumeUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [dayData])
  const { data: currentDayDolomiteHourlyData } = useCurrentDayDolomiteHourData()

  const currentVolume = useMemo(() => {
    if (!currentDayDolomiteHourlyData) {
      return ZERO_FRACTION
    }
    return sumFractions(currentDayDolomiteHourlyData, value => value.hourlyTradeVolumeUSD)
  }, [currentDayDolomiteHourlyData])
  const weeklyVolumeData = useGroupedVolumeData(dayData, 'week')
  const monthlyVolumeData = useGroupedVolumeData(dayData, 'month')

  const volumeData = useMemo(() => {
    return volumeWindow === VolumeWindow.daily
      ? formattedVolumeData
      : volumeWindow === VolumeWindow.weekly
      ? weeklyVolumeData
      : monthlyVolumeData
  }, [volumeWindow, formattedVolumeData, weeklyVolumeData, monthlyVolumeData])
  const options = [t('trades'), t('transfers'), t('liquidations')]

  useEffect(() => {
    setSupplyLabel1(undefined)
    setBorrowLabel1(undefined)
    setPoolLiquidityLabel1(undefined)
    setVolumeLabel1(undefined)
  }, [chainId])

  useEffect(() => {
    if (!supplyLabel2 && currentSupplyLiquidity > 0) {
      setSupplyLabel1(currentSupplyLiquidity)
    }
  }, [currentSupplyLiquidity, supplyLabel2])

  useEffect(() => {
    if (!borrowLabel2 && currentBorrowLiquidity > 0) {
      setBorrowLabel1(currentBorrowLiquidity)
    }
  }, [borrowLabel2, currentBorrowLiquidity])

  useEffect(() => {
    if (!poolLiquidityLabel2 && currentPoolLiquidity > 0) {
      setPoolLiquidityLabel1(currentPoolLiquidity)
    }
  }, [currentPoolLiquidity, poolLiquidityLabel2])

  useEffect(() => {
    if (!volumeLabel2 && currentVolume.greaterThan(ZERO_FRACTION)) {
      setVolumeLabel1(parseFloat(currentVolume.toFixed(2)))
    }
  }, [currentVolume, volumeLabel2])

  return (
    <PageSizeContext.Provider value={size}>
      <StatsWrapper>
        <PageTitle
          title={
            size.x >= 5000
              ? chainId === ChainId.BERACHAIN
                ? 'Bolomite | Super Ultrawide'
                : 'Dolomite | Super Ultrawide'
              : chainId === ChainId.BERACHAIN
              ? 'Bolomite | Stats'
              : 'Dolomite | Stats'
          }
        />
        {!hasApprovedToS && <TOSPopover setApprovedToS={setHasApprovedToS} />}
        <OverviewWrapper>
          <ChartRow>
            <ChartWrapper>
              <LineChart
                data={formattedSupplyData}
                loading={loading}
                height={220}
                minHeight={332}
                color={theme.blue1}
                value={supplyLabel1}
                label={supplyLabel2}
                setValue={setSupplyLabel1}
                setLabel={setSupplyLabel2}
                defaultValue={currentSupplyLiquidity}
                topLeft={
                  <ChartLabel>
                    <ChartTextMedium>Total Supply Liquidity</ChartTextMedium>
                    <ChartTextLarge>
                      <StatLoader
                        data={<ChartValue>${formatAmount(supplyLabel1Fraction).split('.')[0]}</ChartValue>}
                        loading={loading}
                      />
                    </ChartTextLarge>
                    <ChartText>{supplyLabel2 ? <ChartValue>{supplyLabel2}</ChartValue> : null}</ChartText>
                  </ChartLabel>
                }
              />
            </ChartWrapper>
            <ChartWrapper>
              <LineChart
                data={formattedBorrowData}
                loading={loading}
                height={220}
                minHeight={332}
                color={theme.blue1}
                value={borrowLabel1}
                label={borrowLabel2}
                setValue={setBorrowLabel1}
                setLabel={setBorrowLabel2}
                defaultValue={currentBorrowLiquidity}
                topLeft={
                  <ChartLabel>
                    <ChartTextMedium>Total Value Borrowed</ChartTextMedium>
                    <ChartTextLarge>
                      <StatLoader
                        data={<ChartValue>${formatAmount(borrowLabel1Fraction).split('.')[0]}</ChartValue>}
                        loading={loading}
                      />
                    </ChartTextLarge>
                    <ChartText>{borrowLabel2 ? <ChartValue>{borrowLabel2}</ChartValue> : null}</ChartText>
                  </ChartLabel>
                }
              />
            </ChartWrapper>
          </ChartRow>
        </OverviewWrapper>
        <StatTitle>
          <h3>{t('topTokens')}</h3>
        </StatTitle>
        <TokenTable tokens={topTokens} fiatMap={fiatMap} interestRateMap={interestRateMap} isLoading={isTokenLoading} />
        {/*<TopMovers>
          <MoverTitle>{t('topMovers')}</MoverTitle>
          {isTokenLoading && !tokens ? (
            <></>
          ) : (
            <ScrollableWrapper>
              <ScrollableRow ref={tokenIncreaseRef}>
                {topPriceIncrease.map((token, index) => (
                  <TokenMover key={`topMovers-token-${index.toString()}`} token={token} />
                ))}
              </ScrollableRow>
            </ScrollableWrapper>
          )}
        </TopMovers>*/}
        <ChartRow marginTop={25}>
          {chainId === ChainId.ARBITRUM_ONE && (
            <ChartWrapper>
              <LineChart
                data={formattedPoolData}
                loading={loading}
                height={220}
                minHeight={332}
                color={theme.blue1}
                value={poolLiquidityLabel1}
                label={poolLiquidityLabel2}
                setValue={setPoolLiquidityLabel1}
                setLabel={setPoolLiquidityLabel2}
                topLeft={
                  <ChartLabel>
                    <ChartTextMedium>Total Pool Liquidity</ChartTextMedium>
                    <ChartTextLarge>
                      <StatLoader
                        data={<ChartValue>${formatAmount(poolLiquidityLabel1Fraction).split('.')[0]}</ChartValue>}
                        loading={loading}
                      />
                    </ChartTextLarge>
                    <ChartText>{poolLiquidityLabel2 ? <ChartValue>{poolLiquidityLabel2}</ChartValue> : null}</ChartText>
                  </ChartLabel>
                }
              />
            </ChartWrapper>
          )}
          <ChartWrapper>
            <BarChart
              data={volumeData}
              loading={loading}
              height={220}
              minHeight={332}
              color={theme.blue1}
              setValue={setVolumeLabel1}
              setLabel={setVolumeLabel2}
              value={volumeLabel1}
              label={volumeLabel2}
              activeWindow={volumeWindow}
              topRight={
                <RowFixed
                  style={{
                    marginLeft: '-40px',
                    marginTop: '8px',
                  }}
                >
                  <Button
                    active={volumeWindow === VolumeWindow.daily}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.daily)}
                  >
                    D
                  </Button>
                  <Button
                    active={volumeWindow === VolumeWindow.weekly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                  >
                    W
                  </Button>
                  <Button
                    active={volumeWindow === VolumeWindow.monthly}
                    onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                  >
                    M
                  </Button>
                </RowFixed>
              }
              topLeft={
                <ChartLabel>
                  <ChartTextMedium>
                    {volumeLabel2
                      ? volumeWindow === VolumeWindow.monthly
                        ? 'Volume 1M'
                        : volumeWindow === VolumeWindow.weekly
                        ? 'Volume 1W'
                        : volumeWindow === VolumeWindow.daily
                        ? 'Volume 1D'
                        : 'Volume 24H'
                      : 'Volume 24H'}
                  </ChartTextMedium>
                  <ChartTextLarge>
                    <StatLoader
                      data={<ChartValue>${formatAmount(volumeLabel1Fraction).split('.')[0]}</ChartValue>}
                      loading={loading}
                    />
                  </ChartTextLarge>
                  <ChartText>{volumeLabel2 ? <ChartValue>{volumeLabel2}</ChartValue> : null}</ChartText>
                </ChartLabel>
              }
            />
          </ChartWrapper>
        </ChartRow>
        {chainId === ChainId.ARBITRUM_ONE && (
          <>
            <StatTitle>
              <h3>{t('topPools')}</h3>
            </StatTitle>
            <PoolTable pools={pools} isLoading={isPoolLoading} />
          </>
        )}
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => setSelectedTab(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(options ?? []).map((option: string, index: number) => (
            <StyledTab key={`actions-${index}`} disableRipple label={option} onClick={() => setSelectedTab(index)} />
          ))}
        </StyledTabs>
        <Trades>
          {selectedTab === TRADING ? (
            <TradeTable trades={tradeData} isLoading={isTradesLoading} />
          ) : selectedTab === TRANSFERS ? (
            <TransferTable transfers={transferData} isLoading={isTransfersLoading} />
          ) : (
            <Liquidations liquidations={liquidationData} isLoading={isLiquidationsLoading} />
          )}
        </Trades>
        {/*<TopMovers>
          <MoverTitle>{t('topMovers')}</MoverTitle>
          {isPoolLoading && !pools ? (
            <></>
          ) : (
            <ScrollableWrapper>
              <ScrollableRow ref={poolIncreaseRef}>
                {topPairIncrease.map((pool, index) => (
                  <PoolMover key={`topMovers-pools-${index.toString()}`} pool={pool} />
                ))}
              </ScrollableRow>
            </ScrollableWrapper>
          )}
        </TopMovers>*/}
        <FooterSpacing />
      </StatsWrapper>
    </PageSizeContext.Provider>
  )
}
