import { ChainId, GM_ARB_USD_ADDRESSES, GM_ARB_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmARBUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ARB } from '../../tokens/ARB'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'

const cleanSymbol = 'gmARB-USD'
const cleanName = 'GM: ARB-USD'

export const GM_ARB_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmARBUSD,
  safeSymbol: SpecialAssetSymbol.gmARBUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_ARB_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_ARB_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) => getGmCollateralTokens(SpecialAssetSymbol.gmARBUSD, ARB[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(ARB[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(ARB[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(ARB[chainId]),
    remapAccountAddress: calculateGmARBUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_ARB_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
