import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ONE_BIPS, ZERO_ADDRESS, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import calculateUtilization from '../../utils/calculateUtilization'
import calculateLiquidationThreshold from '../../utils/calculateLiquidationThreshold'
import calculateLiquidationReward from '../../utils/calculateLiquidationReward'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { Rounding, Token } from '@dolomite-exchange/v2-sdk'
import { ExternalLink, ExternalLinkIcon } from '../../theme'
import { getEtherscanLink } from '../../utils'
import React, { useMemo } from 'react'
import { InterestRate } from '../../types/interestRateData'
import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { TokenTvl } from '../../types/dolomiteMarginTokenTvlData'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import InterestRateChangedProps from './InterestRateChangedProps'
import styled from 'styled-components/macro'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import tokenEqualsOpt from '../../utils/tokenEqualsOpt'
import { currencyAmountEqualsOpt, fractionEqualsOpt, percentEqualsOpt } from '../../utils/fractionEqualsOpt'
import { formatAmount } from '../../utils/formatAmount'
import { YieldTooltip } from '../../components/LendingPanel'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useShowYieldAsApr } from '../../state/user/hooks'

const Balance = styled.div`
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 580px) {
    font-size: 17px;
  }
`

const DollarBalance = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #7e7f89;
  margin-top: -2px;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

interface TotalValueProps {
  totalValue: string
  totalValueUSD: string
}

const TotalValueComparator = (prevProps: TotalValueProps, nextProps: TotalValueProps) => {
  return prevProps.totalValue === nextProps.totalValue && prevProps.totalValueUSD === nextProps.totalValueUSD
}

const TotalValue = React.memo<TotalValueProps>(
  ({ totalValue, totalValueUSD }: TotalValueProps) => (
    <div>
      <Balance>{totalValue}</Balance>
      <DollarBalance>
        {totalValueUSD.startsWith('-') ? `-$${totalValueUSD.replace('-', '')}` : `${totalValueUSD}`}
      </DollarBalance>
    </div>
  ),
  TotalValueComparator,
)
TotalValue.displayName = 'TotalValue'

const Breaker = styled.div`
  flex-basis: 100%;
  height: 0;
`

const ExpandedContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  flex-wrap: wrap;
`

const LinkWrapper = styled.div`
  width: 100%;
  margin: 0 auto 0;
  display: flex;
  justify-content: space-around;

  > a {
    color: ${({ theme }) => theme.text3} !important;

    svg {
      stroke: ${({ theme }) => theme.text3} !important;
      opacity: 1 !important;
    }

    > a {
      opacity: 1 !important;
    }

    :hover {
      color: ${({ theme }) => theme.text1} !important;

      svg {
        stroke: ${({ theme }) => theme.text1} !important;
      }
    }

    :nth-of-type(1) {
      width: 50%;
      flex-basis: inherit !important;
      margin-top: 0 !important;

      @media screen and (min-width: 581px) {
        white-space: nowrap;
        margin-left: -12px;
      }
    }

    :nth-of-type(2) {
      text-align: center;
      width: 50%;
      vertical-align: bottom;
      margin: 0;
      line-height: 27px;
      margin-top: 4px;
      margin-right: -12px;
    }
  }
`

const NavLink = styled(Link)`
  display: block;
  width: fit-content;
  text-decoration: none;
  margin: 5px auto;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3} !important;

  &:hover {
    color: ${({ theme }) => theme.text1} !important;
  }
`

const ExpandedStatWrapper = styled.div`
  display: block;
  width: 50%;
`

const ExpandedStat = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  margin-bottom: 8px;
`

const StatTitle = styled.div`
  /*color: #808293;*/
  color: #7e7f89;
  font-size: 12px;
  margin-bottom: -2px;
  font-weight: 600;
`

const StatValue = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 15px;
`

const NewRate = styled.div<{ visible: boolean; positive: boolean; isSupplyRate: boolean }>`
    color: ${({ theme, positive }) => (positive ? theme.green1 : theme.red1)};
    margin-top: -5px;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;

    ::before {
        content: '${({ positive, isSupplyRate }) =>
          (positive && !isSupplyRate) || (!positive && isSupplyRate) ? '▼ ' : '▲ '}';
    }
`

const Column = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
`

const RateColumn = styled(Column)`
  text-align: right;
  width: 32.5%;
`

const CollateralRow = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  margin-left: 5%;
  font-weight: 200;
`

const SupplyColumn = styled(RateColumn)`
  width: 25%;
  font-size: 14px;
  line-height: 23px;
`

const BorrowColumn = styled(RateColumn)`
  width: 30%;
  font-size: 14px;
  line-height: 23px;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props}>
    {props.children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    background-color: #3d3e54 !important;
  }
`

const TickerColumn = styled(Column)`
  text-align: left;
  width: 45%;
`

const RateRow = styled.div<{ expanded: boolean; tall: boolean; small: boolean }>`
  width: calc(100% + 70px);
  margin-left: -35px;
  padding: 4px 35px 3px;
  cursor: pointer;
  height: ${({ expanded, tall, small }) =>
    small ? (expanded ? '205' : '32') : expanded ? (tall ? '274' : '250') : tall ? '46' : '32'}px;
  transition: height 0.2s ease-in-out;
  ${({ theme, expanded }) => (expanded ? `background-color: ${theme.bg2};` : '')}
  overflow: hidden;

  :hover {
    background: ${({ theme, expanded }) => (expanded ? theme.bg2 : theme.bg7)};
  }

  @media (max-width: 580px) {
    height: ${({ expanded, tall, small }) =>
      small ? (expanded ? '212' : '32') : expanded ? (tall ? '274' : '260') : tall ? '46' : '32'}px;
  }
`

const TickerWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

const Ticker = styled.div`
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 580px) {
    font-size: 17px;
  }
`

const Name = styled.div<{ visible?: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  font-weight: 500;
  margin-top: -4px;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  display: inline !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 3px;
  margin-bottom: -1px;

  path {
    fill: ${({ theme }) => theme.blue2};
  }
`

const OutsideRate = styled.div`
  color: ${({ theme }) => theme.blue2};
`

function isRateSufficientlyDifferent(newBorrowRate: Percent, oldBorrowRate: Percent) {
  return newBorrowRate
    .subtract(oldBorrowRate)
    .multiply(newBorrowRate.greaterThan(oldBorrowRate) ? '1' : '-1')
    .greaterThan(new Fraction(2, 10000))
}

interface BorrowRateRowProps {
  token: Token
  selectedToken: Token | undefined
  defaultMinCollateralization: Fraction | undefined
  defaultLiquidationReward: Fraction | undefined
  interestRate: InterestRate | undefined
  oraclePrice: Fraction | undefined
  tvl: TokenTvl | undefined
  marketRiskInfo: MarketRiskInfo | undefined
  newBorrowRate: InterestRateChangedProps | undefined
  newSupplyRate: InterestRateChangedProps | undefined
  setSelectedToken: (token?: Token) => void
  showYieldAsApr: boolean
}

function BorrowRateRowComparator(prevProps: BorrowRateRowProps, nextProps: BorrowRateRowProps) {
  const prevInterestRate = prevProps.interestRate
  const nextInterestRate = nextProps.interestRate

  return (
    prevProps.token.equals(nextProps.token) &&
    tokenEqualsOpt(prevProps.selectedToken, nextProps.selectedToken) &&
    fractionEqualsOpt(prevProps.defaultMinCollateralization, nextProps.defaultMinCollateralization) &&
    fractionEqualsOpt(prevProps.defaultLiquidationReward, nextProps.defaultLiquidationReward) &&
    percentEqualsOpt(prevInterestRate?.borrowInterestRate, nextInterestRate?.borrowInterestRate) &&
    percentEqualsOpt(prevInterestRate?.supplyInterestRate, nextInterestRate?.supplyInterestRate) &&
    fractionEqualsOpt(prevProps.oraclePrice, nextProps.oraclePrice) &&
    fractionEqualsOpt(prevProps.tvl?.borrowLiquidity, nextProps.tvl?.borrowLiquidity) &&
    fractionEqualsOpt(prevProps.tvl?.supplyLiquidity, nextProps.tvl?.supplyLiquidity) &&
    prevProps.marketRiskInfo?.isBorrowingDisabled === nextProps.marketRiskInfo?.isBorrowingDisabled &&
    fractionEqualsOpt(prevProps.marketRiskInfo?.marginPremium, nextProps.marketRiskInfo?.marginPremium) &&
    fractionEqualsOpt(
      prevProps.marketRiskInfo?.liquidationRewardPremium,
      nextProps.marketRiskInfo?.liquidationRewardPremium,
    ) &&
    prevProps.marketRiskInfo?.oracleAddress === nextProps.marketRiskInfo?.oracleAddress &&
    currencyAmountEqualsOpt(prevProps.marketRiskInfo?.supplyMaxWei, nextProps.marketRiskInfo?.supplyMaxWei) &&
    tokenEqualsOpt(prevProps.newBorrowRate?.token, nextProps.newBorrowRate?.token) &&
    percentEqualsOpt(prevProps.newBorrowRate?.rate, nextProps.newBorrowRate?.rate) &&
    tokenEqualsOpt(prevProps.newSupplyRate?.token, nextProps.newSupplyRate?.token) &&
    percentEqualsOpt(prevProps.newSupplyRate?.rate, nextProps.newSupplyRate?.rate) &&
    prevProps.showYieldAsApr === nextProps.showYieldAsApr
  )
}

function BorrowRateRow({
  token,
  selectedToken,
  defaultMinCollateralization,
  defaultLiquidationReward,
  interestRate,
  oraclePrice: rawOraclePrice,
  tvl,
  marketRiskInfo,
  newBorrowRate,
  newSupplyRate,
  setSelectedToken,
}: BorrowRateRowProps) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const symbol = cleanCurrencySymbol(token) ?? ''
  const borrowRate = interestRate?.borrowInterestRate ?? ZERO_PERCENT
  const totalBorrowRate = interestRate?.totalBorrowInterestRate ?? ZERO_PERCENT
  const borrowInterestRateParts = interestRate?.outsideBorrowInterestRateParts
  const supplyRate = interestRate?.supplyInterestRate ?? ZERO_PERCENT
  const totalSupplyRate = interestRate?.totalSupplyInterestRate ?? ZERO_PERCENT
  const supplyInterestRateParts = interestRate?.outsideSupplyInterestRateParts
  const oraclePrice = rawOraclePrice ?? ZERO_FRACTION
  const supplyTvl = tvl?.supplyLiquidity.asFraction ?? ZERO_FRACTION
  const supplyTvlUSD = supplyTvl.multiply(oraclePrice)
  const borrowTvl = tvl?.borrowLiquidity.asFraction ?? ZERO_FRACTION
  const borrowTvlUSD = borrowTvl.multiply(oraclePrice)
  const utilization = calculateUtilization(supplyTvl, borrowTvl)

  const isCollateralOnly = marketRiskInfo?.isBorrowingDisabled ?? false
  const liquidationThreshold = calculateLiquidationThreshold(defaultMinCollateralization, marketRiskInfo?.marginPremium)
  const liquidationReward = calculateLiquidationReward(
    defaultLiquidationReward,
    marketRiskInfo?.liquidationRewardPremium,
  )

  const oracleAddress = marketRiskInfo?.oracleAddress ?? ZERO_ADDRESS

  const displayNewSupplyRate = useMemo(() => {
    return (
      !!newSupplyRate &&
      newSupplyRate.token.equals(token) &&
      isRateSufficientlyDifferent(newSupplyRate.rate, totalSupplyRate)
    )
  }, [newSupplyRate, totalSupplyRate, token])

  const displayNewBorrowRate = useMemo(() => {
    return (
      !!newBorrowRate &&
      newBorrowRate.token.equals(token) &&
      isRateSufficientlyDifferent(newBorrowRate.rate, borrowRate)
    )
  }, [borrowRate, newBorrowRate, token])

  // TODO put smaller grayed USD values underneath supplyTVL and borrowTVL
  return (
    <RateRow
      key={`lending-${token.chainId}-${token.address}`}
      expanded={selectedToken?.equals(token) ?? false}
      tall={displayNewBorrowRate || displayNewSupplyRate}
      onClick={() => (selectedToken?.equals(token) ? setSelectedToken(undefined) : setSelectedToken(token))}
      small={isCollateralOnly}
    >
      <TickerColumn>
        <div>
          <TickerWrapper>
            <Ticker>{symbol}</Ticker>
            <Name visible={selectedToken?.equals(token)}>{cleanCurrencyName(token)}</Name>
          </TickerWrapper>
        </div>
      </TickerColumn>
      <>
        <SupplyColumn>
          {supplyInterestRateParts && supplyInterestRateParts.length > 0 ? (
            <StyledTooltip
              title={
                <YieldTooltip
                  interestRateParts={supplyInterestRateParts}
                  lendingYield={supplyRate}
                  totalSupplyYield={totalSupplyRate}
                  showYieldAsApr={showYieldAsApr}
                  isBorrowRate={false}
                />
              }
              placement={'top'}
              arrow={true}
            >
              <OutsideRate>{formatAmount(totalSupplyRate, 2, true, '0%')}</OutsideRate>
            </StyledTooltip>
          ) : (
            <>
              {formatAmount(totalSupplyRate, 2, true, '0%')}
              {newSupplyRate && displayNewSupplyRate && (
                <StyledTooltip
                  title={
                    'This will be the new supply rate once you open your supply position. The change is from the change in the utilization rate of the asset due to your new supply position.'
                  }
                  position={'top'}
                >
                  <NewRate
                    visible={!!newSupplyRate && newSupplyRate.token.address === token.address}
                    positive={newSupplyRate.rate.greaterThanOrEqual(totalSupplyRate)}
                    isSupplyRate={true}
                  >
                    {formatAmount(newSupplyRate.rate, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
                  </NewRate>
                </StyledTooltip>
              )}
            </>
          )}
        </SupplyColumn>
        <BorrowColumn>
          {isCollateralOnly ? (
            <StyledTooltip title={'This asset cannot be borrowed, and as a result has no borrow rate'} position={'top'}>
              <>-</>
            </StyledTooltip>
          ) : borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
            <StyledTooltip
              title={
                <YieldTooltip
                  interestRateParts={borrowInterestRateParts}
                  lendingYield={borrowRate}
                  totalSupplyYield={totalBorrowRate}
                  showYieldAsApr={showYieldAsApr}
                  isBorrowRate={true}
                />
              }
              placement={'top'}
              arrow={true}
            >
              <OutsideRate>{formatAmount(totalBorrowRate, 2, true, '0%')}</OutsideRate>
            </StyledTooltip>
          ) : (
            <>
              {formatAmount(totalBorrowRate, 2, true, '0%')}
              {newBorrowRate && displayNewBorrowRate && (
                <StyledTooltip
                  title={
                    'This will be the new borrow rate once you open your borrow position. The change is from the change in the utilization rate of the asset due to your new borrow position.'
                  }
                  position={'top'}
                >
                  <NewRate
                    visible={!!newBorrowRate && newBorrowRate.token.address === token.address}
                    positive={newBorrowRate.rate.lessThanOrEqual(borrowRate)}
                    isSupplyRate={false}
                  >
                    {formatAmount(newBorrowRate.rate, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
                  </NewRate>
                </StyledTooltip>
              )}
            </>
          )}
        </BorrowColumn>
      </>
      {isCollateralOnly ? (
        <ExpandedContent>
          <ExpandedStatWrapper>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('liquidationThreshold')}</StatTitle>
              <StatValue>
                {formatAmount(liquidationThreshold, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
              </StatValue>
            </ExpandedStat>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('totalSupplied')}</StatTitle>
              <TotalValue
                totalValue={formatAmount(supplyTvl, 2, true, undefined, false)}
                totalValueUSD={formatAmount(supplyTvlUSD, 2, true, undefined, true)}
              />
            </ExpandedStat>
          </ExpandedStatWrapper>
          <ExpandedStatWrapper>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('liquidationPenalty')}</StatTitle>
              <StatValue>
                {formatAmount(liquidationReward, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
              </StatValue>
            </ExpandedStat>
            <ExpandedStat width={'auto'} style={{ marginBottom: '26px' }}>
              <StatTitle>{t('oraclePrice')}</StatTitle>
              <StatValue>{formatAmount(oraclePrice, undefined, true, undefined, true)}</StatValue>
            </ExpandedStat>
          </ExpandedStatWrapper>
          <Breaker />
          <LinkWrapper>
            <ExternalLink
              style={{
                marginTop: '8px',
                flexBasis: '100%',
                textAlign: 'center',
                fontSize: '14px',
                textDecoration: 'none',
              }}
              href={getEtherscanLink(token.chainId, oracleAddress, 'address')}
            >
              {t('oracleContract')}
              <ExternalLinkIcon
                style={{
                  marginTop: '8px',
                  flexBasis: '100%',
                  textAlign: 'center',
                  fontSize: '14px',
                  textDecoration: 'none',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  marginLeft: '-5px',
                  marginBottom: '-1px',
                }}
                anchor={false}
                href={getEtherscanLink(token.chainId, oracleAddress, 'address')}
              />
            </ExternalLink>
            <NavLink to={`/stats/token/${token.address}`}>{t('tokenDetails')}</NavLink>
          </LinkWrapper>
        </ExpandedContent>
      ) : (
        <ExpandedContent>
          <ExpandedStatWrapper>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('totalSupplied')}</StatTitle>
              <TotalValue
                totalValue={formatAmount(supplyTvl, 2, true, undefined, false)}
                totalValueUSD={formatAmount(supplyTvlUSD, 2, true, undefined, true)}
              />
            </ExpandedStat>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('utilizationRate')}</StatTitle>
              <StatValue>{formatAmount(utilization, 2, true)}</StatValue>
            </ExpandedStat>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('liquidationThreshold')}</StatTitle>
              <StatValue>
                {formatAmount(liquidationThreshold, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
              </StatValue>
            </ExpandedStat>
          </ExpandedStatWrapper>
          <ExpandedStatWrapper>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('totalBorrowed')}</StatTitle>
              <TotalValue
                totalValue={formatAmount(borrowTvl, 2, true, undefined, false)}
                totalValueUSD={formatAmount(borrowTvlUSD, 2, true, undefined, true)}
              />
            </ExpandedStat>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('oraclePrice')}</StatTitle>
              <StatValue>{formatAmount(oraclePrice, undefined, true, undefined, true)}</StatValue>
            </ExpandedStat>
            <ExpandedStat width={'auto'}>
              <StatTitle>{t('liquidationPenalty')}</StatTitle>
              <StatValue>
                {formatAmount(liquidationReward, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
              </StatValue>
            </ExpandedStat>
          </ExpandedStatWrapper>
          <Breaker />
          <LinkWrapper>
            <ExternalLink
              style={{
                marginTop: '8px',
                flexBasis: '100%',
                textAlign: 'center',
                fontSize: '14px',
                textDecoration: 'none',
              }}
              href={getEtherscanLink(token.chainId, oracleAddress, 'address')}
            >
              {t('oracleContract')}
              <ExternalLinkIcon
                style={{
                  marginTop: '8px',
                  flexBasis: '100%',
                  textAlign: 'center',
                  fontSize: '14px',
                  textDecoration: 'none',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  marginLeft: '-5px',
                  marginBottom: '-1px',
                }}
                anchor={false}
                href={getEtherscanLink(token.chainId, oracleAddress, 'address')}
              />
            </ExternalLink>
            <NavLink to={`/stats/token/${token.address}`}>{t('tokenDetails')}</NavLink>
          </LinkWrapper>
        </ExpandedContent>
      )}
    </RateRow>
  )
}

export default React.memo(BorrowRateRow, BorrowRateRowComparator)
