import { useMemo } from 'react'
import { BorrowPosition } from '../types/borrowPositionData'
import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { useInterestRateData } from '../types/interestRateData'
import { ZERO_FRACTION } from '../constants'

export default function useNetInterestRate(position: BorrowPosition): Percent | undefined {
  const { data: interestRateMap } = useInterestRateData()
  return useMemo(() => {
    if (!position || !interestRateMap) {
      return undefined
    }
    const [netSupplyInterest, totalSupplyAmount] = position.supplyAmounts.reduce(
      (interestAndTotal, supplyAmount) => {
        const interestRate = interestRateMap[supplyAmount.token.address]?.totalSupplyInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(supplyAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(supplyAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    const [netBorrowInterest, totalBorrowAmount] = position.borrowAmounts.reduce(
      (interestAndTotal, borrowAmount) => {
        const interestRate = interestRateMap[borrowAmount.token.address]?.totalBorrowInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(borrowAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(borrowAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    return Percent.fromFraction(
      netSupplyInterest.subtract(netBorrowInterest).divide(totalSupplyAmount.subtract(totalBorrowAmount)),
    )
  }, [interestRateMap, position])
}
