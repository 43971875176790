import styled, { keyframes } from 'styled-components/macro'
import React, { useEffect, useMemo, useState } from 'react'
import FormattedPriceImpact from '../../../components/Trade/FormattedPriceImpact'
import { QuestionHelper } from '../../../components/Trade/AdvancedTradeDetails'
import { useTranslation } from 'react-i18next'
import { Percent } from '@dolomite-exchange/sdk-core'
import TradePrice from '../../../components/Trade/TradePrice'
import { calculatePriceForZap } from '../../../utils/calculatePrice'
import { useAllTokens } from '../../../hooks/Tokens'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { ChevronRight } from 'react-feather'
import { ZAP_REFRESH_INTERVAL_SECONDS, ZapOutputParam } from '../../../hooks/useGetZapParams'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../../hooks/useFiatValue'
import RefreshIcon from '@material-ui/icons/Refresh'
import { StyledTooltip } from '../../../components/common/StyledTooltip'
import { formatAmount } from '../../../utils/formatAmount'
import useTheme from '../../../hooks/useTheme'
import { ZERO_PERCENT } from '../../../constants'

const TradeDetailsWrapper = styled.div<{ showError: boolean; open?: boolean }>`
  font-size: 13px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  margin-top: ${({ showError }) => (showError ? 5 : -5)}px;
  transition: margin-top 0.2s ease-in-out;
  background: ${({ theme, open }) => (open ? theme.bg2 : theme.bg1)};
  border-radius: 8px;
  padding: 12px 20px;

  @media screen and (max-width: 615px) {
    margin-top: ${({ showError }) => (showError ? 5 : 3)}px;
  }
`

const LoadingWrapper = styled.div<{ small: boolean }>`
  width: 100%;
  text-align: center;
  min-height: ${({ small }) => (small ? '108px' : '62px')};
  line-height: ${({ small }) => (small ? '108px' : '62px')};
`

const SectionWrapper = styled.div<{ small: boolean }>`
  width: ${({ small }) => (small ? '100%' : 'calc(50% - 10px)')};
  display: inline-block;
  vertical-align: top;

  :nth-child(even) {
    margin-right: 20px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const SectionTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const HelperTextWrapper = styled.div`
  margin-left: -5px !important;
  margin-top: 0;
  vertical-align: top;
  display: inline-block;
  transform: translateY(1px);
`

const ExchangeWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`

const ExchangeInfo = styled.div<{ small: boolean }>`
  width: calc(100% - 18px);
  display: inline-block;
  vertical-align: top;
  margin-top: ${({ small }) => (small ? 10 : 0)}px;
`

const RefreshAnimationWrapper = styled.div<{ small: boolean }>`
  display: inline-block;
  vertical-align: top;
  width: 18px;
  height: 18px;
  margin-left: -2px;
  margin-top: ${({ small }) => (small ? 10 : 0)}px;
`

const topPieceAnimation = keyframes`
  to {
    transform: rotate(180deg);
  }
`

const bottomPieceAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const hidingPieceAnimation = keyframes`
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const TopLoadingPiece = styled.div`
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background: linear-gradient(-90deg, transparent, transparent 50%, #d5d6e1 50%);
  animation: ${topPieceAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS / 2}s linear 1 forwards;
`

const BottomLoadingPiece = styled.div`
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: top;
  margin-left: -18px;
  border-radius: 50%;
  background: linear-gradient(-90deg, transparent, transparent 50%, #d5d6e1 50%);
  animation: ${bottomPieceAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS}s linear 1 forwards;
`

const HidingLoadingPiece = styled.div<{ color: string }>`
  height: 20px;
  width: 10px;
  display: inline-block;
  vertical-align: top;
  margin-left: -19px;
  margin-top: -1px;
  background-color: ${({ color }) => color};
  animation: ${hidingPieceAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS / 2}s linear 1 forwards;
`

const LoadingCenterPiece = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 4;
`

const QuoteRefresh = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  transform: translate(15px, -4px);
`

const hideRefreshCountdownAnimation = keyframes`
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const RefreshAnimation = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  animation: ${hideRefreshCountdownAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS}s linear 1 forwards;
`

const RefreshAnimationInner = styled.div`
  width: 18px;
  height: 18px;
`

const refreshingSpinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const RefreshSpinner = styled.div<{ color: string }>`
  width: 18px;
  height: 18px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  z-index: 1;
  background: ${({ color }) => `radial-gradient(closest-side, ${color} 79%, transparent 90% 100%),
    conic-gradient(#d5d6e1 calc(63%), ${color} 0)`};
  animation: ${refreshingSpinnerAnimation} 1s ease-in-out infinite forwards;
`

const refreshHiderPanelAnimation = keyframes`
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const RefreshHiderPanel = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 2;
  background-color: ${({ color }) => color};
  animation: ${refreshHiderPanelAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS}s linear 1 forwards;
`

const dimRefreshButtonAnimation = keyframes`
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0.3 !important;
  }
`

const RefreshButton = styled.div<{ isDulled: boolean }>`
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  animation: ${dimRefreshButtonAnimation} ${ZAP_REFRESH_INTERVAL_SECONDS}s linear 1 forwards;
  opacity: ${({ isDulled }) => (isDulled ? 0.3 : 1)};
  /*transition: transform 0.2s ease-in-out;

  :hover {
    transform: rotate(360deg);
  }*/

  svg {
    width: 70%;
    height: 70%;
    margin: 15%;
    color: ${({ theme }) => theme.text1};

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const Exchange = styled.span`
  font-weight: 700;
`

const PriceWrapper = styled(Section)``

const PriceImpactWrapper = styled(Section)``

const MinimumReceivedWrapper = styled(Section)``

const RouteWrapper = styled(Section)`
  > div > span > svg {
    transform: translateY(1px);
  }
`

interface BorrowTradeDetailsProps {
  bestZap: ZapOutputParam | undefined
  priceImpact: Percent | undefined
  isLoading: boolean
  refreshZapData: () => void
  showError: boolean
  open?: boolean
  small?: boolean
  loadingDisabled?: boolean
}

const BorrowTradeDetailsComparator = (prevProps: BorrowTradeDetailsProps, nextProps: BorrowTradeDetailsProps) => {
  //TODO - make this more precise to prevent unnecessary re-renders
  return (
    prevProps.bestZap === nextProps.bestZap &&
    !!prevProps.priceImpact?.equalTo(nextProps.priceImpact ?? ZERO_PERCENT) &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.showError === nextProps.showError
  )
}

function RefreshingAnimation({
  nonce,
  refreshZapData,
  open,
}: {
  nonce: number
  refreshZapData: () => void
  open?: boolean
}) {
  const [hideSpinner, setHideSpinner] = useState(false)
  const theme = useTheme()
  const color = open ? theme.bg2 : theme.bg1
  const submitRefresh = () => {
    setHideSpinner(true)
    refreshZapData()
  }

  useEffect(() => {
    setHideSpinner(false)
  }, [nonce])

  return (
    <QuoteRefresh key={nonce} onClick={() => submitRefresh()}>
      <RefreshSpinner color={color} />
      {!hideSpinner && (
        <div>
          <RefreshHiderPanel color={color} />
          <RefreshAnimation>
            <RefreshAnimationInner>
              <TopLoadingPiece />
              <BottomLoadingPiece />
              <HidingLoadingPiece color={color} />
            </RefreshAnimationInner>
          </RefreshAnimation>
          <LoadingCenterPiece color={color} />
        </div>
      )}
      <RefreshButton isDulled={hideSpinner}>
        <RefreshIcon />
      </RefreshButton>
    </QuoteRefresh>
  )
}

function BorrowTradeDetails({
  bestZap,
  priceImpact,
  isLoading,
  refreshZapData,
  showError,
  open,
  small,
  loadingDisabled,
}: BorrowTradeDetailsProps) {
  const { t } = useTranslation()
  const [refreshIncrementor, setRefreshIncrementor] = useState(0)

  useEffect(() => {
    setRefreshIncrementor(Date.now())
  }, [bestZap])

  const tokenMap = useAllTokens()
  const outputToken = useMemo(
    () => (bestZap?.tokensPath ? tokenMap[bestZap.tokensPath[bestZap.tokensPath.length - 1].address] : undefined),
    [bestZap, tokenMap],
  )
  const minOutputAmount = useMemo(() => {
    if (!outputToken || !bestZap) {
      return undefined
    }
    return CurrencyAmount.fromRawAmount(
      outputToken,
      bestZap.amountWeisPath[bestZap.amountWeisPath.length - 1].toFixed(0),
    )
  }, [bestZap, outputToken])
  const tradePrice = useMemo(() => (bestZap ? calculatePriceForZap(bestZap, tokenMap) : undefined), [bestZap, tokenMap])
  const exchanges = bestZap ? bestZap.traderParams.map(param => param.readableName).join(' → ') : undefined
  const [isPriceInverted, setIsPriceInverted] = useState(false)
  if (isLoading) {
    return (
      <TradeDetailsWrapper showError={showError} open={open}>
        <LoadingWrapper small={!!small}>Loading...</LoadingWrapper>
      </TradeDetailsWrapper>
    )
  }
  return (
    <TradeDetailsWrapper showError={showError} open={open}>
      <ExchangeWrapper>
        <ExchangeInfo small={!!small}>
          Swapping via <Exchange>{exchanges}</Exchange>
          <HelperTextWrapper>
            <QuestionHelper
              text={
                'Zap trades are routed using a mixture of native redemptions for LP tokens and a DEX aggregator to get the best price.'
              }
            />
          </HelperTextWrapper>
        </ExchangeInfo>
        {!loadingDisabled && (
          <StyledTooltip
            title={'Displayed data will refresh every 30 seconds, click to refresh manually'}
            placement={'top'}
          >
            <RefreshAnimationWrapper small={!!small}>
              <RefreshingAnimation nonce={refreshIncrementor} refreshZapData={refreshZapData} open={open} />
            </RefreshAnimationWrapper>
          </StyledTooltip>
        )}
      </ExchangeWrapper>
      <SectionWrapper small={!!small}>
        <PriceWrapper>
          <SectionTitle>Price</SectionTitle>
          {
            <TradePrice
              price={tradePrice}
              showInverted={isPriceInverted}
              setShowInverted={() => setIsPriceInverted(!isPriceInverted)}
            />
          }
        </PriceWrapper>
      </SectionWrapper>
      <SectionWrapper small={!!small}>
        <PriceImpactWrapper>
          <SectionTitle>
            Price Impact{' '}
            <HelperTextWrapper>
              <QuestionHelper text={t('priceImpactForBorrowHelperText')} />
            </HelperTextWrapper>
          </SectionTitle>
          {/*<FormattedPriceImpact
           priceImpact={priceImpactWithoutFee}
           adjustedPriceImpact={isMargin ? adjustedPriceImpactWithoutFee : undefined}
           />*/}
          <div>
            {priceImpact ? <FormattedPriceImpact priceImpact={priceImpact} adjustedPriceImpact={undefined} /> : '-'}
          </div>
        </PriceImpactWrapper>
      </SectionWrapper>
      <SectionWrapper small={!!small}>
        <MinimumReceivedWrapper>
          <SectionTitle>
            Minimum Received{' '}
            <HelperTextWrapper>
              <QuestionHelper text={t('minimumReceivedHelperText')} />
            </HelperTextWrapper>
          </SectionTitle>
          <div>
            {formatAmount(minOutputAmount)} {cleanCurrencySymbol(outputToken)}
          </div>
        </MinimumReceivedWrapper>
      </SectionWrapper>
      <SectionWrapper small={!!small}>
        <RouteWrapper>
          <SectionTitle>
            Route{' '}
            <HelperTextWrapper>
              <QuestionHelper text={t('routeHelperText')} />
            </HelperTextWrapper>
          </SectionTitle>
          <div>
            {bestZap
              ? bestZap.tokensPath.map((token, index) => {
                  return (
                    <span key={index}>
                      {cleanCurrencySymbol(token)}
                      {index + 1 < bestZap.tokensPath.length && <ChevronRight size={11} color={'#d5d6e1'} />}
                    </span>
                  )
                })
              : '-'}
          </div>
        </RouteWrapper>
      </SectionWrapper>
    </TradeDetailsWrapper>
  )
}

export default React.memo(BorrowTradeDetails, BorrowTradeDetailsComparator)
