import { Currency, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/sdk-core'
import { useMultipleContractMultipleData } from '../state/multicall/hooks'
import { useMemo } from 'react'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
import { useActiveWeb3React } from './index'
import { useMarketRiskInfoData } from '../types/marketRiskInfoData'
import { abi as IPriceOracleAbi } from '@dolomite-exchange/dolomite-margin/build/contracts/IPriceOracle.json'
import { Interface } from '@ethersproject/abi'
import { TOKENS_TO_IGNORE_PRICES_MAP } from '../constants/isolation/special-assets'
import { getExternalTokens } from '../utils/externalTokens'
import store from 'state'
import { USDM } from '../constants/tokens/USDM'
import { ONE_FRACTION } from '../constants'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { RefreshFrequency } from '../state/chain/hooks'
import { createFraction } from '../types/gqlTypeHelpers'

const ONE_DOLLAR = JSBI.BigInt('1000000000000000000000000000000000000')
const TEN = JSBI.BigInt(10)
const ORACLE_INTERFACE = new Interface(IPriceOracleAbi)

type PriceDecimal = string

interface PriceDataApiResponse {
  prices: {
    [tokenAddressLowerCase: string]: PriceDecimal | undefined
  }
}

export function useFiatPricesWithLoadingIndicator(tokens?: Token[]): [Record<string, Fraction | undefined>, boolean] {
  const { chainId } = useActiveWeb3React()
  const { data: riskInfoMap, loading: isRiskInfoMapLoading } = useMarketRiskInfoData()
  const tokensWithOracles = useMemo(() => {
    return tokens?.reduce<{ tokens: Token[]; oracles: string[] }>(
      (memo, token) => {
        const riskInfo = riskInfoMap[token.address]
        if (!riskInfo || token.chainId !== chainId || TOKENS_TO_IGNORE_PRICES_MAP[chainId][token.address]) {
          return memo
        }

        memo.tokens.push(token)
        memo.oracles.push(riskInfo.oracleAddress)
        return memo
      },
      {
        tokens: [],
        oracles: [],
      },
    )
  }, [tokens, riskInfoMap, chainId])
  const oracleAddresses = useMemo(() => tokensWithOracles?.oracles ?? [], [tokensWithOracles])
  const callInputs = useMemo(() => tokensWithOracles?.tokens.map(token => [token.address]) ?? [], [tokensWithOracles])
  const callStates = useMultipleContractMultipleData(oracleAddresses, ORACLE_INTERFACE, 'getPrice', callInputs)
  const anyLoading = useMemo(() => callStates.some(callState => callState.loading) || isRiskInfoMapLoading, [
    callStates,
    isRiskInfoMapLoading,
  ])
  const serverPricesState = useGraphqlResult<PriceDataApiResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}/prices`,
    NO_VARIABLES,
    RefreshFrequency.Fast,
  )

  return useMemo(() => {
    const usdm = USDM[chainId]

    const serverPricesResult = serverPricesState.result
    if (serverPricesResult && tokens) {
      const fiatValueMap = tokens.reduce((memo, t) => {
        const priceString = serverPricesResult.prices[t.address.toLowerCase()]
        if (priceString) {
          memo[t.address] = createFraction(priceString)
        }
        return memo
      }, {} as Record<string, Fraction | undefined>)

      if (Object.keys(fiatValueMap).length === tokens.length) {
        if (usdm) {
          fiatValueMap[usdm.address] = ONE_FRACTION
        }
        return [fiatValueMap, false]
      }
    }

    const fiatValueMap =
      tokensWithOracles?.tokens.reduce<Record<string, Fraction | undefined>>((memo, token, i) => {
        const callState = callStates[i]
        const denominator = JSBI.exponentiate(TEN, JSBI.BigInt(36 - token.decimals))
        memo[token.address] = callState.result ? new Fraction(callState.result[0], denominator) : undefined
        return memo
      }, {}) ?? {}

    if (usdm) {
      fiatValueMap[usdm.address] = ONE_FRACTION
    }

    return [fiatValueMap, anyLoading]
  }, [serverPricesState.result, tokens, tokensWithOracles?.tokens, chainId, anyLoading, callStates])
}

export function useFiatValueWithLoadingIndicator<T extends Currency>(
  amount: CurrencyAmount<T> | Fraction | undefined,
  token?: Token,
): [Fraction | undefined, boolean] {
  if (token && amount && amount instanceof CurrencyAmount) {
    try {
      // This try catch isn't ideal, but a previous change resulted in this getting hit when selected token is changed,
      // it's an issue with the inputValueAmount changing after the token changes, so there's a render where this gets
      // hit before the inputValueAmount is updated
      invariant(amount.currency.wrapped.equals(token), `INVALID_TOKEN: ${amount.currency.symbol} / ${token.symbol}`)
    } catch (e) {
      const error = e as any
      console.log(error.message)
    }
  }

  const tokensArray = useMemo(() => (token ? [token] : []), [token])
  const [fiatPrices, isLoading] = useFiatPricesWithLoadingIndicator(tokensArray)

  return useMemo(() => {
    if (!fiatPrices || !token || !amount) {
      return [undefined, isLoading]
    }

    const fiatPrice = fiatPrices[token.address]
    return [fiatPrice ? amount.asFraction.multiply(fiatPrice) : undefined, isLoading]
  }, [amount, token, fiatPrices, isLoading])
}

export function useFiatValuesWithLoadingIndicator<T extends Currency>(
  tokenAddressToAmountMap: Record<string, CurrencyAmount<T> | undefined>,
  tokens?: Token[],
): [Record<string, Fraction | undefined>, boolean, Record<string, Fraction | undefined>] {
  const [fiatPrices, isLoading] = useFiatPricesWithLoadingIndicator(tokens)
  return useMemo(() => {
    if (!fiatPrices || !tokens) {
      return [{}, isLoading, fiatPrices]
    }

    const fiatValues = tokens.reduce<Record<string, Fraction | undefined>>((memo, token) => {
      const amount = tokenAddressToAmountMap[token.address]
      const fiatPrice = fiatPrices[token.address]
      memo[token.address] = fiatPrice && amount ? amount.asFraction.multiply(fiatPrice) : undefined
      return memo
    }, {})

    return [fiatValues, isLoading, fiatPrices]
  }, [fiatPrices, tokens, isLoading, tokenAddressToAmountMap])
}

export function useDefaultFiatValueWithLoadingIndicator(token: Token | undefined): [Fraction | undefined, boolean] {
  const tokens = useMemo(() => (token ? [token] : undefined), [token])
  const [fiatMap, isLoading] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  return useMemo(() => [fiatMap[token?.address ?? ''], isLoading], [fiatMap, isLoading, token])
}

export function useDefaultFiatValuesWithLoadingIndicator(
  tokens?: Token[],
): [Record<string, Fraction | undefined>, boolean, Record<string, Fraction | undefined>] {
  const amounts = useMemo(() => {
    if (!tokens) {
      return {}
    }

    return tokens.reduce<Record<string, CurrencyAmount<Token>>>((memo, token) => {
      memo[token.address] = CurrencyAmount.fromRawAmount(token, JSBI.exponentiate(TEN, JSBI.BigInt(token.decimals)))
      return memo
    }, {})
  }, [tokens])

  return useFiatValuesWithLoadingIndicator(amounts, tokens)
}

//   const walletBalanceValuesToCopyMap = useMemo(() => {
//     const result = {
//       [ether.wrapped.address]: ethBalance,
//     }
//
//     const usdmBalance = rawWalletBalanceMap[usdm?.address ?? '']
//     if (usdm && wusdm && usdmBalance) {
//       result[wusdm.address] = usdmBalance
//     }
//     return result
//   }, [ether, ethBalance, rawWalletBalanceMap, usdm, wusdm])

export function useFiatValuesWithExternalAssetsMap(
  balanceMap: Record<string, CurrencyAmount<Token> | undefined>,
  tokenList: Token[],
  ethBalance: CurrencyAmount<Currency> | undefined,
): Record<string, Fraction | undefined> {
  const [, , tokenFiatPricesMap] = useDefaultFiatValuesWithLoadingIndicator(tokenList)
  return useMemo(() => {
    const result = Object.keys(balanceMap).reduce((memo, key) => {
      const balance = balanceMap[key]
      const value = tokenFiatPricesMap[key]
      if (balance && value) {
        memo[key] = balance.asFraction.multiply(value)
      }
      return memo
    }, {} as Record<string, Fraction | undefined>)

    getExternalTokens(store.getState().chain.chainId).forEach(token => {
      const balance = balanceMap[token.address]
      const value = tokenFiatPricesMap[token.address]
      result[token.address] = balance && value ? balance.asFraction.multiply(value) : undefined
    })

    const ethValue = tokenFiatPricesMap[ethBalance?.currency.wrapped.address ?? '']
    if (ethBalance && ethValue) {
      result[ethBalance.currency.symbol ?? ''] = ethBalance.multiply(ethValue)
    }

    return result
  }, [balanceMap, tokenFiatPricesMap, ethBalance])
}
