import { BIG_INT_ZERO, ChainId, WMNT_ISOLATION_MODE_ADDRESSES } from '../../constants'
import { Currency, CurrencyAmount, Ether, Token } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useSerializedCurrenciesOpt, useSerializedTokenOpt, useSerializedTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useDolomiteMarginContract, useMulticallContract } from '../../hooks/useContract'
import { isAddress } from '../../utils'
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/hooks'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import remapTokenAddressForBalanceOf from '../../utils/isolation/remapTokenAddressForBalanceOf'
import { BigintIsh } from '@dolomite-exchange/v2-sdk'
import { useDolomiteMarginTokenAddressToIdMap } from '../../hooks/useDolomiteMarginProtocol'
import { getSpecialAsset } from '../../constants/isolation/special-assets'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

declare type TokenBalancesWithLoadingIndicator = [Record<address, CurrencyAmount<Token> | undefined>, boolean]

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[],
): Record<string, CurrencyAmount<Currency> | undefined> {
  const multicallContract = useMulticallContract()

  const { chainId } = useActiveWeb3React()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses],
  )

  const inputs = useMemo(() => addresses.map(address => [address]), [addresses])
  const results = useSingleContractMultipleData(multicallContract, 'getEthBalance', inputs)

  return useMemo(
    () =>
      addresses.reduce<Record<address, CurrencyAmount<Currency>>>((memo, address, i) => {
        const value = results[i]?.result?.[0]
        if (value) {
          memo[address] = CurrencyAmount.fromRawAmount(Ether.onChain(chainId ?? ChainId.MAINNET), value.toString())
        }
        return memo
      }, {}),
    [addresses, results, chainId],
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
): TokenBalancesWithLoadingIndicator {
  const { chainId } = useActiveWeb3React()
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => t?.chainId === chainId) ?? [],
    [chainId, tokens],
  )

  const validatedTokenAddresses = useMemo(
    () =>
      validatedTokens.map(token => {
        return remapTokenAddressForBalanceOf(token)
      }, []),
    [validatedTokens],
  )

  const needsCurrencyBalance = tokens?.find(
    t => t?.chainId === ChainId.MANTLE && t.address === WMNT_ISOLATION_MODE_ADDRESSES[ChainId.MANTLE],
  )

  const ethBalanceMap = useETHBalances(
    useMemo(() => (needsCurrencyBalance && address ? [address] : []), [needsCurrencyBalance, address]),
  )
  const ethBalanceOpt = ethBalanceMap[address ?? '']
  const callInputs = useMemo(() => [address], [address])
  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', callInputs)

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  const serializedEthBalance = ethBalanceOpt?.numerator.toString()
  const serializedBalances = JSON.stringify(balances.map(b => b.result?.[0]?.toString() ?? null))
  return [
    useMemo(() => {
      if (!address || validatedTokens.length === 0) {
        return {}
      }
      const deserializedBalances = JSON.parse(serializedBalances) as (string | null)[]
      return validatedTokens.reduce((memo, token, i) => {
        const value = deserializedBalances[i]
        if (needsCurrencyBalance?.equals(token)) {
          if (serializedEthBalance) {
            memo[token.address] = CurrencyAmount.fromRawAmount(token, serializedEthBalance)
          } else {
            memo[token.address] = undefined
          }
        } else if (value) {
          memo[token.address] = CurrencyAmount.fromRawAmount(token, value)
        }
        return memo
      }, {} as Record<string, CurrencyAmount<Token> | undefined>)
    }, [address, validatedTokens, serializedBalances, needsCurrencyBalance, serializedEthBalance]),
    anyLoading,
  ]
}

interface AccountInfoStruct {
  owner: string
  number: string
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useDolomiteBalancesWithLoadingIndicator(
  account?: string,
  tokens?: (Token | undefined)[],
  accountNumber: string = '0',
): TokenBalancesWithLoadingIndicator {
  const dolomiteMargin = useDolomiteMarginContract()
  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const { chainId } = useActiveWeb3React()
  const inputs = useMemo(() => {
    if (!account || !tokens || !chainId || Object.keys(tokenAddressToMarketIdMap).length === 0) {
      return []
    }
    return tokens.reduce<[AccountInfoStruct, BigintIsh][]>((params, token) => {
      if (!token || token.chainId !== chainId) {
        return params
      }

      const marketId = tokenAddressToMarketIdMap[token.address]
      if (!marketId) {
        return params
      }

      const specialAsset = getSpecialAsset(chainId, token)
      const remappedAddress = specialAsset?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
      if (remappedAddress) {
        params.push([
          {
            owner: remappedAddress,
            number: accountNumber,
          },
          marketId.toString(),
        ])
      } else {
        params.push([
          {
            owner: account,
            number: accountNumber,
          },
          marketId.toString(),
        ])
      }

      return params
    }, [])
  }, [account, accountNumber, chainId, tokenAddressToMarketIdMap, tokens])

  const callStates = useSingleContractMultipleData(dolomiteMargin, 'getAccountWei', inputs)

  return [
    useMemo<Record<string, CurrencyAmount<Token>>>(() => {
      if (
        callStates.some(callState => callState.loading) ||
        !account ||
        !tokens ||
        Object.values(inputs).length === 0
      ) {
        return {}
      }

      return tokens.reduce<Record<string, CurrencyAmount<Token>>>((memo, token, i) => {
        if (token) {
          const wei = callStates[i]?.result?.[0]
          if (!wei) {
            memo[token.address] = CurrencyAmount.fromRawAmount(token, BIG_INT_ZERO)
          } else {
            const value = CurrencyAmount.fromRawAmount(token, wei.value)
            if (!wei.sign) {
              memo[token.address] = CurrencyAmount.fromRawAmount(token, BIG_INT_ZERO).subtract(value)
            } else {
              memo[token.address] = value
            }

            const currencySymbol = Ether.onChain(token.chainId).symbol
            if (currencySymbol && currencySymbol === cleanCurrencySymbol(token)) {
              memo[currencySymbol] = memo[token.address]
            }
          }
        }
        return memo
      }, {})
    }, [account, callStates, inputs, tokens]),
    callStates.some(callState => callState.loading),
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[],
): Record<string, CurrencyAmount<Token> | undefined> {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useDolomiteBalance(address?: string, token?: Token | undefined): CurrencyAmount<Token> | undefined {
  const tokens = useMemo(() => (token ? [token] : []), [token])
  const result = useDolomiteBalancesWithLoadingIndicator(address, tokens)[0]

  if (!address || !token) {
    return undefined
  }

  return result[token.address]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): CurrencyAmount<Token> | undefined {
  const serializedTokenOpt = useSerializedTokenOpt(token)
  const tokens = useMemo(() => (serializedTokenOpt ? [serializedTokenOpt] : []), [serializedTokenOpt])
  const tokenBalances = useTokenBalances(account, tokens)
  return useMemo(() => {
    if (!token) {
      return undefined
    }
    return tokenBalances[token.address]
  }, [tokenBalances, token])
}

export function useCurrencyBalances(
  account?: string,
  currencies?: Currency[] | undefined,
): (CurrencyAmount<Currency> | undefined)[] {
  const serializedCurrencies = useSerializedCurrenciesOpt(currencies)
  const tokens = useMemo(
    () => serializedCurrencies?.filter((currency): currency is Token => currency instanceof Token) ?? [],
    [serializedCurrencies],
  )
  const tokenBalanceMap = useTokenBalances(account, useSerializedTokens(tokens))
  const containsETH: boolean = useMemo(() => {
    return serializedCurrencies?.some(currency => currency.isNative) ?? false
  }, [serializedCurrencies])
  const ethBalance = useETHBalances(useMemo(() => (containsETH ? [account] : []), [account, containsETH]))

  return useMemo(
    () =>
      serializedCurrencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalanceMap[currency.address]
        if (currency.isNative) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, serializedCurrencies, ethBalance, tokenBalanceMap],
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(
    account,
    useMemo(() => (currency ? [currency] : []), [currency]),
  )[0]
}
