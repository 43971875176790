import 'inter-ui'
import React, { StrictMode } from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Blocklist from './components/Blocklist'
import './i18n'
import App from './pages/App'
import store from './state'
import ChainUpdater from './state/chain/updater'
import MulticallUpdater from './state/multicall/updater'
import GraphqlUpdater from './state/graphql/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import WalletUpdater from './state/wallet/updater'
import AchievementUpdater from './state/achievement/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import * as Sentry from '@sentry/react'
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  extraErrorDataIntegration,
  replayIntegration,
} from '@sentry/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Web3OnboardProvider } from '@web3-onboard/react'
import { getWeb3Onboard } from './web3-onboard'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  })
} else {
  ReactGA.initialize('test', {
    testMode: true,
    debug: true,
  })
}

window.addEventListener('error', error => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true,
  })
})

function Updaters() {
  return (
    <>
      <UserUpdater />
      <ChainUpdater />
      <TransactionUpdater />
      <GraphqlUpdater />
      <AchievementUpdater />
      <MulticallUpdater />
      <WalletUpdater />
    </>
  )
}

Sentry.init({
  dsn: 'https://8619ee004c7a46cebf585a9a4c46ddc5@o162178.ingest.sentry.io/1229269',
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.NODE_ENV === 'development' ? 'development' : 'production',
  integrations: [
    browserTracingIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
    extraErrorDataIntegration({
      depth: 7,
    }),
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.25,
  tunnel: `${DOLOMITE_API_SERVER_URL}/report`,
})

const SentryApp = Sentry.withProfiler(App)

ReactDOM.render(
  <StrictMode>
    <FixedGlobalStyle />
    <Web3OnboardProvider web3Onboard={getWeb3Onboard()}>
      <Provider store={store}>
        <Blocklist>
          <Updaters />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <BrowserRouter>
              <SentryApp />
            </BrowserRouter>
          </ThemeProvider>
        </Blocklist>
      </Provider>
    </Web3OnboardProvider>
  </StrictMode>,
  document.getElementById('root'),
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
