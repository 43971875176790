import { ChainId, GM_LINK_USD_ADDRESSES, GM_LINK_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmLINKUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'
import { LINK } from '../../tokens/LINK'

const cleanSymbol = 'gmLINK-USD'
const cleanName = 'GM: LINK-USD'

export const GM_LINK_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmLINKUSD,
  safeSymbol: SpecialAssetSymbol.gmLINKUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_LINK_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_LINK_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) => getGmCollateralTokens(SpecialAssetSymbol.gmLINKUSD, LINK[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(LINK[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(LINK[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(LINK[chainId]),
    remapAccountAddress: calculateGmLINKUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_LINK_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
