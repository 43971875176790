import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { ChainId } from '../constants'
import store from '../state'
import { LIBRARY_MAP } from '../connectors'
import { onError } from '@apollo/client/link/error'
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { GraphqlClientType } from '../state/graphql/actions'
import { BigNumber as ZapBigNumber, DOLOMITE_API_SERVER_URL, DolomiteZap, Network } from '@dolomite-exchange/zap-sdk'
import { useActiveWeb3React } from '../hooks'
import { Dispatch, useMemo } from 'react'
import ExpiringMap from '../data/ExpiringMap'
import { updateClientToChainToUrlIndex } from 'state/application/actions'
import { CHAIN_ID, ChainIdMap } from '../constants/chainId'

const DEFAULT_BLOCK_TAG = 'latest'
const ONE_HOUR_SECONDS = 60 * 60
const DEFAULT_SLIPPAGE = 0.001
const MAX_CONSECUTIVE_ERRORS = 4

export const START_BLOCK_MAP: ChainIdMap<number> = {
  [ChainId.MAINNET]: 0,
  [ChainId.ARBITRUM_ONE]: 28_220_369,
  [ChainId.BASE]: 10_010_605,
  [ChainId.BERACHAIN]: 7_000_000,
  [ChainId.MANTLE]: 63_091_469,
  [ChainId.POLYGON_ZKEVM]: 9_597_566,
  [ChainId.X_LAYER]: 854_000,
}

const dolomiteSubgraphURLs: ChainIdMap<string[] | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: [
    // WARNING: This is the latest Subgraph version. Using this results in querying against the wrong version sometimes...
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-arbitrum/v0.1.2/gn', // v0.1.2
    // 'https://gateway-arbitrum.network.thegraph.com/api/382d6a038adf0cbf379512728008f30b/subgraphs/id/DF4dkCD1NNxuqF3tovPpQG1VgkxsaGjr5TXJTtsKZH7D',
  ],
  [ChainId.BASE]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-base/v0.1.2/gn', // v0.1.2
    // 'https://gateway-arbitrum.network.thegraph.com/api/382d6a038adf0cbf379512728008f30b/deployments/id/QmYnv6xR1G13BsapCkwUUpa69EketB2xccg9hHr4LhM9N3', // v0.1.0
  ],
  [ChainId.BERACHAIN]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-berachain/v0.1.2/gn', // v0.1.2
  ],
  [ChainId.MANTLE]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-mantle/v0.1.2/gn', // v0.1.2
    'https://subgraph-api.mantle.xyz/api/public/d38368a3-2cd7-4c60-a3f3-a0d3c82b2257/subgraphs/dolomite-mantle/v0.1.2/gn', // v0.1.2
  ],
  [ChainId.POLYGON_ZKEVM]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-polygon-zkevm/v0.1.2/gn', // v0.1.2
    // 'https://gateway-arbitrum.network.thegraph.com/api/382d6a038adf0cbf379512728008f30b/deployments/id/Qmb9EZg4jLQbHH2hv4xeUTZbGD2kiRktx2W8NDBoo2CCUa', // v0.1.0
  ],
  [ChainId.X_LAYER]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/dolomite-x-layer/v0.1.2/gn', // v0.1.2
    // 'https://gateway-arbitrum.network.thegraph.com/api/382d6a038adf0cbf379512728008f30b/deployments/id/QmVL9S7R2qb3868SqreZU4vcsRAVvwp167duN1UmhNQ1um', // v0.1.0
  ],
}

const blockSubgraphURLs: ChainIdMap<string[] | undefined> = {
  [ChainId.MAINNET]: ['https://api.thegraph.com/subgraphs/name/kybernetwork/ethereum-blocks'],
  [ChainId.ARBITRUM_ONE]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-arbitrum/1.0.0/gn',
  ],
  [ChainId.BASE]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-base/1.0.0/gn',
  ],
  [ChainId.BERACHAIN]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-berachain-bartio/1.0.0/gn',
  ],
  [ChainId.MANTLE]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-mantle/1.0.0/gn',
  ],
  [ChainId.POLYGON_ZKEVM]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-polygon-zkevm/1.0.0/gn',
  ],
  [ChainId.X_LAYER]: [
    'https://subgraphapi.dolomite.io/api/public/1301d2d1-7a9d-4be4-9e9a-061cb8611549/subgraphs/blocks-x-layer/1.0.0/gn',
  ],
}

export const galxeSubgraphClient = new ApolloClient({
  uri: `${DOLOMITE_API_SERVER_URL}/galxe/query`,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  assumeImmutableResults: false,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})

export const blockSubgraphClient = buildSubgraphClient(GraphqlClientType.Blocks, 'cache-first', blockSubgraphURLs)

export const dolomiteSubgraphClient = buildSubgraphClient(GraphqlClientType.Dolomite, 'no-cache', dolomiteSubgraphURLs)

const odosReferralCode = !new ZapBigNumber(process.env.REACT_APP_ODOS_REFERRAL_CODE ?? '').isNaN()
  ? new ZapBigNumber(process.env.REACT_APP_ODOS_REFERRAL_CODE ?? '')
  : undefined
const referralAddress = process.env.REACT_APP_PARTNER_ADDRESS
export const CHAIN_ID_TO_ZAP_MAP: ChainIdMap<DolomiteZap | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: new DolomiteZap({
    network: Network.ARBITRUM_ONE,
    subgraphUrl: dolomiteSubgraphURLs[ChainId.ARBITRUM_ONE]![0],
    web3Provider: LIBRARY_MAP[ChainId.ARBITRUM_ONE]!,
    cacheSeconds: ONE_HOUR_SECONDS,
    defaultIsLiquidation: false,
    defaultSlippageTolerance: DEFAULT_SLIPPAGE,
    defaultBlockTag: DEFAULT_BLOCK_TAG,
    referralInfo: {
      referralAddress,
      odosReferralCode,
    },
    useProxyServer: true,
    gasMultiplier: new ZapBigNumber(3),
  }),
  [ChainId.BASE]: new DolomiteZap({
    network: Network.BASE,
    subgraphUrl: dolomiteSubgraphURLs[ChainId.BASE]![0],
    web3Provider: LIBRARY_MAP[ChainId.BASE]!,
    cacheSeconds: ONE_HOUR_SECONDS,
    defaultIsLiquidation: false,
    defaultSlippageTolerance: DEFAULT_SLIPPAGE,
    defaultBlockTag: DEFAULT_BLOCK_TAG,
    referralInfo: {
      referralAddress,
      odosReferralCode,
    },
    useProxyServer: true,
    gasMultiplier: new ZapBigNumber(3),
  }),
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: new DolomiteZap({
    network: Network.MANTLE,
    subgraphUrl: dolomiteSubgraphURLs[ChainId.MANTLE]![0],
    web3Provider: LIBRARY_MAP[ChainId.MANTLE]!,
    cacheSeconds: ONE_HOUR_SECONDS,
    defaultIsLiquidation: false,
    defaultSlippageTolerance: DEFAULT_SLIPPAGE,
    defaultBlockTag: DEFAULT_BLOCK_TAG,
    referralInfo: {
      referralAddress,
      odosReferralCode,
    },
    useProxyServer: true,
    gasMultiplier: new ZapBigNumber(2),
  }),
  [ChainId.POLYGON_ZKEVM]: new DolomiteZap({
    network: Network.POLYGON_ZKEVM,
    subgraphUrl: dolomiteSubgraphURLs[ChainId.POLYGON_ZKEVM]![0],
    web3Provider: LIBRARY_MAP[ChainId.POLYGON_ZKEVM]!,
    cacheSeconds: ONE_HOUR_SECONDS,
    defaultIsLiquidation: false,
    defaultSlippageTolerance: DEFAULT_SLIPPAGE,
    defaultBlockTag: DEFAULT_BLOCK_TAG,
    referralInfo: {
      referralAddress,
      odosReferralCode,
    },
    useProxyServer: true,
    gasMultiplier: new ZapBigNumber(3),
  }),
  [ChainId.X_LAYER]: new DolomiteZap({
    network: Network.X_LAYER,
    subgraphUrl: dolomiteSubgraphURLs[ChainId.X_LAYER]![0],
    web3Provider: LIBRARY_MAP[ChainId.X_LAYER]!,
    cacheSeconds: ONE_HOUR_SECONDS,
    defaultIsLiquidation: false,
    defaultSlippageTolerance: DEFAULT_SLIPPAGE,
    defaultBlockTag: DEFAULT_BLOCK_TAG,
    referralInfo: {
      referralAddress,
      odosReferralCode,
    },
    useProxyServer: true,
    gasMultiplier: new ZapBigNumber(1.1),
  }),
}

const LAG_THRESHOLD_MAP: ChainIdMap<number> = {
  [ChainId.MAINNET]: 300,
  [ChainId.ARBITRUM_ONE]: 6000,
  [ChainId.BASE]: 1800,
  [ChainId.BERACHAIN]: 1800,
  [ChainId.MANTLE]: 1800,
  [ChainId.POLYGON_ZKEVM]: 1800,
  [ChainId.X_LAYER]: 1800,
}

export function getSubgraphLagThreshold(chainId: ChainId): number {
  return LAG_THRESHOLD_MAP[chainId]
}

// Require a 60-second cool down before the URL can be reset back to index 0
const urlIncrementingMap = new ExpiringMap<string, boolean>(60_000)

function getCurrentSubgraphUrlIndex(
  clientType: GraphqlClientType,
  chainId: ChainId,
  subgraphUrlsMap: ChainIdMap<string[] | undefined>,
): number {
  const urlIndex = store.getState().application.clientToChainToUrlIndex[clientType][chainId]
  const subgraphUrls = subgraphUrlsMap[chainId] ?? subgraphUrlsMap[CHAIN_ID]!
  return urlIndex % subgraphUrls.length
}

export function incrementUrlIndexByClientType(
  clientType: GraphqlClientType,
  dispatch: Dispatch<any>,
  chainId: ChainId,
  currentUrlIndex?: number,
): boolean {
  let urlIndex =
    currentUrlIndex === undefined
      ? store.getState().application.clientToChainToUrlIndex[clientType][chainId] + 1
      : currentUrlIndex + 1

  if (urlIndex % (dolomiteSubgraphURLs[chainId]?.length ?? 1) === 0) {
    const key = `${clientType}-${chainId}-cooldown`
    const isCoolingDown = urlIncrementingMap.get(key) ?? false
    if (isCoolingDown) {
      console.debug(`URL for ${clientType} client and chain ID ${chainId} is cooling down`)
      return false
    }

    urlIncrementingMap.set(key, true)
  }
  console.debug(`Incrementing URL for ${clientType} client and chain ID ${chainId} to index ${urlIndex}`)
  dispatch(
    updateClientToChainToUrlIndex({
      clientType,
      chainId,
      urlIndex,
    }),
  )
  return true
}

export function useActiveDolomiteZapClient(): DolomiteZap | undefined {
  const { chainId } = useActiveWeb3React()
  const subgraphUrlIndex = getCurrentSubgraphUrlIndex(GraphqlClientType.Dolomite, chainId, dolomiteSubgraphURLs)
  return useMemo(() => {
    const zap = CHAIN_ID_TO_ZAP_MAP[chainId]
    if (zap) {
      zap.subgraphUrl = dolomiteSubgraphURLs[chainId]![subgraphUrlIndex]
    }
    return zap
  }, [chainId, subgraphUrlIndex])
}

function buildSubgraphClient(
  clientType: GraphqlClientType,
  cachePolicy: FetchPolicy,
  subgraphUrlsMap: ChainIdMap<string[] | undefined>,
): ApolloClient<any> {
  const expiringMap = new ExpiringMap<string, number>(15_000)
  const httpLink = createHttpLink({ uri: subgraphUrlsMap[CHAIN_ID]![0] })
  const authMiddleware = new ApolloLink((operation, forward) => {
    const chainId = operation.getContext().chainId as ChainId
    operation.setContext(() => {
      const urlIndex = getCurrentSubgraphUrlIndex(clientType, chainId, subgraphUrlsMap)
      const subgraphUrls = subgraphUrlsMap[chainId] ?? subgraphUrlsMap[CHAIN_ID]!
      return {
        chainId,
        uri: subgraphUrls[urlIndex],
        urlIndex,
      }
    })

    return forward(operation)
  })
  const errorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
    const chainId = operation.getContext().chainId as ChainId
    const dispatch = store.dispatch
    const currentIndex = operation.getContext().urlIndex
    const error = graphQLErrors?.[0]?.message ?? networkError?.message

    if (graphQLErrors?.[0]?.message.includes('No indexers found for subgraph deployment')) {
      console.warn(`No indexers found for index ${currentIndex}, trying next subgraph URL`)
      incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
    } else if (graphQLErrors?.[0]?.message.includes('Subgraph not found')) {
      console.warn(`Subgraph not found for index ${currentIndex}, trying next subgraph URL`)
      incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
    } else if (graphQLErrors?.[0]?.message.match(/subgraph [0-9A-Za-z]{32,} has only indexed up to block number/)) {
      console.warn(`Subgraph not fully indexed for index ${currentIndex}, trying next subgraph URL`)
      incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
    } else if (graphQLErrors?.[0]?.message.includes('Payment required')) {
      console.warn(`Subgraph API key expired for index ${currentIndex}, trying next subgraph URL`)
      incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
    } else if (networkError) {
      const statusCode = (networkError as any)?.statusCode ?? 0
      if (statusCode >= 500 && statusCode < 600) {
        console.warn('Encountered server error. trying next subgraph URL')
        incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
      } else {
        console.warn('Encountered network error: ', networkError)
        handleError(networkError.message, expiringMap, currentIndex, clientType, chainId, dispatch)
      }
    } else if (error) {
      handleError(error, expiringMap, currentIndex, clientType, chainId, dispatch)
    }

    return forward(operation)
  })
  return new ApolloClient({
    link: errorLink.concat(authMiddleware).concat(httpLink),
    cache: new InMemoryCache(),
    queryDeduplication: false,
    assumeImmutableResults: true,
    defaultOptions: {
      query: {
        fetchPolicy: cachePolicy,
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: cachePolicy,
        errorPolicy: 'all',
      },
    },
  })
}

function handleError(
  error: string,
  expiringMap: ExpiringMap<string, number>,
  currentIndex: number,
  clientType: GraphqlClientType,
  chainId: ChainId,
  dispatch: Dispatch<any>,
) {
  const key = `${chainId}-${currentIndex}-${error}`
  const errorCount = expiringMap.get(key) ?? 0
  expiringMap.set(key, errorCount + 1)

  if (errorCount === MAX_CONSECUTIVE_ERRORS) {
    console.warn(
      `Caught ${MAX_CONSECUTIVE_ERRORS} consecutive errors for chain ID ${chainId} and subgraph index ${currentIndex}, incrementing URL index. Error:`,
      error,
    )
    incrementUrlIndexByClientType(clientType, dispatch, chainId, currentIndex)
  }
}
