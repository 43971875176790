import React, { useEffect, useState } from 'react'
import { PageSizeContext } from '../../pages/History'
import { useTranslation } from 'react-i18next'
import { ColumnTitles, ConnectBtn, ConnectWalletButton, DataRows } from './styled'
import Web3Status from '../../components/Web3Status'
import Column from '../../components/Orders/Column'
import DataRow, { TransactionColumn } from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { Fraction } from '@dolomite-exchange/v2-sdk'
import { useTransfersByWallet } from '../../types/depositWithdrawalData'
import styled from 'styled-components'
import { TableLoader } from '../Loader'
import { EntityType } from '../../types/gqlTypeHelpers'

const ExpandedInfoTitle = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`

export default function Transfers() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { data: transfers, loading: transferLoading } = useTransfersByWallet(account)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    isLoading && !transferLoading && setIsLoading(false)
  }, [transferLoading, isLoading])

  const widths = {
    widths: [10, 15, 15, 10],
    starts: [3, 31, 57, 87],
    mobileWidths: [15, 20, 15],
    mobileStarts: [3, 31, 82],
  }

  return (
    <PageSizeContext.Consumer>
      {({ x }) => {
        const isMobile = x < 960
        const isTablet = 660 < x && x < 960
        return (
          <>
            <ColumnTitles>
              <Column width={22} mobileWidth={24} textAlign={'left'}>
                {t('action')}
              </Column>
              <Column width={20} mobileWidth={40} textAlign={'left'}>
                {t('amount')}
              </Column>
              <Column width={20} hideOnMobile={true} textAlign={isMobile && !isTablet ? 'right' : 'left'}>
                {t('transaction')}
              </Column>
              <Column width={15} mobileWidth={24} textAlign={'right'}>
                {t('date')}
              </Column>
            </ColumnTitles>
            <DataRows>
              {account ? (
                isLoading ? (
                  <TableLoader
                    rows={isMobile ? 5 : isTablet ? 4 : 3}
                    height={30}
                    spacing={55}
                    marginTop={20}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    widths={widths}
                  />
                ) : (
                  transfers.map((transfer, index) => (
                    <DataRow
                      key={index}
                      columns={[
                        {
                          width: 22,
                          mobileWidth: 24,
                          textAlign: 'left',
                          type: /*isMobile ? 'action' : */ 'text',
                          data: transfer.type === EntityType.Deposit ? 'Deposit' : 'Withdrawal',
                          //date: isMobile && !isTablet ? transfer.transaction.timestamp : undefined,
                        },
                        {
                          width: 20,
                          mobileWidth: 40,
                          textAlign: 'left',
                          type: 'amount',
                          data: transfer.amount as Fraction,
                          token: transfer.token,
                        },
                        {
                          width: 20,
                          textAlign: isMobile && !isTablet ? 'right' : 'left',
                          type: 'transaction',
                          data: transfer.transaction.transactionHash,
                          hideOnMobile: true,
                        },
                        {
                          width: 15,
                          mobileWidth: 24,
                          textAlign: 'right',
                          type: 'time',
                          data: undefined,
                          date: transfer.transaction.timestamp,
                        },
                      ]}
                      enableExpanding={true}
                      history
                      contentHeight={36}
                      expandedHeight={72}
                      expandedContent={
                        <div style={{ textAlign: 'left' }}>
                          <ExpandedInfoTitle>{`Tx hash: `}</ExpandedInfoTitle>
                          <TransactionColumn hash={transfer.transaction.transactionHash} expanded />
                        </div>
                      }
                    />
                  ))
                )
              ) : (
                <ConnectWalletButton>
                  <ConnectBtn>
                    <Web3Status />
                  </ConnectBtn>
                </ConnectWalletButton>
              )}
            </DataRows>
          </>
        )
      }}
    </PageSizeContext.Consumer>
  )
}
