import { Percent } from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'
import { InterestRatePart } from '../../types/InterestRatePart'

export default function useMagicGlpYield(glpYield: InterestRatePart[]): InterestRatePart[] {
  return useMemo(() => {
    // magicGLP takes a 1% fee on top of the APR
    const magicGlpAprWithFees = glpYield[0]?.interestRate?.multiply('99').divide('100')

    return [
      {
        interestRate: magicGlpAprWithFees
          ? new Percent(magicGlpAprWithFees.numerator, magicGlpAprWithFees.denominator)
          : undefined,
        label: 'Auto-compounding yield from GLP',
        isBorrowRateImpacted: false,
        metadata: null,
      },
    ]
  }, [glpYield])
}
