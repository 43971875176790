import { useMemo } from 'react'
import { Transfer } from '../../../types/transferData'
import { CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { BIG_INT_ZERO, ZERO_FRACTION } from '../../../constants'
import { BorrowPosition } from '../../../types/borrowPositionData'
import JSBI from 'jsbi'
import { Transaction } from '../../../types/transaction'
import { Liquidation } from '../../../types/liquidationData'
import { Trade } from '../../../types/tradeData'
import { EntityType } from '../../../types/gqlTypeHelpers'

export interface ActionWithLabel {
  label: string
  serialId: JSBI
  amount: CurrencyAmount<Token>
  amount1?: CurrencyAmount<Token>
  amountUSD: Fraction
  amount1USD?: Fraction
  transaction: Transaction
  actionType: string
}

interface RunningValuesAndActions {
  runningValues: Record<string, CurrencyAmount<Token> | undefined>
  actions: ActionWithLabel[]
}

export default function useSortedActionsWithLabels(
  transfers: Transfer[],
  liquidations: Liquidation[],
  trades: Trade[],
  position: BorrowPosition | undefined,
): ActionWithLabel[] {
  return useMemo(() => {
    return Object.values(
      (transfers as (Transfer | Liquidation | Trade)[])
        .concat(liquidations)
        .concat(trades)
        .sort((a, b) => (JSBI.greaterThan(a.serialId, b.serialId) ? 1 : -1))
        .reduce<Record<string, (Transfer | Liquidation | Trade)[]>>((memo, current) => {
          const id = current.id.split('-')[0]
          const value = memo[id]
          if (value) {
            memo[id] = [...value, current]
          } else {
            memo[id] = [current]
          }
          return memo
        }, {}),
    )
      .reduce<RunningValuesAndActions>(
        (memo, valuesForTransaction) => {
          const firstValue = valuesForTransaction[0]
          const lastValue = valuesForTransaction[valuesForTransaction.length - 1]
          if (
            valuesForTransaction.length >= 2 &&
            firstValue.type === EntityType.Transfer &&
            lastValue.type === EntityType.Transfer
          ) {
            return saveActionsForTransfersForZap(memo, valuesForTransaction, position)
          } else if (firstValue.type === EntityType.Transfer) {
            return saveActionForTransfer(memo, firstValue as Transfer, position)
          } else if (firstValue.type === EntityType.Liquidation) {
            return saveActionForLiquidation(memo, firstValue as Liquidation, position)
          } else if (firstValue.type === EntityType.Trade) {
            return saveActionForTrade(memo, firstValue as Trade, position)
          } else {
            throw new Error(`Invalid data type, found ${firstValue.type}`)
          }
        },
        {
          runningValues: {},
          actions: [],
        },
      )
      .actions.reverse()
  }, [transfers, liquidations, trades, position])
}

function saveActionsForTransfersForZap(
  memo: RunningValuesAndActions,
  valuesForTransaction: (Transfer | Liquidation | Trade)[],
  position: BorrowPosition | undefined,
): RunningValuesAndActions {
  let firstTransfer: Transfer
  let lastTransfer: Transfer
  if (position?.specialInfo.specialAsset?.isIsolationMode || valuesForTransaction.length === 2) {
    firstTransfer = valuesForTransaction[0] as Transfer
    lastTransfer = valuesForTransaction[valuesForTransaction.length - 1] as Transfer
  } else {
    firstTransfer = valuesForTransaction[0] as Transfer
    lastTransfer = valuesForTransaction[valuesForTransaction.length - 1] as Transfer
    if (lastTransfer.toMarginAccount.toString() === position?.id) {
      // If the last transfer is to the position, then it is the real first transfer
      firstTransfer = valuesForTransaction[valuesForTransaction.length - 2] as Transfer
      lastTransfer = valuesForTransaction[valuesForTransaction.length - 3] as Transfer
    }
  }

  let label: string
  // First we check for internal swaps
  if (
    firstTransfer.fromMarginAccount.toString() === position?.id &&
    lastTransfer.toMarginAccount.toString() === position.id &&
    ((memo.runningValues[firstTransfer.token.address]?.greaterThan(ZERO_FRACTION) &&
      (memo.runningValues[lastTransfer.token.address]?.greaterThanOrEqual(ZERO_FRACTION) ||
        memo.runningValues[lastTransfer.token.address] === undefined)) ||
      ((memo.runningValues[firstTransfer.token.address]?.lessThanOrEqual(ZERO_FRACTION) ||
        memo.runningValues[firstTransfer.token.address] === undefined) &&
        memo.runningValues[lastTransfer.token.address]?.lessThan(ZERO_FRACTION)))
  ) {
    if (
      (memo.runningValues[firstTransfer.token.address]?.greaterThanOrEqual(ZERO_FRACTION) ||
        memo.runningValues[firstTransfer.token.address] === undefined) &&
      (memo.runningValues[lastTransfer.token.address]?.greaterThanOrEqual(ZERO_FRACTION) ||
        memo.runningValues[lastTransfer.token.address] === undefined)
    ) {
      label = 'Swap collateral: collateral'
    } else {
      label = 'Swap debt: debt'
    }
  } else if (firstTransfer.toMarginAccount.toString() === position?.id) {
    const balance = memo.runningValues[firstTransfer.token.address]
    if (!balance || balance.greaterThanOrEqual(ZERO_FRACTION)) {
      label = 'Collateral up: and added as collateral'
    } else {
      label = 'Collateral up: and repay'
    }
  } else if (lastTransfer.fromMarginAccount.toString() === position?.id) {
    if (!position.specialInfo.specialAsset?.isIsolationMode) {
      // Remove the dusty transfer
      lastTransfer = valuesForTransaction[valuesForTransaction.length - 2] as Transfer
    }
    const balance = memo.runningValues[firstTransfer.token.address]
    if (!balance || balance.lessThan(ZERO_FRACTION)) {
      label = 'Borrow: and removed as collateral'
    } else {
      label = 'Collateral down: and added to Dolomite Balance'
    }
  } else {
    const firstBalance = memo.runningValues[firstTransfer.token.address]
    const lastBalance = memo.runningValues[lastTransfer.token.address]
    if (firstBalance?.greaterThan(ZERO_FRACTION) && lastBalance?.lessThan(ZERO_FRACTION)) {
      label = 'Repay: and repaid debt'
    } else {
      label = 'Borrow: and added as collateral'
    }
  }
  memo.actions.push({
    label: label,
    serialId: firstTransfer.serialId,
    amount: label.includes('Repay') ? lastTransfer.amount : firstTransfer.amount,
    amount1: label.includes('Repay') ? firstTransfer.amount : lastTransfer.amount,
    amountUSD: label.includes('Repay') ? lastTransfer.amountUSD : firstTransfer.amountUSD,
    amount1USD: label.includes('Repay') ? firstTransfer.amountUSD : lastTransfer.amountUSD,
    transaction: firstTransfer.transaction,
    actionType: 'Trade',
  })
  valuesForTransaction.forEach(valueForTransaction => {
    applyBalanceUpdates(memo, valueForTransaction, position)
  })
  return memo
}

function saveActionForTransfer(
  memo: RunningValuesAndActions,
  transfer: Transfer,
  position: BorrowPosition | undefined,
) {
  const tokenAddress = transfer.token.address
  const balance = memo.runningValues[tokenAddress]
  const label =
    transfer.toMarginAccount.toString() === position?.id
      ? balance?.lessThan(ZERO_FRACTION)
        ? 'Repay'
        : 'Collateral up'
      : balance?.greaterThan(ZERO_FRACTION)
      ? 'Collateral down'
      : 'Borrow'

  applyBalanceUpdates(memo, transfer, position)

  if (!transfer.amount.equalTo(ZERO_FRACTION)) {
    memo.actions.push({
      label: label,
      serialId: transfer.serialId,
      amount: transfer.amount,
      amountUSD: transfer.amountUSD,
      transaction: transfer.transaction,
      actionType: 'Transfer',
    })
  }
  return memo
}

function saveActionForLiquidation(
  memo: RunningValuesAndActions,
  liquidation: Liquidation,
  position: BorrowPosition | undefined,
) {
  const label = 'Repaid'

  applyBalanceUpdates(memo, liquidation, position)

  memo.actions.push({
    label: label,
    serialId: liquidation.serialId,
    amount: liquidation.heldTokenAmountDeltaWei,
    amountUSD: liquidation.heldTokenAmountUSD,
    amount1: liquidation.borrowedTokenAmountDeltaWei,
    amount1USD: liquidation.borrowedTokenAmountUSD,
    transaction: liquidation.transaction,
    actionType: 'Liquidation',
  })
  return memo
}

function saveActionForTrade(memo: RunningValuesAndActions, trade: Trade, position: BorrowPosition | undefined) {
  const inputBalance = memo.runningValues[trade.takerDeltaWei.currency.address]
  const outputBalance = memo.runningValues[trade.makerDeltaWei.currency.address]
  let label: string
  if (inputBalance?.greaterThan(ZERO_FRACTION) && outputBalance?.lessThanOrEqual(ZERO_FRACTION)) {
    label = 'Repay: and repaid debt'
  } else {
    label = 'Borrow: and added as collateral'
  }

  applyBalanceUpdates(memo, trade, position)

  memo.actions.push({
    label: label,
    serialId: trade.serialId,
    amount: label.includes('Repay') ? trade.makerDeltaWei : trade.takerDeltaWei,
    amount1: label.includes('Repay') ? trade.takerDeltaWei : trade.makerDeltaWei,
    amountUSD: label.includes('Repay') ? trade.makerAmountUSD : trade.takerAmountUSD,
    amount1USD: label.includes('Repay') ? trade.takerAmountUSD : trade.makerAmountUSD,
    transaction: trade.transaction,
    actionType: trade.isExpiration ? 'Expiration' : 'Trade',
  })
  return memo
}

function applyBalanceUpdates(
  memo: RunningValuesAndActions,
  valueForTransaction: Transfer | Liquidation | Trade,
  position: BorrowPosition | undefined,
) {
  if (valueForTransaction.type === EntityType.Transfer) {
    const transfer = valueForTransaction as Transfer
    const tokenAddress = transfer.token.address
    if (!memo.runningValues[tokenAddress]) {
      memo.runningValues[tokenAddress] = CurrencyAmount.fromRawAmount(transfer.token, BIG_INT_ZERO)
    }
    if (transfer.toMarginAccount.toString() === position?.id) {
      memo.runningValues[tokenAddress] = memo.runningValues[tokenAddress]?.add(transfer.amount)
    } else {
      memo.runningValues[tokenAddress] = memo.runningValues[tokenAddress]?.subtract(transfer.amount)
    }
  } else if (valueForTransaction.type === EntityType.Liquidation) {
    const liquidation = valueForTransaction as Liquidation
    const heldTokenAddress = liquidation.heldToken.address
    const borrowedTokenAddress = liquidation.borrowedToken.address
    if (!memo.runningValues[heldTokenAddress]) {
      memo.runningValues[heldTokenAddress] = CurrencyAmount.fromRawAmount(liquidation.heldToken, BIG_INT_ZERO)
    }
    if (!memo.runningValues[borrowedTokenAddress]) {
      memo.runningValues[borrowedTokenAddress] = CurrencyAmount.fromRawAmount(liquidation.borrowedToken, BIG_INT_ZERO)
    }

    if (liquidation.solidAccount.toString() === position?.id) {
      memo.runningValues[heldTokenAddress] = memo.runningValues[heldTokenAddress]?.add(
        liquidation.heldTokenAmountDeltaWei,
      )
      memo.runningValues[borrowedTokenAddress] = memo.runningValues[borrowedTokenAddress]?.subtract(
        liquidation.borrowedTokenAmountDeltaWei,
      )
    } else {
      memo.runningValues[heldTokenAddress] = memo.runningValues[heldTokenAddress]?.subtract(
        liquidation.heldTokenAmountDeltaWei,
      )
      memo.runningValues[borrowedTokenAddress] = memo.runningValues[borrowedTokenAddress]?.add(
        liquidation.borrowedTokenAmountDeltaWei,
      )
    }
  } else if (valueForTransaction.type === EntityType.Trade) {
    const trade = valueForTransaction as Trade
    const makerTokenAddress = trade.makerDeltaWei.currency.address
    const takerTokenAddress = trade.takerDeltaWei.currency.address
    if (!memo.runningValues[makerTokenAddress]) {
      memo.runningValues[makerTokenAddress] = CurrencyAmount.fromRawAmount(trade.makerDeltaWei.currency, BIG_INT_ZERO)
    }
    if (!memo.runningValues[takerTokenAddress]) {
      memo.runningValues[takerTokenAddress] = CurrencyAmount.fromRawAmount(trade.takerDeltaWei.currency, BIG_INT_ZERO)
    }

    if (trade.takerAccount.toString() === position?.id) {
      memo.runningValues[makerTokenAddress] = memo.runningValues[makerTokenAddress]?.add(trade.makerDeltaWei)
      memo.runningValues[takerTokenAddress] = memo.runningValues[takerTokenAddress]?.subtract(trade.takerDeltaWei)
    } else {
      memo.runningValues[makerTokenAddress] = memo.runningValues[makerTokenAddress]?.subtract(trade.makerDeltaWei)
      memo.runningValues[takerTokenAddress] = memo.runningValues[takerTokenAddress]?.add(trade.takerDeltaWei)
    }
  } else {
    throw new Error(`Invalid data type, found ${valueForTransaction.type}`)
  }
}
