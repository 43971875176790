import { ChainId, GMX_ADDRESSES, GMX_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGMXProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getFunctionalOutsideAprCallData, getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const GMX_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dGMX,
  safeSymbol: SpecialAssetSymbol.dGMX,
  cleanSymbol: 'GMX',
  cleanName: 'GMX',
  hasExternalRewards: true,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gmx',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GMX_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GMX_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, 'dGMX', 'Dolomite Isolation: GMX'),
      ),
    getUnderlyingToken: getGmxToken,
    getUnderlyingSpenderToken: getGmxToken,
    allowableCollateralTokens: () => [],
    allowableDebtTokens: () => [],
    remapAccountAddress: calculateGMXProxyVaultAddress,
    isAsync: false,
  },
  rewardInfo: {
    getOutsideAprCallData: chainId => getFunctionalOutsideAprCallData(chainId, SpecialAssetSymbol.dGMX),
  },
})

function getGmxToken(chainId: ChainId): Token | undefined {
  return getOptionally(GMX_ADDRESSES[chainId], address => new Token(chainId, address, 18, 'GMX', 'GMX'))
}
