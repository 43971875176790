import { BalanceCheckFlag } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from './useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import {
  useAsyncIsolationModeUserVaultContract,
  useGenericTraderProxyContract,
  useIsolationModeUserVaultContract,
} from './useContract'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { USER_ERROR_CODES, MAX_UINT_256, NEGATIVE_TWO_UINT_256 } from '../constants'
import { calculateGasMargin } from '../utils'
import JSBI from 'jsbi'
import useTransactionDeadline from './useTransactionDeadline'
import { useDolomiteMarginTokenIdMap } from './useDolomiteMarginProtocol'
import { useAllTokens } from './Tokens'
import useIsolationModeUserVaultAddressIfCreated from './useIsolationModeUserVaultAddressIfCreated'
import { SpecialAsset, useSpecialAsset } from '../constants/isolation/special-assets'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import invariant from 'tiny-invariant'
import { ZapOutputParam } from './useGetZapParams'
import { useActiveDolomiteZapClient } from '../apollo/client'

export enum ExtraZapInfo {
  SwapDebt,
  AddCollateral,
  RemoveCollateral,
  SwapCollateral,
}

const MIN_PLV_GLP_AMOUNT = '100000'

export enum ZapEventType {
  None = 0,
  OpenBorrowPosition = 1,
  MarginPosition = 2,
}

export function useZapExactTokensForTokens(
  tradeAccountNumber: JSBI | undefined,
  otherAccountNumber: JSBI | undefined,
  zaps: ZapOutputParam[] | undefined,
  specialAssetForBorrowPosition: SpecialAsset | undefined,
  extraZapInfo: ExtraZapInfo,
  isMaxSelected: boolean,
  eventType: ZapEventType,
  zapOnly: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId } = useActiveWeb3React()
  const genericTraderProxyContract = useGenericTraderProxyContract()
  const deadline = useTransactionDeadline()
  const addTransaction = useTransactionAdder()
  const marketIdToTokenAddressMap = useDolomiteMarginTokenIdMap()
  const tokenMap = useAllTokens()
  const isolationModeVaultAddress = useMemo(() => {
    return specialAssetForBorrowPosition?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  }, [specialAssetForBorrowPosition, account, chainId])
  const isolationModeVaultContract = useIsolationModeUserVaultContract(isolationModeVaultAddress)
  const asyncIsolationModeVaultContract = useAsyncIsolationModeUserVaultContract(isolationModeVaultAddress)

  const inputToken = useMemo(() => {
    const inputMarketId = zaps?.[0]?.marketIdsPath[0]
    if (!inputMarketId) {
      return undefined
    }

    return tokenMap[marketIdToTokenAddressMap[inputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const inputSpecialAsset = useSpecialAsset(inputToken)
  const inputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(inputToken)
  const inputIsolationModeVaultContract = useIsolationModeUserVaultContract(inputIsolationVaultAddress)

  const outputToken = useMemo(() => {
    const zap = zaps?.[0]
    if (!zap) {
      return undefined
    }
    const outputMarketId = zap.marketIdsPath[zap.marketIdsPath.length - 1]
    return tokenMap[marketIdToTokenAddressMap[outputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const outputSpecialAsset = useSpecialAsset(outputToken)
  const outputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(outputToken)
  const outputIsolationModeVaultContract = useIsolationModeUserVaultContract(outputIsolationVaultAddress)
  const zapClient = useActiveDolomiteZapClient()

  return useMemo(() => {
    if (
      !genericTraderProxyContract ||
      !account ||
      !tradeAccountNumber ||
      !otherAccountNumber ||
      !inputToken ||
      !zapClient ||
      !outputToken
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (!zaps || zaps.length === 0) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'No zaps found',
      }
    } else if (inputSpecialAsset?.isIsolationMode && !inputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input isolation vault not yet created',
      }
    } else if (outputSpecialAsset?.isIsolationMode && !outputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output isolation vault not yet created',
      }
    } else if (inputToken.symbol === 'dplvGLP' && zaps[0].amountWeisPath[0].isLessThan(MIN_PLV_GLP_AMOUNT)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input amount is too small for plvGLP',
      }
    } else if (
      outputToken.symbol === 'dplvGLP' &&
      zaps[0].amountWeisPath[zaps[0].amountWeisPath.length - 1].isLessThan(MIN_PLV_GLP_AMOUNT)
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output amount is too small for plvGLP',
      }
    }

    let contract =
      isolationModeVaultContract ??
      inputIsolationModeVaultContract ??
      outputIsolationModeVaultContract ??
      genericTraderProxyContract

    let methodName: string
    let paramArrays: any[][]
    const marketIdIn = zaps[0]?.marketIdsPath?.[0]
    if (zapClient.getIsAsyncAssetByMarketId(marketIdIn)) {
      methodName = 'initiateUnwrapping'
      paramArrays = getAsyncWithdrawContractCallParams(tradeAccountNumber, zaps, marketIdToTokenAddressMap)
      contract = asyncIsolationModeVaultContract!
    } else if (extraZapInfo === ExtraZapInfo.SwapDebt || extraZapInfo === ExtraZapInfo.SwapCollateral) {
      methodName = 'swapExactInputForOutput'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
      paramArrays.forEach(params => {
        params.push({
          deadline: deadline?.toString() ?? '0',
          balanceCheckFlag:
            extraZapInfo === ExtraZapInfo.SwapCollateral ? BalanceCheckFlag.Both : BalanceCheckFlag.None,
          eventType: eventType,
        })
      })
    } else if (extraZapInfo === ExtraZapInfo.AddCollateral) {
      if (isolationModeVaultContract || inputIsolationModeVaultContract || outputIsolationVaultAddress) {
        methodName = 'addCollateralAndSwapExactInputForOutput'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelected)
        paramArrays.forEach(params => {
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      } else {
        methodName = 'swapExactInputForOutputAndModifyPosition'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
        paramArrays.forEach((params, i) => {
          params.push({
            fromAccountNumber: otherAccountNumber.toString(),
            toAccountNumber: tradeAccountNumber.toString(),
            transferAmounts: [
              {
                marketId: zaps[i].marketIdsPath[0].toFixed(),
                amountWei: isMaxSelected ? MAX_UINT_256.toString() : zaps[i].amountWeisPath[0].toFixed(),
              },
            ],
          })
          params.push({
            marketId: 0,
            expiryTimeDelta: 0,
          })
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      }
    } else {
      invariant(extraZapInfo === ExtraZapInfo.RemoveCollateral, 'Invalid extra zap info')
      if (isolationModeVaultContract || inputIsolationModeVaultContract || outputIsolationVaultAddress) {
        methodName = 'swapExactInputForOutputAndRemoveCollateral'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelected)
        paramArrays.forEach(params => {
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      } else {
        methodName = 'swapExactInputForOutputAndModifyPosition'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
        paramArrays.forEach((params, i) => {
          // if max is selected, transfer dust from the input amount
          params.push({
            fromAccountNumber: tradeAccountNumber.toString(),
            toAccountNumber: otherAccountNumber.toString(),
            transferAmounts: [
              {
                marketId: zaps[i].marketIdsPath[zaps[i].marketIdsPath.length - 1].toFixed(),
                amountWei: NEGATIVE_TWO_UINT_256.toString(),
              },
            ],
          })
          params.push({
            marketId: 0,
            expiryTimeDelta: 0,
          })
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      }
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        let firstError: Error | undefined
        for (let i = 0; i < paramArrays.length; i++) {
          const options = { value: zaps[i].executionFee.times(1.5).toFixed() }
          const estimatedCall = await estimateGasAsync(contract, methodName, paramArrays[i], options)
          let successfulCall: SuccessfulContractCall
          if ('gasEstimate' in estimatedCall) {
            successfulCall = estimatedCall
          } else {
            if (!firstError) {
              firstError = estimatedCall.error
            }
            console.error(`Estimate gas for function at index ${i} failed:`, estimatedCall.error)
            continue
          }

          try {
            return await contract[methodName](...paramArrays[i], {
              ...options,
              gasLimit: calculateGasMargin(successfulCall.gasEstimate),
              from: account,
            }).then((response: any) => {
              let summary: string
              if (extraZapInfo === ExtraZapInfo.SwapDebt || zapOnly) {
                summary = `Zap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(outputToken)}`
              } else if (extraZapInfo === ExtraZapInfo.AddCollateral) {
                summary = `Add ${cleanCurrencySymbol(inputToken)} collateral and zap for ${cleanCurrencySymbol(
                  outputToken,
                )}`
              } else if (extraZapInfo === ExtraZapInfo.SwapCollateral) {
                summary = `Swap ${cleanCurrencySymbol(inputToken)} collateral and zap for ${cleanCurrencySymbol(
                  outputToken,
                )}`
              } else {
                summary = `Zap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(
                  outputToken,
                )} and remove collateral`
              }
              addTransaction(response, { summary })
              return response.hash
            })
          } catch (e) {
            const error = e as any
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              return Promise.reject(new Error('transaction-rejected'))
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, paramArrays)
              return Promise.reject(new Error(`Call function failed: ${error.message}`))
            }
          }
        }

        return Promise.reject(firstError)
      },
      error: null,
    }
  }, [
    genericTraderProxyContract,
    zaps,
    account,
    inputSpecialAsset?.isIsolationMode,
    inputIsolationVaultAddress,
    outputSpecialAsset?.isIsolationMode,
    outputIsolationVaultAddress,
    isolationModeVaultContract,
    asyncIsolationModeVaultContract,
    inputIsolationModeVaultContract,
    outputIsolationModeVaultContract,
    extraZapInfo,
    tradeAccountNumber,
    otherAccountNumber,
    deadline,
    isMaxSelected,
    outputToken,
    addTransaction,
    inputToken,
    eventType,
    zapOnly,
    marketIdToTokenAddressMap,
    zapClient,
  ])
}

function getContractCallParams(
  tradeAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  otherAccountNumber: string | JSBI | undefined,
  isMaxSelected: boolean,
): any[][] {
  return zaps.map<any[]>(zap => {
    const otherAccountArray = otherAccountNumber ? [otherAccountNumber.toString()] : []
    return [
      ...otherAccountArray,
      tradeAccountNumber.toString(),
      zap.marketIdsPath.map(marketId => marketId.toFixed()),
      isMaxSelected ? MAX_UINT_256.toString() : zap.amountWeisPath[0].toFixed(),
      zap.amountWeisPath[zap.amountWeisPath.length - 1].toFixed(),
      zap.traderParams.map(trader => ({
        traderType: trader.traderType,
        makerAccountIndex: trader.makerAccountIndex,
        trader: trader.trader,
        tradeData: trader.tradeData,
      })),
      zap.makerAccounts,
    ]
  })
}

function getAsyncWithdrawContractCallParams(
  tradeAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  marketIdToTokenMap: Record<string, string | undefined>,
): any[][] {
  return zaps.map<any[]>(zap => {
    const outputToken = marketIdToTokenMap[zap.marketIdsPath[1].toFixed()]
    invariant(zap.amountWeisPath.length === 2, 'Invalid amountWeisPath length')
    invariant(zap.traderParams.length === 1, 'Invalid traderParams length')
    invariant(!!outputToken, `Could not find output token for marketId [${zap.marketIdsPath[1].toFixed()}]`)

    return [
      tradeAccountNumber.toString(),
      zap.amountWeisPath[0].toFixed(),
      outputToken,
      zap.amountWeisPath[1].toFixed(),
      zap.traderParams.map(trader => ({
        traderType: trader.traderType,
        makerAccountIndex: trader.makerAccountIndex,
        trader: trader.trader,
        tradeData: trader.tradeData,
      }))[0].tradeData,
    ]
  })
}
