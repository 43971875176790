import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'
import PageTitle from '../../components/common/PageTitle'
import { ColumnTitles, formatDate } from '../../components/Leaderboard/Table'
import { SimpleLoader, TableLoader } from '../../components/Loader'
import Column from '../../components/Orders/Column'
import { MarginPosition, MarginPositionStatus, useMarginPositionByIdData } from '../../types/marginPositionData'
import { Transfer, useCombinedTransfersByMarginAccount } from '../../types/transferData'
import { useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { ChartLabel, ChartText, ChartTextLarge, ChartValue } from '../../components/Stats/Charts/styled'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { styled as muiStyled } from '@mui/material'
import LineChart from '../../components/Stats/Charts/LineChart'
import useTheme from '../../hooks/useTheme'
import { FORMATTER, ZERO_ADDRESS, ZERO_FRACTION } from '../../constants'
import moment from 'moment'
import JSBI from 'jsbi'
import getLogoOrDefault from '../../components/common/TokenLogos'
import { formatAmount } from '../../utils/formatAmount'
import { tryParseAmount } from '../../state/trade/hooks'
import { Ether } from '@dolomite-exchange/sdk-core'

const PositionWrapper = styled.div`
  margin: 45px auto;
  width: 80vw;
  min-width: 340px;
  max-width: 600px;

  @media screen and (max-width: 1219px) {
    width: 90vw;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin: 5px auto;
  }

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 730px) {
    width: 90vw;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto 15px;
    width: calc(100vw - 32px);
  }
`

const TextRow = styled.div`
  margin: 14px 0;
  width: 100%;
  height: 22px;
`

const RightText = styled.div`
  float: right;
  display: inline-block;
`

const StatTitle = styled.span`
  margin-right: 8px;
  color: ${({ theme }) => theme.text3};
  vertical-align: top;
`

const PositionData = styled.div`
  float: left;
`

const CurrentPrice = styled.div`
  margin-left: 3px;
  display: inline-block;
`

const ActionPrice = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
`

const TokenWrapper = styled.div`
  margin: 4px 0;
  height: 22px;
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 2px;
  margin-top: 0;
  vertical-align: top;
  width: 22px;

  img {
    width: 100%;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const Account = styled.div`
  float: right;
  cursor: pointer;
`

const ChartValueWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const AddressWrapper = styled.div`
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
`

const AccountIcon = muiStyled(AccountCircleIcon)(() => ({
  fontSize: 25,
  color: '#f9f9f9',
  marginTop: '5px',
}))

const Table = styled.div`
  background-color: #292938;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px;
`

const Leverage = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
`

const TransferRow = styled.div<{ disabled?: boolean }>`
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 50px;
  padding: 15px 20px;
  font-size: 15px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

const Metric = styled.div<{ right?: boolean }>`
  vertical-align: top;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  display: inline-block;
  width: 20%;
`

const TimeText = styled.div`
  font-size: 12px;
  margin-top: -7px;
`

const FieldWrapper = styled.div<{ inline?: boolean }>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'auto')};
  vertical-align: top;
`

export const PageSizeContext = React.createContext({
  x: window.innerWidth,
  y: window.innerHeight,
})

const widths = {
  widths: [12, 12, 12, 18, 12],
  starts: [0, 20, 40, 60, 88],
}

const StatLoader = ({
  data,
  loading,
  x,
  y,
  inline,
}: {
  data: any
  loading: boolean
  x?: number
  y?: number
  inline?: boolean
}) => (
  <FieldWrapper inline={inline}>
    {loading ? <SimpleLoader width={x ?? 100} height={y ?? 16} spacing={y ? y + 3 : 19} marginTop={3} /> : data}
  </FieldWrapper>
)

function isPosition(object: any): object is MarginPosition {
  if (!object) {
    return false
  }

  return 'marginAccount' in object
}

export default function Position() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: positions, loading: positionLoading } = useMarginPositionByIdData(id)
  const position = positions[0]
  const marginAccount = (position.marginAccount.account ?? ZERO_ADDRESS).toLowerCase()
  const { data: transfers } = useCombinedTransfersByMarginAccount(position.marginAccount)
  const [label, setLabel] = useState<string | undefined>()
  const [chartValue, setChartValue] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const open = position ? position.status === MarginPositionStatus.Open : undefined
  const time = position ? (open ? position.openTimestamp : position.closeTimestamp) : 0
  const openDate = moment.utc(position.openTimestamp ?? 0)
  const date = moment.utc(time)
  const token0 = position.heldToken
  const token1 = position.owedToken
  const symbol0 = token0.symbol
  const symbol1 = token1?.symbol
  const price = (open ? position.initialOwedPriceUSD : position.closeOwedPriceUSD)?.toFixed(0, FORMATTER) ?? 0
  const positive = position.marginDeposit.asFraction.greaterThan('0')
  const leverage = positive ? position.heldAmount.asFraction.divide(position.marginDeposit.asFraction) : ZERO_FRACTION
  const amount = `$${position.heldAmount.toFixed(0, FORMATTER)}`
  const profit = position.profit

  useEffect(() => {
    loading && !positionLoading && position && setLoading(false)
  }, [loading, positionLoading, position])

  const tokenPrice = useMemo(() => {
    return token1
      ? CurrencyAmount.fromRawAmount(token1, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(token1.decimals)))
      : undefined
  }, [token1])

  const [current, currentLoading] = useFiatValueWithLoadingIndicator(tokenPrice, token1)

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.onresize = updateSize

    return () => {
      window.onresize = null
    }
  }, [])

  const value = parseFloat(profit.toFixed(0)) ?? 0
  useEffect(() => {
    if (chartValue === undefined && value > 0) {
      setChartValue(value)
    }
  }, [value, chartValue])

  const chartValueAsFraction = useMemo(() => {
    const value = tryParseAmount(chartValue?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [chartValue])

  const titles = [t('date'), t('action'), t('collateral'), t('amount'), t('price')]
  const tableData: (MarginPosition | Transfer)[] = [
    ...(open === false ? [position, ...transfers, position] : [position, ...transfers]),
  ].reverse()

  return (
    <PageSizeContext.Provider value={size}>
      <PositionWrapper>
        <PageTitle title={'Dolomite | Position'} />
        <TextRow>
          <StatTitle>{open ? t('opened') : t('settled')}</StatTitle>
          <StatLoader data={formatDate(date.fromNow())} loading={loading} inline={true} />
          <RightText>
            <StatTitle>{t('fees')}</StatTitle>0
          </RightText>
        </TextRow>
        <LineChart
          data={[]}
          loading={loading}
          height={160}
          minHeight={160}
          color={theme.blue1}
          value={chartValue}
          label={label}
          setValue={setChartValue}
          setLabel={setLabel}
          topLeft={
            <PositionData>
              <StatLoader
                data={
                  <div>
                    {amount}
                    <Leverage>{` (${leverage.toFixed(0)}x)`}</Leverage>
                  </div>
                }
                loading={loading}
                x={70}
              />
              <StatLoader
                data={
                  <TokenWrapper>
                    <TokenLogo>
                      <LogoHelper>
                        <img src={getLogoOrDefault(symbol0 ?? '')} alt={`${symbol0 ?? ''} logo`} />
                      </LogoHelper>
                    </TokenLogo>
                    <TokenLogo>
                      <LogoHelper>
                        <img src={getLogoOrDefault(symbol1 ?? '')} alt={`${symbol1 ?? ''} logo`} />
                      </LogoHelper>
                    </TokenLogo>
                    <CurrentPrice>${current?.toFixed(0, FORMATTER)}</CurrentPrice>
                  </TokenWrapper>
                }
                loading={!symbol0 || currentLoading}
                y={21}
              />
              <StatLoader
                data={<ActionPrice>{`${position.status} @ $${price}`}</ActionPrice>}
                loading={loading}
                inline={true}
                x={85}
                y={13}
              />
            </PositionData>
          }
          topCenter={
            <ChartValueWrapper>
              <ChartLabel>
                <ChartTextLarge>
                  <ChartValue>{`$${formatAmount(chartValueAsFraction)}`.split('.')[0]}</ChartValue>
                </ChartTextLarge>
                <ChartText>{label ? <ChartValue>{label} (UTC)</ChartValue> : null}</ChartText>
              </ChartLabel>
            </ChartValueWrapper>
          }
          topRight={
            <Account>
              <AccountIcon />
              <AddressWrapper onClick={() => history.push(`/account/${marginAccount}`)}>
                {marginAccount.substring(0, 6)}
                <br />
                {marginAccount.substring(marginAccount.length - 5)}
              </AddressWrapper>
            </Account>
          }
        />
        <TextRow>
          <StatTitle>{t('openDate')}</StatTitle>
          <StatLoader data={openDate.format('MMM D YYYY')} loading={loading} inline={true} />
          <RightText>
            <StatTitle>{t('collateral')}</StatTitle>0
          </RightText>
        </TextRow>
        <Table>
          <ColumnTitles>
            {titles.map((title, i) => (
              <Column key={`${i}`} width={20} textAlign={titles.length - 1 === i ? 'right' : 'left'}>
                {title}
              </Column>
            ))}
          </ColumnTitles>
          {position && !loading ? (
            tableData.map((transfer: MarginPosition | Transfer, index: number) => {
              const open = isPosition(transfer) && index === tableData.length - 1
              const date = moment.utc(time)
              return (
                <TransferRow key={`${index}`}>
                  <Metric>
                    <TimeText>
                      {formatDate(date.fromNow())}
                      <br />
                      {date.format('MMM D YYYY')}
                    </TimeText>
                  </Metric>
                  <Metric>
                    {isPosition(transfer)
                      ? open
                        ? t('open')
                        : t('close')
                      : transfer.fromAccountAddress === marginAccount
                      ? t('decrease')
                      : t('increase')}
                  </Metric>
                  <Metric>
                    {isPosition(transfer)
                      ? open
                        ? `${formatAmount(transfer.heldAmount)}`
                        : ''
                      : `${formatAmount(transfer.amount)}`}
                  </Metric>
                  <Metric></Metric>
                  <Metric right={true}>
                    {isPosition(transfer)
                      ? open
                        ? `${formatAmount(transfer.openPriceUSD, undefined, true, '$0.00', true)}`
                        : `${formatAmount(transfer.closePriceUSD, undefined, true, '$0.00', true)}`
                      : ''}
                  </Metric>
                </TransferRow>
              )
            })
          ) : (
            <TableLoader rows={2} height={30} spacing={50} wrapperHeight={100} marginTop={20} widths={widths} />
          )}
        </Table>
      </PositionWrapper>
    </PageSizeContext.Provider>
  )
}
