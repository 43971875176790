import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import ArrowForward from '@material-ui/icons/ArrowForward'
import toDate from '../../../utils/toDate'
import toTime from '../../../utils/toTime'
import Column from '../Column'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { Currency, Fraction, NativeCurrency, Token } from '@dolomite-exchange/v2-sdk'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { Market } from '../../../types/Market'
import { MarginPositionType } from '../../../types/marginPositionData'
import { getEtherscanLink } from '../../../utils'
import { useActiveWeb3React } from '../../../hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip } from '../../common/StyledTooltip'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../../utils/formatAmount'

const DataRowWrapper = styled.div<{
  isCancelled?: boolean
  expanded?: boolean
  contentHeight: number
  expandedHeight?: number
  isFaded?: boolean
  history?: boolean
  disabled?: boolean
  isMargin?: boolean
}>`
    width: 100%;
    height: fit-content;
    font-size: ${({ history }) => (history ? '14px' : '13px')};
    font-weight: 400;
    line-height: ${({ contentHeight }) => contentHeight}px;
    padding: ${({ isMargin }) => (isMargin ? '3px 25px 5px 35px' : '10px 25px 9px')};
    cursor: ${({ expanded, disabled }) => /*expanded || disabled ? 'default' : 'pointer'*/ 'pointer'};
    overflow: hidden;

    ${({ theme, isCancelled }) => isCancelled && `color: ${theme.text3} !important;`}
    ${({ theme, expanded }) => (expanded ? `background-color: ${theme.bg2};` : '')}
    ${({ isFaded }) => isFaded && 'opacity: 0.6;'}
    > div {
        height: ${({ expanded, contentHeight, expandedHeight }) =>
          expandedHeight && expanded ? `${expandedHeight}px;` : `${contentHeight}px;`}
        transition: height 0.2s ease-in-out;

        > div:nth-child(1) {
            height: ${({ contentHeight }) => contentHeight}px;
        }

        > div:nth-child(2) {
            ${({ expanded }) => (expanded ? 'opacity: 1;' : 'opacity: 0;')}
            transition: opacity 0.2s ease-in-out;
        }
    }

    :hover {
        background-color: ${({ theme, disabled }) => !disabled && `${theme.bg2}`};
    }

    @media (max-width: 1000px) {
        font-size: 13px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    padding: 10px 20px 9px;
  `};
`

const DataRowInner = styled.div`
  overflow: hidden;
`

const RowContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TextColumn = styled.div`
  text-transform: capitalize;
`

const DateColumn = styled.div`
  position: relative;
`

const StyledLink = styled.div`
  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 400;
    text-decoration: none;

    svg {
      font-size: 13px;
      vertical-align: middle;
      margin-bottom: 3px;
      opacity: 0.9;
    }

    :hover {
      opacity: 0.7;
    }
  }
`

const TwoRowDateColumn = styled.div`
  width: fit-content;
`

const TimeRow = styled.div`
  width: 100%;
  display: block;
  line-height: 18px;
  font-size: 11px;
  font-weight: 200;
`

const DayRow = styled.div`
  width: 100%;
  display: block;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
`

// const TwoRowDateSpan = styled.div`
//   /*line-height: 24px;*/
// `
//
// const NumberTwoLineColumn = styled.div`
//   line-height: 18px;
//   font-size: 11px;
// `

const StatusColumn = styled.div<{ status: any }>``

// const DirectionColumn = styled.div``

const AmountToggleableColumnWrapper = styled.div``

const ToggleableNumber = styled.span`
  width: fit-content;
  cursor: pointer;
`

const Unit = styled.span`
  color: ${({ theme }) => theme.text3};
`

const MarginArrow = styled.div`
  display: inline-block;
  font-size: 23px;
  margin-left: 15px;

  transform: scale(1.5, 1);
`

const Hash = styled.a`
  text-decoration: none;
  color: inherit;
`

const TradeColumnWrapper = styled.div`
  text-align: left;
  width: 100%;

  svg {
    font-size: 14px;
    margin-bottom: -2px;
  }
`

const TradeSection = styled.span`
  width: calc(50% - 10px);
`

const PnlWithAmount = styled.div`
  display: inline-block;
  vertical-align: top;
`

const PnlPercent = styled.span<{ color: string }>`
  color: ${({ theme, color }) => (color === 'green' ? theme.green1 : theme.red1)};
`

const PnlAmount = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`

const AmountColumnWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
`

export const MarketData = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-left: 3px;
`

const TransactionColumnWrapper = styled.span`
  &:hover {
    color: ${({ theme }) => theme.text2};
  }

  svg {
    font-size: 13px;
    margin-bottom: -2px;
    margin-left: 1px;
    cursor: pointer;
  }
`

const ExpirationWarningBubble = styled.div`
  display: inline-block;
  padding-left: 0;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  top: -2px;
  left: 0;
  width: 13px;
  height: 13px !important;
  padding-right: 0.5px;
  line-height: 13px;
`

const AmountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const AmountSpacer = styled.div`
  width: fit-content;
`

const AmountColumn = ({
  amount,
  ticker,
  amount2,
  ticker2,
  isUSD,
}: {
  amount: Fraction
  ticker: string
  amount2?: Fraction
  ticker2?: string
  isUSD?: boolean
}) => {
  if (amount2) {
    return (
      <AmountWrapper>
        <AmountSpacer>
          {formatAmount(amount, undefined, true, '-')} <Unit>{ticker}</Unit>
        </AmountSpacer>
        <AmountSpacer>
          {formatAmount(amount2, undefined, true, '-')} <Unit>{ticker2}</Unit>
        </AmountSpacer>
      </AmountWrapper>
    )
  } else {
    return (
      <AmountColumnWrapper>
        {isUSD && '$'}
        {formatAmount(amount, undefined, true, '-')} <Unit>{ticker}</Unit>
      </AmountColumnWrapper>
    )
  }
}

const AmountsStackedWrapper = styled.div`
  width: 100%;
  line-height: 14px;
  padding-top: 4px;
`

const StackedAmountRow = styled.div`
  display: inline-block;
  font-size: 11px;
  width: 100%;
`

const AmountsStackedColumn = ({
  amount,
  ticker,
  amount2,
  ticker2,
}: {
  amount: Fraction
  ticker: string
  amount2?: Fraction
  ticker2?: string
}) => {
  return (
    <AmountsStackedWrapper>
      <StackedAmountRow>
        {formatAmount(amount, undefined, true, '-')} <Unit>{ticker}</Unit>
      </StackedAmountRow>
      <StackedAmountRow>
        {formatAmount(amount2, undefined, true, '-')} <Unit>{ticker2}</Unit>
      </StackedAmountRow>
    </AmountsStackedWrapper>
  )
}

const AmountToggleableColumn = ({
  primary,
  secondary,
  primaryToken,
  secondaryToken,
}: //isSecondaryToggled,
//setIsSecondaryToggled,
{
  primary: CurrencyAmount<Currency>
  secondary: CurrencyAmount<Currency>
  primaryToken: Token
  secondaryToken: Token
  //isSecondaryToggled: boolean | undefined
  //setIsSecondaryToggled: ((boolean: any) => void | undefined) | undefined
}) => {
  const [isSecondaryToggled, setIsSecondaryToggled] = useState(false)
  return (
    <AmountToggleableColumnWrapper>
      {primary ? (
        isSecondaryToggled ? (
          <ToggleableNumber
            onClick={e => {
              setIsSecondaryToggled?.(!isSecondaryToggled)
              e.stopPropagation()
            }}
          >
            {formatAmount(secondary)} <Unit>{cleanCurrencySymbol(secondaryToken)}</Unit>
          </ToggleableNumber>
        ) : (
          <ToggleableNumber
            onClick={e => {
              setIsSecondaryToggled?.(!isSecondaryToggled)
              e.stopPropagation()
            }}
          >
            {formatAmount(primary)} <Unit>{cleanCurrencySymbol(primaryToken)}</Unit>
          </ToggleableNumber>
        )
      ) : (
        '-'
      )}
    </AmountToggleableColumnWrapper>
  )
}

const TwoRowColumnWrapper = styled.div`
  width: 100%;
  font-weight: 200;
`

const Row = styled.div<{ color: string | undefined | null; weight?: number; full?: boolean }>`
  width: 100%;
  ${({ theme, color }) =>
    color
      ? color === 'green'
        ? `color: ${theme.green1};`
        : color === 'red'
        ? `color: ${theme.red1};`
        : color === 'gray1'
        ? `color: ${theme.text2};`
        : color === 'gray2'
        ? `color: ${theme.text3};`
        : color === 'yellow'
        ? `color: ${theme.yellow1};`
        : 'color: inherit;'
      : ''}
  ${({ full }) => !full && 'line-height: 18px'};

  ${({ weight }) => (weight ? `font-weight: ${weight};` : '')}
  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;

    ${({ theme, color }) =>
      color
        ? color === 'green'
          ? `color: ${theme.green1};`
          : color === 'red'
          ? `color: ${theme.red1};`
          : color === 'gray1'
          ? `color: ${theme.text2};`
          : color === 'gray2'
          ? `color: ${theme.text3};`
          : color === 'yellow'
          ? `color: ${theme.yellow1};`
          : 'color: inherit;'
        : ''}
    svg {
      font-size: 11px;
      vertical-align: middle;
      margin-top: -1px;
      color: ${({ theme }) => theme.text2};
    }
  }
`

const FitContent = styled.div`
  width: fit-content;
  display: inline-block;
`

const TradeColumn = ({
  fromTicker,
  fromAmount,
  toTicker,
  toAmount,
}: {
  fromTicker?: string
  fromAmount?: Fraction
  toTicker?: string
  toAmount?: Fraction
}) => {
  return (
    <TradeColumnWrapper>
      <TradeSection>
        {formatAmount(fromAmount)} <Unit>{fromTicker}</Unit>
      </TradeSection>{' '}
      <ArrowForward />{' '}
      <TradeSection>
        {formatAmount(toAmount)} <Unit>{toTicker}</Unit>
      </TradeSection>
    </TradeColumnWrapper>
  )
}

const MarginType = styled.span<{ type: string }>`
  color: ${({ theme, type }) => (type === 'LONG' ? theme.green1 : theme.red1)};
`

const PositionColumn = ({
  amount,
  leverage,
  ticker,
  type,
}: {
  amount: Fraction
  leverage: string
  ticker: string
  type: string
}) => {
  return (
    <AmountColumnWrapper>
      <MarginType type={type}>{type}</MarginType>
      &nbsp;&nbsp;
      {formatAmount(amount)} <Unit>{ticker}</Unit>&nbsp;&nbsp;({leverage})
    </AmountColumnWrapper>
  )
}

const OneRowColumn = ({ row, rowColor, rowWeight }: { row: string; rowColor?: string; rowWeight?: number }) => {
  return (
    <Row color={rowColor} weight={rowWeight} full={true}>
      {row}
    </Row>
  )
}

const TwoRowColumn = ({
  rowOne,
  rowOneColor,
  rowOneWeight,
  rowTwo,
  rowTwoColor,
  rowTwoWeight,
}: {
  rowOne: string
  rowOneColor?: string
  rowOneWeight?: number
  rowTwo: string
  rowTwoColor?: string
  rowTwoWeight?: number
}) => {
  return (
    <TwoRowColumnWrapper>
      <Row color={rowOneColor} weight={rowOneWeight}>
        {rowOne}
      </Row>
      <Row color={rowTwoColor} weight={rowTwoWeight} style={{ fontSize: '11px' }}>
        {rowTwo}
      </Row>
    </TwoRowColumnWrapper>
  )
}

const PnlToggleFeesColumn = ({
  rowOne,
  rowOneAlt,
  rowOneColor,
  rowOneAltColor,
  rowTwo,
  rowTwoAlt,
  showProfitWithFees,
}: {
  rowOne: string
  rowOneAlt: string
  rowOneColor?: string
  rowOneAltColor?: string
  rowTwo: string
  rowTwoAlt: string
  showProfitWithFees?: boolean
}) => {
  return (
    <TwoRowColumnWrapper>
      <Row color={showProfitWithFees ? rowOneColor : rowOneAltColor} weight={600}>
        {showProfitWithFees ? rowOne : rowOneAlt}
      </Row>
      <Row color={'gray1'} weight={400} style={{ fontSize: '11px' }}>
        {showProfitWithFees ? rowTwo : rowTwoAlt}
      </Row>
    </TwoRowColumnWrapper>
  )
}

const LongShortMarketWrapper = styled.div`
  width: 100%;
  font-weight: 200;
`

const LongShort = styled.span<{ color: string }>`
  font-weight: 700;
  color: ${({ theme, color }) => (color === 'green' ? theme.green1 : theme.red1)};
`

const LongShortMarket = ({
  longAsset,
  shortAsset,
  longLeverage,
  shortLeverage,
}: {
  longAsset: string
  shortAsset: string
  longLeverage: number
  shortLeverage: number
}) => {
  return (
    <LongShortMarketWrapper>
      <Row color={'inherit'}>
        <LongShort color={'green'}>LONG</LongShort>
        <span>{` ${longAsset} (${longLeverage}x)`}</span>
      </Row>
      <Row color={'inherit'}>
        <LongShort color={'red'}>SHORT</LongShort>
        <span>{` ${shortAsset} (${shortLeverage}x)`}</span>
      </Row>
    </LongShortMarketWrapper>
  )
}

const TwoRowToggleableColumn = ({
  rowOne,
  rowOneAlt,
  rowOneColor,
  rowOneWeight,
  rowTwo,
  rowTwoAlt,
  rowTwoColor,
  rowTwoWeight,
}: {
  rowOne: string
  rowOneAlt: string
  rowOneColor?: string
  rowOneWeight?: number
  rowTwo: string
  rowTwoAlt?: string
  rowTwoColor?: string
  rowTwoWeight?: number
}) => {
  const [isToggled, setIsToggled] = useState(false)

  return (
    <TwoRowColumnWrapper>
      <FitContent
        onClick={(event: React.MouseEvent) => {
          setIsToggled(!isToggled)
          event.stopPropagation()
        }}
        style={{ cursor: 'pointer' }}
      >
        <Row color={rowOneColor} weight={rowOneWeight}>
          {isToggled ? rowOneAlt : rowOne}
        </Row>
        <Row color={rowTwoColor} weight={rowTwoWeight} style={{ fontSize: '11px' }}>
          {isToggled && rowTwoAlt ? rowTwoAlt : rowTwo}
        </Row>
      </FitContent>
    </TwoRowColumnWrapper>
  )
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const TransactionColumn = ({ hash, expanded }: { hash: string; expanded?: boolean }) => {
  const { chainId } = useActiveWeb3React()
  return (
    <TransactionColumnWrapper>
      <Hash href={getEtherscanLink(chainId, hash, 'transaction')} target={'_blank'} rel={'noopener noreferrer'}>
        {`${hash.substring(0, expanded ? 16 : 6)}...${hash.substring(hash.length - (expanded ? 16 : 6))}`}
      </Hash>
      <OpenInNew onClick={() => openInNewTab(getEtherscanLink(chainId, hash, 'transaction'))} />
    </TransactionColumnWrapper>
  )
}

const SpinnerWrapper = styled.div`
  color: white;

  > div {
    width: 24px !important;
    height: 24px !important;
    margin-top: 5px;
  }
`

const ExpandedContent = styled.div<{ expandedHeight: number }>`
  width: 100%;
  height: ${({ expandedHeight }) => expandedHeight}px;
`

export interface DataRowProps {
  columns: {
    width: number
    tabletWidth?: number
    mobileWidth?: number
    customTabletWidth?: number
    customMobileWidth?: number
    textAlign: string
    smallTextAlign?: string | null
    type: string
    data: any
    token?: Token | NativeCurrency | undefined
    data2?: CurrencyAmount<Token> | Fraction
    token2?: Token | NativeCurrency | undefined
    market?: Market
    date?: Date
    otherData?: any
    title?: string
    hideOnMobile?: boolean | null
    hideOnTablet?: boolean | null
    hideOnDesktop?: boolean | null
    amount2?: Fraction
    ticker2?: Token
    link?: string
    isUSD?: boolean
    transactionHash?: string
    showExpirationWarning?: boolean
    showCollateralWarning?: boolean
  }[]
  contentHeight: number
  expandedHeight?: number
  expandedContent?: React.ReactNode
  expanded?: boolean
  toggleExpanded?: (ref: MutableRefObject<any>) => void
  isFaded?: boolean
  disabled?: boolean
  history?: boolean
  pending?: boolean
  enableExpanding?: boolean
  isMargin?: boolean
  shouldUseRealAssetSymbols?: boolean
}

const dataRowComparator = (prevProps: DataRowProps, nextProps: DataRowProps) => {
  return false // JSON.stringify(prevProps) === JSON.stringify(nextProps) No longer works now that a component is passed as a prop, probably smart to change this to something that does a faster and better check
}

const useOutsideAlerter = (ref: any, expanded: boolean, setExpanded: any, enableExpanding: boolean | undefined) => {
  useEffect(() => {
    if (enableExpanding) {
      const handleClickOutside = (event: any) => {
        if (expanded && ref.current && !ref.current.contains(event.target)) {
          setExpanded(false)
        }
      }

      document.addEventListener('mouseup', handleClickOutside)
      return () => {
        document.removeEventListener('mouseup', handleClickOutside)
      }
    }

    return undefined
  }, [ref, setExpanded, expanded, enableExpanding])
}

//TODO - check performance to make sure memoization is beneficial here
const DataRow = React.memo<DataRowProps>(
  ({
    columns,
    contentHeight,
    expandedHeight,
    expandedContent,
    expanded,
    toggleExpanded,
    isFaded,
    disabled,
    history,
    pending,
    enableExpanding,
    isMargin,
    shouldUseRealAssetSymbols: shouldUseRealAssetSymbols,
  }: DataRowProps) => {
    const [internalExpanded, setInternalExpanded] = useState(false)
    const { chainId } = useActiveWeb3React()

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, internalExpanded, setInternalExpanded, enableExpanding)

    return (
      <DataRowWrapper
        expanded={enableExpanding ? internalExpanded : expanded}
        contentHeight={contentHeight}
        expandedHeight={expandedHeight}
        // onClick={() => {
        //   !disabled && enableExpanding
        //     ? setInternalExpanded(!internalExpanded)
        //     : toggleExpanded && toggleExpanded(wrapperRef)
        // }}
        onClick={() => {
          enableExpanding ? setInternalExpanded(!internalExpanded) : toggleExpanded?.(wrapperRef)
        }}
        ref={wrapperRef}
        isFaded={isFaded}
        history={history}
        disabled={!!disabled || !!pending}
        isMargin={isMargin}
      >
        <DataRowInner>
          <RowContent>
            {columns.map((column, index) => (
              <Column
                key={index}
                width={column.width}
                tabletWidth={column.tabletWidth}
                mobileWidth={column.mobileWidth}
                customTabletWidth={column.customTabletWidth}
                customMobileWidth={column.customMobileWidth}
                textAlign={column.textAlign}
                hideOnMobile={column.hideOnMobile}
                hideOnTablet={column.hideOnTablet}
                hideOnDesktop={column.hideOnDesktop}
              >
                {column.type === 'text' ? (
                  <TextColumn>{column.data}</TextColumn>
                ) : column.type === 'amount' ? (
                  <AmountColumn
                    amount={column.data as CurrencyAmount<Token>}
                    ticker={cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}
                    amount2={column.amount2}
                    ticker2={cleanCurrencySymbol(column.token2, !shouldUseRealAssetSymbols)}
                    isUSD={column.isUSD}
                  />
                ) : column.type === 'amountsStacked' ? (
                  <AmountsStackedColumn
                    amount={column.data as CurrencyAmount<Token>}
                    ticker={cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}
                    amount2={column.amount2}
                    ticker2={cleanCurrencySymbol(column.token2, !shouldUseRealAssetSymbols)}
                  />
                ) : column.type === 'amountToggleable' ? (
                  <AmountToggleableColumn
                    primary={column.data as CurrencyAmount<Token>}
                    secondary={column.data2 as CurrencyAmount<Token>}
                    primaryToken={column.token as Token}
                    secondaryToken={column.token2 as Token}
                  />
                ) : column.type === 'time' ? (
                  <DateColumn>
                    {column.showCollateralWarning ? (
                      <StyledTooltip title={'This position has collateral that can be withdrawn'} placement={'top'}>
                        <ExpirationWarningBubble>!</ExpirationWarningBubble>
                      </StyledTooltip>
                    ) : (
                      column.showExpirationWarning && (
                        <StyledTooltip title={'Warning: position close to expiring.'} placement={'top'}>
                          <ExpirationWarningBubble>!</ExpirationWarningBubble>
                        </StyledTooltip>
                      )
                    )}
                    {column.date ? toDate(column.date as Date) : column.data}
                  </DateColumn>
                ) : column.type === 'link' ? (
                  <StyledLink>
                    <a href={column.link} target={'_blank'} rel={'noreferrer'}>
                      {column.data} <OpenInNew />
                    </a>
                  </StyledLink>
                ) : column.type === 'twoRowTime' ? (
                  <TwoRowDateColumn>
                    {
                      <div>
                        <DayRow>{toDate(column.date!) || '-'}</DayRow>
                        <TimeRow>{toTime(column.date!) || '-'}</TimeRow>
                      </div>
                    }
                  </TwoRowDateColumn>
                ) : column.type === 'marginArrow' ? (
                  <MarginArrow>&#8627;</MarginArrow>
                ) : column.type === 'status' ? (
                  <StatusColumn status={column.data}>{column.data !== null ? column.data : '-'}</StatusColumn>
                ) : column.type === 'trade' ? (
                  <TradeColumn
                    fromTicker={cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols)}
                    fromAmount={column.data as Fraction}
                    toTicker={cleanCurrencySymbol(column.token2, !shouldUseRealAssetSymbols)}
                    toAmount={column.data2 as Fraction}
                  />
                ) : column.type === 'action' ? (
                  column.date ? (
                    <TwoRowColumn
                      rowOne={column.date ? toDate(column.date as Date) : 'N/A'}
                      rowOneWeight={600}
                      rowTwo={`${column.data}`}
                      rowTwoColor={
                        column.data.includes('Open')
                          ? 'green'
                          : column.data === 'Closed' || column.data === 'Liquidated'
                          ? 'red'
                          : 'gray1'
                      }
                      rowTwoWeight={400}
                    />
                  ) : (
                    <Row
                      color={
                        column.data.includes('Open')
                          ? 'green'
                          : column.data === 'Closed' || column.data === 'Liquidated'
                          ? 'red'
                          : undefined
                      }
                      full
                    >
                      {column.transactionHash ? (
                        <a
                          href={getEtherscanLink(chainId, column.transactionHash, 'transaction')}
                          target={'_blank'}
                          rel={'noreferrer'}
                          onClick={e => e.stopPropagation()}
                        >
                          {column.data} <OpenInNew />
                        </a>
                      ) : (
                        <span>{column.data}</span>
                      )}
                    </Row>
                  )
                ) : column.type === 'marginAmount' ? (
                  <PositionColumn
                    amount={column.data as CurrencyAmount<Token>}
                    ticker={cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}
                    leverage={`${(column.data2 as Fraction).toFixed(2)}x`}
                    type={`${column.otherData}`}
                  />
                ) : column.type === 'marketSide' ? (
                  <TwoRowColumn
                    rowOne={`${cleanCurrencySymbol(
                      column.market?.primaryToken,
                      !shouldUseRealAssetSymbols,
                    )}-${cleanCurrencySymbol(column.market?.secondaryToken, !shouldUseRealAssetSymbols)}`}
                    rowOneWeight={400}
                    rowTwo={column.otherData?.toString() || '-'}
                    rowTwoColor={(column.otherData as MarginPositionType) === MarginPositionType.LONG ? 'green' : 'red'}
                    rowTwoWeight={700}
                  />
                ) : column.type === 'longShortMarket' ? (
                  <LongShortMarket
                    longAsset={cleanCurrencySymbol(column.data.longAsset, !shouldUseRealAssetSymbols) ?? '-'}
                    shortAsset={cleanCurrencySymbol(column.data.shortAsset, !shouldUseRealAssetSymbols) ?? '-'}
                    longLeverage={
                      column.data.type === MarginPositionType.LONG
                        ? column.data.leverage.toFixed(2)
                        : column.data.leverage.equalTo('0')
                        ? '0.00'
                        : column.data.leverage.add('1').toFixed(2)
                    }
                    shortLeverage={
                      column.data.type === MarginPositionType.SHORT
                        ? column.data.leverage.toFixed(2)
                        : column.data.leverage.equalTo('0')
                        ? '0.00'
                        : column.data.leverage.subtract('1').toFixed(2)
                    }
                  />
                ) : column.type === 'pnl' ? (
                  <OneRowColumn
                    row={`${
                      (column.data as Fraction).greaterThanOrEqual('0') ? '+' : ''
                    }${(column.data as Fraction).toFixed(2)}%`}
                    rowColor={(column.data as Fraction).greaterThanOrEqual('0') ? 'green' : 'red'}
                    rowWeight={600}
                  />
                ) : column.type === 'pnlWithAmount' ? (
                  <PnlWithAmount>
                    <PnlPercent
                      color={(column.data as Fraction).greaterThanOrEqual('0') ? 'green' : 'red'}
                      style={{ fontWeight: 600 }}
                    >
                      {`${
                        (column.data as Fraction).greaterThanOrEqual('0') ? '+' : ''
                      }${(column.data as Fraction).toFixed(2)}%`}
                    </PnlPercent>
                    <PnlAmount>{` (${column.data2?.greaterThan('0') ? '+' : ''}${formatAmount(
                      column.data2,
                    )} ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols)})`}</PnlAmount>
                  </PnlWithAmount>
                ) : column.type === 'pnlLeverage' ? (
                  // data1 is pnl
                  // data2 is leverage
                  <TwoRowColumn
                    rowOne={`${column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? '+' : '') : ''}${
                      column.data ? (column.data as Fraction).toFixed(2) : '-'
                    }%`}
                    rowOneColor={
                      column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? 'green' : 'red') : 'none'
                    }
                    rowOneWeight={600}
                    rowTwo={`${column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? '+' : '') : ''}${
                      column.data2 ? column.data2.toFixed(6) : ''
                    } ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}`}
                    rowTwoColor={'gray1'}
                    rowTwoWeight={400}
                  />
                ) : column.type === 'pnlToggle' ? (
                  // data1 is pnl
                  // data2 is leverage
                  <PnlToggleFeesColumn
                    rowOne={`${column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? '+' : '') : ''}${
                      column.data ? (column.data as Fraction).toFixed(2) : '-'
                    }%`}
                    rowOneColor={
                      column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? 'green' : 'red') : 'none'
                    }
                    rowOneAlt={`${
                      column.otherData.profitPct
                        ? (column.otherData.profitPct as Fraction).greaterThanOrEqual('0')
                          ? '+'
                          : ''
                        : ''
                    }${column.otherData.profitPct ? (column.otherData.profitPct as Fraction).toFixed(2) : '-'}%`}
                    rowOneAltColor={
                      column.otherData.profitPct
                        ? (column.otherData.profitPct as Fraction).greaterThanOrEqual('0')
                          ? 'green'
                          : 'red'
                        : 'none'
                    }
                    rowTwo={`${column.data ? ((column.data as Fraction).greaterThanOrEqual('0') ? '+' : '') : ''}${
                      column.data2 ? column.data2.toFixed(6) : ''
                    } ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}`}
                    rowTwoAlt={`${
                      column.otherData.profit
                        ? (column.otherData.profit as Fraction).greaterThanOrEqual('0')
                          ? '+'
                          : ''
                        : ''
                    }${
                      column.otherData.profit ? (column.otherData.profit as Fraction).toFixed(6) : ''
                    } ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols) ?? ''}`}
                    showProfitWithFees={column.otherData.showProfitWithFees}
                  />
                ) : column.type === 'liqOpen' ? (
                  <TwoRowToggleableColumn
                    rowOne={`${
                      column.data && !(column.data as Fraction).equalTo('0')
                        ? formatAmount(column.data as Fraction)
                        : '-'
                    } ${cleanCurrencySymbol(column.token2, !shouldUseRealAssetSymbols)}`}
                    rowOneAlt={`${
                      column.data && !(column.data as Fraction).equalTo('0')
                        ? formatAmount((column.data as Fraction).invert())
                        : '-'
                    } ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols)}`}
                    rowOneWeight={600}
                    rowTwo={`${column.data2 ? formatAmount(column.data2 as Fraction) : '-'} ${cleanCurrencySymbol(
                      column.token2,
                      !shouldUseRealAssetSymbols,
                    )}`}
                    //TODO - This only works for USD-based markets, inverting the USD price won't work when longing one non-USD asset against another
                    rowTwoAlt={`${
                      column.data2 ? formatAmount((column.data2 as Fraction).invert()) : '-'
                    } ${cleanCurrencySymbol(column.token, !shouldUseRealAssetSymbols)}`}
                    rowTwoColor={'gray1'}
                    rowTwoWeight={400}
                  />
                ) : column.type === 'sizeEquity' ? (
                  // data is size
                  // data2 is equity
                  <TwoRowColumn
                    rowOne={`${column.data ? formatAmount(column.data) : '-'} ${cleanCurrencySymbol(
                      column.token,
                      !shouldUseRealAssetSymbols,
                    )}`}
                    rowOneWeight={600}
                    rowTwo={`${column.data2 ? formatAmount(column.data2) : '-'} ${cleanCurrencySymbol(
                      column.token2,
                      !shouldUseRealAssetSymbols,
                    )}`}
                    rowTwoColor={'gray1'}
                    rowTwoWeight={400}
                  />
                ) : column.type === 'transaction' ? (
                  <TransactionColumn hash={`${column.data?.toString()}`} />
                ) : column.type === 'loading' ? (
                  <SpinnerWrapper>
                    <CircularProgress color={'inherit'} />
                  </SpinnerWrapper>
                ) : (
                  <></>
                )}
              </Column>
            ))}
          </RowContent>
          {expandedHeight && expandedContent && (
            <ExpandedContent expandedHeight={expandedHeight - contentHeight}>{expandedContent}</ExpandedContent>
          )}
        </DataRowInner>
      </DataRowWrapper>
    )
  },
  dataRowComparator,
)
DataRow.displayName = 'DataRow'

export default DataRow
