import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import calculateScaledValueForRiskParams from './calculateScaledValueForRiskParams'
import { ZERO_FRACTION } from '../constants'
import { BorrowPositionAmount } from '../types/borrowPositionData'

export default function calculateBorrowPositionHealth(
  supplyAmounts: BorrowPositionAmount[],
  borrowAmounts: BorrowPositionAmount[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  minCollateralization: Fraction,
): Fraction | undefined {
  if (Object.keys(marketRiskInfoMap).length === 0) {
    return undefined
  }

  try {
    const scaledTotalSupplyAmount = supplyAmounts.reduce((acc, supplyAmount) => {
      const riskInfo = marketRiskInfoMap[supplyAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for supply token', supplyAmount.token.symbol, supplyAmount.token.address)
        throw new Error('No risk info for supply token')
      }
      return acc.add(calculateScaledValueForRiskParams(true, supplyAmount.amountUSD, riskInfo))
    }, ZERO_FRACTION)

    const scaledTotalBorrowAmount = borrowAmounts.reduce((acc, borrowAmount) => {
      const riskInfo = marketRiskInfoMap[borrowAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for borrow token', borrowAmount.token.symbol, borrowAmount.token.address)
        throw new Error('No risk info for borrow token')
      }
      return acc.add(calculateScaledValueForRiskParams(false, borrowAmount.amountUSD, riskInfo))
    }, ZERO_FRACTION)

    if (scaledTotalBorrowAmount.equalTo(ZERO_FRACTION)) {
      return undefined
    } else {
      return scaledTotalSupplyAmount.divide(scaledTotalBorrowAmount.multiply(minCollateralization))
    }
  } catch (e) {
    return undefined
  }
}
