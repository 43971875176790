import { Percent } from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'
import { SECONDS_PER_YEAR, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { useWethToken } from '../Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { useGlpAum, useGmxBalanceWithSupplies, useGmxPriceUSD, useGmxStakingInfos } from './useGmxProtocol'
import useMidAum from './useMidAum'
import { useActiveWeb3React } from '../index'

import { InterestRatePart } from '../../types/InterestRatePart'

const label = 'GLP Fees APR'

export default function useGlpYield(): InterestRatePart[] {
  const { account } = useActiveWeb3React()
  const wethToken = useWethToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => [wethToken], [wethToken]))
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(account)
  const [aums] = useGlpAum()
  const aum = useMidAum(aums)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(account)
  const [gmxPrice] = useGmxPriceUSD()

  return useMemo(() => {
    const supplyData = balanceAndSupplyData?.supplyData
    if (!supplyData || !stakingData || !gmxPrice || !aum || !wethPrice) {
      return [
        {
          label,
          interestRate: ZERO_PERCENT,
          isBorrowRateImpacted: false,
          metadata: null,
        },
      ]
    }
    const glpSupply = supplyData.glp
    const glpPrice = glpSupply?.greaterThan(ZERO_FRACTION) ? aum.divide(glpSupply) : ZERO_FRACTION

    const glpSupplyUsd = glpSupply?.multiply(glpPrice)

    const stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker?.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(gmxPrice)
    const glpAprForEsGmx =
      glpSupplyUsd?.greaterThan(ZERO_FRACTION) && stakedGlpTrackerAnnualRewardsUsd
        ? stakedGlpTrackerAnnualRewardsUsd.divide(glpSupplyUsd)
        : ZERO_FRACTION
    const feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker?.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(wethPrice)
    const glpAprForNativeToken =
      glpSupplyUsd?.greaterThan(ZERO_FRACTION) && feeGlpTrackerAnnualRewardsUsd
        ? feeGlpTrackerAnnualRewardsUsd.divide(glpSupplyUsd)
        : ZERO_FRACTION
    const glpAprTotal = glpAprForNativeToken.add(glpAprForEsGmx)
    return [
      {
        label,
        interestRate: new Percent(glpAprTotal.numerator, glpAprTotal.denominator),
        isBorrowRateImpacted: false,
        metadata: null,
      },
    ]
  }, [balanceAndSupplyData?.supplyData, stakingData, gmxPrice, aum, wethPrice])
}
