import React, { useCallback, useEffect, useState } from 'react'
import TokenSelection from './TokenSelection'
import Balances from './Balances'
import TradePanel from './TradePanel'
import OrderBook from './OrderBook'
import TradingViewChart from './TradingViewChart'
import RecentTrades from './RecentTrades'
import OrdersPanel from './OrdersPanel'
import styled from 'styled-components/macro'
import Stats from './Stats'
import { MEDIA_WIDTHS } from '../../theme'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PageTitle from '../../components/common/PageTitle'
import FirstVisitPopover from '../../components/FirstVisitPopover/FirstVisitPopover'
import useApprovedToS from '../../hooks/useApprovedToS'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '../../constants'
import ConstructionIcon from '@mui/icons-material/Construction'

const PageWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 72px);
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-y: auto;
  `};
`

const LeftSide = styled.div`
  height: calc(100vh - 72px);
  width: 300px;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    margin: 0 10px;
    height: fit-content;
  `};
`

const LeftTop = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 43px;
  text-align: left;
  max-width: 400px;
`

const LeftBottom = styled.div`
  height: calc(100vh - 115px);
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 400px;
  border-top-right-radius: 10px;
  padding: 20px 25px;
  background-color: ${({ theme }) => theme.primary5};
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  text-align: left;
  position: relative;
  pointer-events: none;

  div {
    pointer-events: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 10px;
    height: fit-content;
    margin-top: 8px;
  `};
`

const RightSide = styled.div`
  width: calc(100vw - 300px);
  height: calc(100vh - 72px);
  display: inline-block;
  vertical-align: top;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    margin: 0 10px 90px;
    height: fit-content;
  `};
`

const RightSideInner = styled.div`
  width: 100%;
  height: calc(100% - 45px);

  @media (max-width: 850px) {
    margin-top: 45px;
  }

  @media (max-width: 660px) {
    margin-top: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: fit-content;
  `};
`

const RightRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;

  @media (min-width: 1440px) {
    width: calc(100% - 220px);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: fit-content;
  `};
`

const RightTop = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 65%;
`

const RightBottom = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 35%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    > div {
      margin-left: 0;
      border-radius: 10px;
    }

    margin-top: 15px;
    height: fit-content;
  `};
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  max-width: 380px;
  margin: 0 auto 5px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const TabsWrapper = styled.div``

const TabContent = styled.div<{ wide: boolean }>`
  ${({ wide }) =>
    !wide &&
    `max-width: 400px;
  margin: 0 auto;`}
`

const TradingDisabledWrapper = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 8;
  width: 100vw;
  height: calc(100vh - 64px);
  background: ${({ theme }) => theme.bg1};
  opacity: 0.5;
  cursor: default;

  @media screen and (max-width: 660px) {
    top: 52px;
    height: calc(100vh - 52px);
  }
`

const TradingDisabledPanel = styled.div`
  width: 460px;
  height: auto;
  position: fixed;
  display: flex;
  top: calc(50% - 150px);
  left: calc(50% - 230px);
  z-index: 99;
  background: ${({ theme }) => theme.bg1};
  border-radius: 10px;
  padding: 32px 48px 42px;
  color: ${({ theme }) => theme.text1};
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  opacity: 1;
  font-size: 15px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    width: 380px;
    left: calc(50% - 190px);
    padding: 24px 40px 36px;
    font-size: 13px;
  }

  @media screen and (max-width: 440px) {
    width: 85vw;
    left: 7.5vw;
    top: calc(40% - 150px);
    display: block;
  }
`

const IconWrapper = styled.div`
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
    margin-top: 10px;
    opacity: 0.7;
  }

  @media screen and (max-width: 600px) {
    width: 75px;

    svg {
      margin-top: 18px;
    }
  }

  @media screen and (max-width: 440px) {
    width: 100%;
    height: 90px;
    flex-direction: row;
    display: inline-block;
    text-align: center;

    svg {
      width: auto;
      height: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      opacity: 0.7;
    }
  }
`

const TradingDisabledPanelContent = styled.div`
  width: calc(100% - 120px);
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;

  @media screen and (max-width: 600px) {
    width: calc(100% - 95px);
    margin-left: 20px;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
    margin: 0;
    text-align: center;
  }
`

const TradingDisabledPanelTitle = styled.div`
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 28px;
  }
`

const ComingSoonWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg2};
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  padding: 32px;
  z-index: 99;
  border-radius: 8px;
`

const ComingSoonOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const ComingSoonInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const ComingSoonTitle = styled.div`
  font-size: 23px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
`

const ComingSoonContent = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const Overlay = () => (
  <ComingSoonWrapper>
    <ComingSoonOverlay>
      <ComingSoonInner>
        <ComingSoonTitle>Trading Update Coming Soon!</ComingSoonTitle>
        <ComingSoonContent>
          Our Trade page until now has been based on the Dolomite AMM Pool - a Uniswap V2 style USDc.e/ETH pool. However
          this limits liquidity, and requires a new pool for every trading pair, making it difficult to open sizable
          margin positions and add new markets.
          <br />
          <br />
          But Dolomite is able to offer so much more.
          <br />
          <br />
          {`Dolomite's upgraded Trade page will utilize all the strength of Dolomite's Zap feature - direct protocol
                protocol integrations and integrations with DEX aggregators - to enable margin trading utilizing a much
                wider range of Dolomite's supported assets with minimal slippage. Keep an eye out for updates on our
                socials, and get excited to try some of the best margin trading in DeFi!`}
        </ComingSoonContent>
      </ComingSoonInner>
    </ComingSoonOverlay>
  </ComingSoonWrapper>
)

const TradingDisabledPanelText = styled.div``

export const PageSizeContext = React.createContext({
  x: window.innerWidth,
  y: window.innerHeight,
})

enum TABS {
  CHART = 0,
  STATS = 1,
  DEPTH_CHART = 2,
}

export default function Trade() {
  const { chainId } = useActiveWeb3React()
  const isInvalidChain = chainId !== ChainId.ARBITRUM_ONE
  const [currentTab, setCurrentTab] = useState(TABS.CHART)
  const [hasVisitedExchange, setHasVisitedExchange] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()
  const options = ['Chart', 'Stats', 'Trades']
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = useCallback(
    () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
      }),
    [],
  )
  useEffect(() => {
    window.onresize = updateSize
    const localStorageContentsExchange = window.localStorage.getItem('dolomite_has_visited_exchange')
    if (!localStorageContentsExchange || localStorageContentsExchange !== 'true') {
      setHasVisitedExchange(false)
    }
    return () => {
      window.onresize = null
    }
  }, [updateSize])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_exchange', 'true')
    setHasVisitedExchange(true)
  }, [])

  // TODO - after a certain height, maybe show trade history stacked underneath the depth chart?

  return (
    <PageSizeContext.Provider value={size}>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | Trade' : 'Dolomite | Trade'} />
      {(!hasVisitedExchange || !hasApprovedToS) && (
        <FirstVisitPopover
          width={size.x}
          height={size.y}
          approvedToS={hasApprovedToS}
          setHasVisited={setHasVisited}
          setApprovedToS={setHasApprovedToS}
        />
      )}
      <PageWrapper>
        {isInvalidChain && (
          <TradingDisabledPanel>
            <IconWrapper>
              <ConstructionIcon />
            </IconWrapper>
            <TradingDisabledPanelContent>
              <TradingDisabledPanelTitle>Coming Soon</TradingDisabledPanelTitle>
              <TradingDisabledPanelText>
                Trading is not currently available on this chain, but will be coming soon in a future update!
              </TradingDisabledPanelText>
            </TradingDisabledPanelContent>
          </TradingDisabledPanel>
        )}
        {isInvalidChain && <TradingDisabledWrapper />}
        <LeftSide>
          <LeftTop>
            <TokenSelection />
          </LeftTop>
          {size.x <= MEDIA_WIDTHS.upToSmall && (
            <TabsWrapper>
              <StyledTabs
                value={currentTab}
                onChange={(_: any, index: number) => setCurrentTab(index)}
                indicatorColor={'primary'}
                textColor={'primary'}
              >
                {options.map((option: string, index: number) => (
                  <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
                ))}
              </StyledTabs>
              <TabContent wide={currentTab === TABS.CHART}>
                {currentTab === TABS.CHART ? (
                  <TradingViewChart isMobile={true} />
                ) : currentTab === TABS.STATS ? (
                  <div>
                    <OrderBook />
                    <Stats />
                  </div>
                ) : (
                  <RecentTrades isMobile={true} />
                )}
              </TabContent>
            </TabsWrapper>
          )}
          <LeftBottom>
            <Overlay />
            <Balances />
            <TradePanel />
          </LeftBottom>
        </LeftSide>
        <RightSide>
          {size.x > MEDIA_WIDTHS.upToSmall && <Stats />}
          <RightSideInner>
            {size.x >= 1440 && <OrderBook />}
            <RightRight>
              {size.x > MEDIA_WIDTHS.upToSmall && (
                <RightTop>
                  {size.x > 850 && size.x < 1440 && <RecentTrades />}
                  {size.x > 850 && <TradingViewChart />}
                  {size.x >= 1440 && <RecentTrades />}
                </RightTop>
              )}
              <RightBottom>
                <OrdersPanel />
              </RightBottom>
            </RightRight>
          </RightSideInner>
        </RightSide>
      </PageWrapper>
    </PageSizeContext.Provider>
  )
}
