import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { CollateralActionType, LoanActionType, ManageType } from '../types'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../components/common/StyledTooltip'
import { BalanceCheckFlag, Currency, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import ArrowRight from '@material-ui/icons/ArrowForward'
import BoltIcon from '@mui/icons-material/Bolt'
import { ChainId, DEFAULT_MIN_COLLATERALIZATION, ONE_FRACTION, ZERO_FRACTION } from '../../../constants'
import { DescriptionText, IsolationModeExplainer } from '../OpenNewBorrow'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { useSpecialAsset } from '../../../constants/isolation/special-assets'
import { getPositionHealth } from '../BorrowPositionRow'
import Input from '@material-ui/core/Input'
import {
  BorrowPosition as BorrowPositionData,
  deserializeBorrowPositionAmounts,
  serializeBorrowPositionAmounts,
} from '../../../types/borrowPositionData'
import useNewBorrowPositionHealth from '../../../hooks/useNewBorrowPositionHealth'
import isInputValueCloseToBalance from '../../../utils/isInputValueCloseToBalance'
import useDebounce from '../../../hooks/useDebounce'
import { useDolomiteMarginContract } from '../../../hooks/useContract'
import { tryParseAmount } from '../../../state/trade/hooks'
import {
  useDefaultFiatValuesWithLoadingIndicator,
  useFiatValuesWithLoadingIndicator,
  useFiatValueWithLoadingIndicator,
} from '../../../hooks/useFiatValue'
import { useMarketRiskInfoData } from '../../../types/marketRiskInfoData'
import { useDolomiteMarginData } from '../../../types/dolomiteMarginData'
import ReactGA from 'react-ga'
import { useDefaultMarginAccount } from '../../../types/marginAccount'
import {
  useCloseBorrowPosition,
  useRepayAllForBorrowPosition,
  useTransferAmountForBorrowPosition,
} from '../../../hooks/useBorrowPositionProtocol'
import AdvancedDescription from './AdvancedDescription'
import getNewInterestRateWithSlippage from '../../../utils/getNewInterestRateWithSlippage'
import InterestRateChangedProps from '../InterestRateChangedProps'
import { useDolomiteMarginTokenTvlData } from '../../../types/dolomiteMarginTokenTvlData'
import { useInterestRateData } from '../../../types/interestRateData'
import { useDolomiteMarginTokenAddressToIdMap, useMarketsTotalWeiData } from '../../../hooks/useDolomiteMarginProtocol'
import { Checkbox, Checked } from '../../../components/FirstVisitPopover/FirstVisitPopover'
import BorrowTradeDetails from './BorrowTradeDetails'
import BorrowSubmitButton from './BorrowSubmitButton'
import { useGetZapExactTokensForTokensParams } from '../../../hooks/useGetZapParams'
import { ExtraZapInfo, useZapExactTokensForTokens, ZapEventType } from '../../../hooks/useExecuteZap'
import CurrencyModal from '../../../components/CurrencyModal'
import { useAllActiveTokensArray } from '../../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator, useTokenBalances } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { ChangingHealth } from './ChangingHealth'
import useBorrowRowDisplayTokenList from '../../../hooks/useBorrowRowDisplayTokenList'
import Checkmark from '@material-ui/icons/Check'
import { formatAmount } from '../../../utils/formatAmount'
import { useShowYieldAsApr } from '../../../state/user/hooks'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { useActiveDolomiteZapClient } from '../../../apollo/client'
import { BigNumber } from '@dolomite-exchange/zap-sdk'
import { NETWORK_LABELS } from '../../../constants/chainId'

const ExpandedSection = styled.div<{
  expanded: boolean
  isZapActivated: boolean
  showTradeDetails: boolean
  displayingTokenSelection: boolean
  isolationMode: boolean
}>`
    background: ${({ theme }) => theme.bg2};
    width: calc(100% + 70px);
    margin-left: -35px;
    height: ${({ expanded, isZapActivated, showTradeDetails, isolationMode }) =>
      expanded
        ? isolationMode
          ? isZapActivated
            ? showTradeDetails
              ? '447px'
              : '334px'
            : '214px'
          : isZapActivated
          ? showTradeDetails
            ? '400px'
            : '298px'
          : '178px'
        : '0'};

    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    padding: ${({ expanded }) => (expanded ? '10px' : '0')} 35px;
    transition: ${({ isolationMode, isZapActivated, showTradeDetails }) =>
      (isolationMode && !isZapActivated) || showTradeDetails ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s'};
    overflow: ${({ displayingTokenSelection, expanded }) =>
      expanded ? (displayingTokenSelection ? 'visible' : 'hidden') : 'hidden'};
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    cursor: default;
    position: relative;

    @media screen and (max-width: 615px) {
        width: calc(100% + 48px);
        margin-left: -24px;
        height: ${({ expanded, isolationMode, isZapActivated }) =>
          expanded ? (isZapActivated ? (isolationMode ? '385px' : '355px') : isolationMode ? '275px' : '225px') : '0'};
    }

    @media screen and (max-width: 550px) {
            /*height: ${({ expanded, isolationMode }) => (expanded ? (isolationMode ? '290px' : '250px') : '0')};*/
        height: ${({ expanded, isZapActivated, showTradeDetails, isolationMode }) =>
          expanded
            ? isolationMode
              ? isZapActivated
                ? showTradeDetails
                  ? '626px'
                  : '446px'
                : '341px'
              : isZapActivated
              ? showTradeDetails
                ? '550px'
                : '430px'
              : '285px'
            : '0'};
    }

    @media screen and (max-width: 480px) {
        width: calc(100% + 64px);
        margin-left: -32px;
    }
`

const BorrowExpandedContentWrapper = styled.div`
  width: 100%;
  position: relative;
`

const BorrowContentTop = styled.div`
  display: inline-block;
`

const ActionDescription = styled.div<{ small: boolean }>`
  font-size: 11px;
  line-height: ${({ small }) => (small ? '12px' : '15px')};
  color: ${({ theme }) => theme.text2};
  text-align: left;
  width: 100%;
  margin-top: 3px;
  margin-bottom: -3px;
  height: 28px;

  @media screen and (max-width: 1400px) {
    line-height: ${({ small }) => (small ? '12px' : '15px')};
  }

  @media screen and (max-width: 680px) {
    font-size: 10px;
  }

  @media screen and (max-width: 615px) {
    width: 100%;
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 10px;
    margin-top: 5px;
    height: 36px;
  }

  @media screen and (max-width: 400px) {
    font-size: 10px;
    line-height: 12px;
  }
`

export const NewValue = styled.div<{ isNegative?: boolean | undefined; health?: number; error?: boolean }>`
  color: ${({ theme, isNegative, error }) =>
    error ? theme.red1 : isNegative !== undefined ? (isNegative ? theme.red1 : theme.green1) : 'inherit'};
  ${({ theme, health }) =>
    health && `color: ${health === 3 ? theme.green1 : health === 2 ? theme.yellow1 : theme.red1};`};
`

const InputOuter = styled.div<{ isolationMode: boolean }>`
  width: 100%;
  position: absolute;
  top: ${({ isolationMode }) => (isolationMode ? 75 : 35)}px;
  transition: ${({ isolationMode }) => (isolationMode ? 'top 0.3s ease-in-out' : 'top 0.3s ease-in-out 0.2s')};

  @media screen and (max-width: 615px) {
    top: ${({ isolationMode }) => (isolationMode ? 115 : 56)}px;
    padding-bottom: 37px;
  }
`

export const TopRow = styled.div`
  display: inline-block;
  margin-top: 8px;
  width: calc(100% - 100px);
  height: 34px;
  position: relative;

  @media screen and (max-width: 615px) {
    margin-top: 45px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

export const BalanceRow = styled.div`
  width: 100%;
  text-align: left;
  height: 15px;
  line-height: 15px;
`

export const BalanceTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;

  @media screen and (max-width: 650px) {
    font-size: 11px;
  }
`

export const BalanceValue = styled.span`
  font-size: 12px;
  font-weight: 200;
`

export const NewValueWrapper = styled.span<{ isNegative: boolean | undefined }>`
  width: fit-content;

  svg {
    height: 14px;
    vertical-align: top;
    margin-top: 1.6px;
    color: ${({ theme, isNegative }) =>
      isNegative !== undefined ? (isNegative ? theme.red1 : theme.green1) : 'inherit'};
  }

  ${NewValue} {
    font-size: 13px;
    display: inline-block;
  }
`

export const TokenSymbol = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
`

const MaxButton = styled.div<{ visible: boolean }>`
  color: #606375;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};
  font-size: 14px;
  font-weight: 100;
  vertical-align: top;
  margin-top: -15px;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  right: 0;

  &:hover {
    color: #f9f9f9;
  }
`

const InputWrapper = styled.div`
  /*position: relative;*/
  display: inline-block;
  position: relative;
  width: calc(100% - 100px);
  vertical-align: top;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:first-child {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div:first-child > div {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

export const TempToken = styled.div<{ expanded?: boolean }>`
    pointer-events: none;
    opacity: ${({ expanded }) => (expanded ? '0' : '1')};
    transition: ${({ expanded }) => (expanded ? 'opacity 0s ease-in-out 0.3s' : 'opacity 0s ease-in-out 0s')};
    position: absolute;
    z-index: 999;
    right: 0;
    height: 33px;
    background: gray;
    width: 75px;
    text-align: left;
    line-height: 33px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #3a3a4f;
    font-weight: 300;
    padding-left: 10px;
}
`

const TokenSelector = styled.div<{ disabled: boolean; isLoading?: boolean; visible?: boolean }>`
    background-color: #3a3a4f;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: ${({ isLoading, disabled }) => (isLoading || disabled ? 'default' : 'pointer')};
    height: 33px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    transition: ${({ visible }) => (visible ? 'opacity 0s ease-in-out 0.3s' : 'opacity 0s ease-in-out 0s')};
    width: fit-content;
    z-index: 1;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

    ${({ isLoading }) =>
      isLoading &&
      `
    > div {
      opacity: 0.5;
      :hover {
        background: none !important;
      }
    }
  `}

    ${({ disabled }) =>
      disabled &&
      `
    > div {
      opacity: 0.5;      
      :hover {
        background: none !important;
      }
    }
  `}

    ${({ disabled }) => !disabled && `height: fit-content;`};

    @media screen and (max-width: 550px) {
        left: calc(100% - 81px);
        bottom: 0;
    }
`

const TokenSelectRow = styled.div<{ disabled?: boolean }>`
    font-size: 16px;
    font-weight: 300;
    padding: 5px 25px 5px 10px;
    height: 33px;
    cursor: pointer;
        /*${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.6; cursor: default;'}*/

    &:hover {
        background-color: ${({ theme }) => theme.bg4};
    }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
  position: relative;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    /*opacity: 0.5;*/
    input {
      background: #262535 !important;
    }
  `}

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

export const AdvancedToggle = styled.div`
  width: fit-content;
  font-size: 14px;
  margin: 5px 0;

  svg {
    height: 20px;
    width: 16px;
    margin-bottom: -5px;
    margin-right: -5px;
  }

  > svg {
    height: 16px;
    margin-top: 1px;
    vertical-align: top;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    font-size: 12px;
    line-height: 17px;

    ${Checkbox} {
      margin-top: 0;
    }

    svg {
      height: 18px;
      width: 16px;
      margin-bottom: -5px;
      margin-right: -5px;
    }
  }
`

export const ZapWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${({ theme }) => theme.blue2};
  font-weight: 700;
  margin-right: 5px;
  margin-left: -2px;
`

export const AdvancedSection = styled.div<{
  expanded: boolean
  displayingTokenSelection: boolean
  showTradeDetails: boolean
  open?: boolean
}>`
  width: 100%;
  overflow: ${({ displayingTokenSelection }) => (displayingTokenSelection ? 'visible' : 'hidden')};
  height: ${({ expanded, open, showTradeDetails }) =>
    expanded ? (open ? (showTradeDetails ? '192px' : '85px') : '225px') : '0'};
  transition: ${({ showTradeDetails }) =>
    showTradeDetails ? 'height 0.3s ease-in-out' : 'height 0.3s ease-in-out 0.2s'};

  > ${TopRow} {
    margin-top: 0;
  }

  @media screen and (max-width: 550px) {
    transition: ${({ showTradeDetails }) =>
      showTradeDetails ? 'max-height 0.3s ease-in-out' : 'max-height 0.3s ease-in-out 0.2s'};
    height: auto;
    max-height: ${({ expanded }) => (expanded ? '290px' : '0')};
  }
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

const TooltipResizer = styled.div`
  display: inline-block;
  vertical-align: top;

  svg {
    height: 17px !important;
    margin-top: 1px;
  }

  @media screen and (max-width: 550px) {
    svg {
      margin-top: 0;
    }
  }
`

const IsolationModeWrapper = styled.div`
  > div {
    top: -35px !important;
    left: 0 !important;
    width: 100% !important;
    background: ${({ theme }) => theme.bg1} !important;

    @media screen and (max-width: 615px) {
      top: -37px !important;
    }

    @media screen and (max-width: 550px) {
      left: 32px;
      font-size: 10px;
      line-height: 12px;
      margin-top: 2px;
      top: 83px;
    }
  }
`

const ErrorMessage = styled.div<{ isVisible: boolean; tall: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  font-size: 12px;
  color: ${({ theme }) => theme.red1};
  height: ${({ tall }) => (tall ? 34 : 17)};
  padding-top: ${({ tall }) => (tall ? 0 : 6)}px;
  width: 100%;
`

const ErrorText = styled.div`
  max-width: calc(100% - 121px);
  display: inline-block;
`

const CopyError = styled.span`
  color: ${({ theme }) => theme.text3};
  margin-left: 5px;
  cursor: pointer;
  vertical-align: top;

  svg {
    height: 13px;
    width: 12px;
    transform: translateY(2px);
  }

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const IsoToIsoError = styled.div<{ showError: boolean; open?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  margin-top: ${({ showError }) => (showError ? 5 : -5)}px;
  transition: margin-top 0.2s ease-in-out;
  background: ${({ theme, open }) => (open ? theme.bg2 : theme.bg1)};
  border-radius: 8px;
  padding: 12px 20px;
  height: 86px;

  svg {
    margin-top: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
  }

  @media screen and (max-width: 615px) {
    margin-top: ${({ showError }) => (showError ? 5 : 3)}px;
  }
`

const IsoToIsoErrorInner = styled.div`
  display: flex;
  align-items: center;
`

const IsoToIsoErrorText = styled.div`
  color: ${({ theme }) => theme.yellow1};
  font-weight: 500;
  margin-left: 5px;
`

export const GmFeeExplainer = ({ expanded, visible }: { expanded: boolean; visible: boolean }) => {
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom extraTall>
      <WarningRoundedIcon />
      {`GM assets zaps have a minimum trade size of $1 and require a small ETH fee to be paid to GMX relayers, paid from your wallet. `}
      &nbsp;
      <a href={'https://docs.dolomite.io/integrations/gmx/gm#fees'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

export const INSUFFICIENT_FUNDS = 'Insufficient funds'
export const INVALID_VALUE = 'Invalid value'
export const OVER_PAYMENT = 'Over payment'
export const UNDERCOLLATERALIZED = 'Undercollateralized'
export const DECIMALS_EXCEEDED = 'Too many numbers after decimal'
export const INTENT_BARRIER = 'Intent Assets cannot be zapped to your Dolomite Balance'

interface CurrencySelectModalData {
  tokens: Token[]
  currencySelect: (currency: Currency) => void
  onDismiss: () => void
  balanceMap: Record<string, CurrencyAmount<Token> | undefined>
  fiatBalanceMap: Record<string, Fraction | undefined>
  title: string
}

function BorrowExpandedContentComparator(
  prevProps: BorrowExpandedContentInterface,
  nextProps: BorrowExpandedContentInterface,
) {
  return (
    nextProps.position === prevProps.position &&
    nextProps.selectedTab === prevProps.selectedTab &&
    nextProps.manageType === prevProps.manageType &&
    nextProps.isAttemptingTx === prevProps.isAttemptingTx &&
    nextProps.isTxPending === prevProps.isTxPending &&
    nextProps.expandedInputArea === prevProps.expandedInputArea &&
    nextProps.expanded === prevProps.expanded
  )
}

interface BorrowExpandedContentInterface {
  wrapperRef: React.RefObject<HTMLDivElement>
  position: BorrowPositionData
  selectedTab: CollateralActionType | LoanActionType
  manageType: ManageType
  isAttemptingTx: boolean
  isTxPending: boolean
  expandedInputArea: boolean
  expanded: boolean
  addClosing: (id: string) => void
  setIsDialogOpen: React.Dispatch<SetStateAction<boolean>>
  setIsAttemptingTx: React.Dispatch<SetStateAction<boolean>>
  setSelectedTokenForTransactionConfirmation: React.Dispatch<SetStateAction<Token | undefined>>
  setTransactionPendingText: React.Dispatch<SetStateAction<string>>
  setTxHash: React.Dispatch<SetStateAction<string | undefined>>
  setNewBorrowRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
  setNewSupplyRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
}

function BorrowExpandedContent({
  position,
  wrapperRef,
  selectedTab,
  manageType,
  isAttemptingTx,
  isTxPending,
  expandedInputArea,
  expanded,
  setIsDialogOpen,
  setIsAttemptingTx,
  setSelectedTokenForTransactionConfirmation,
  setTransactionPendingText,
  addClosing,
  setTxHash,
  setNewBorrowRate,
  setNewSupplyRate,
}: BorrowExpandedContentInterface) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const isInvalidChain = chainId === ChainId.X_LAYER || chainId === ChainId.BERACHAIN
  const [showYieldAsApr] = useShowYieldAsApr()
  const tokens = useAllActiveTokensArray()
  const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const { data: riskParams } = useDolomiteMarginData()
  const { data: interestRateMap } = useInterestRateData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const { data: tokenTvlMap } = useDolomiteMarginTokenTvlData()
  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const [marketTotalWeiMap] = useMarketsTotalWeiData()
  const [isZapActivated, setIsZapActivated] = useState(false)
  const [hasCopiedError, setHasCopiedError] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const [selectedZapToken, setSelectedZapToken] = useState<Token | undefined>(undefined)
  const displayTokenList = useBorrowRowDisplayTokenList(
    manageType,
    selectedTab,
    position,
    tokens,
    true,
    isZapActivated,
    selectedToken,
    selectedZapToken,
  )
  const zapDisplayTokenList = useBorrowRowDisplayTokenList(
    manageType,
    selectedTab,
    position,
    tokens,
    false,
    isZapActivated,
    selectedToken,
    selectedZapToken,
  )
  const [inputValue, setInputValue] = useState('')
  const [zapInputValue, setZapInputValue] = useState('')
  const [zapTokenSelectOpen, setZapTokenSelectOpen] = useState(false)
  const [tokenSelectOpen, setTokenSelectOpen] = useState(false)
  const [dolomiteFiatBalanceMap] = useFiatValuesWithLoadingIndicator(dolomiteBalanceData, displayTokenList)
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const dolomiteMarginContract = useDolomiteMarginContract()
  const protocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, zapDisplayTokenList)
  const [zapErrorMessage, setZapErrorMessage] = useState<string | undefined>(undefined)
  const [slippageError, setSlippageError] = useState<string | undefined>(undefined)

  useEffect(() => {
    setSelectedToken(previousToken => {
      if (!previousToken && displayTokenList.length >= 1) {
        return displayTokenList[0]
      } else if (previousToken && !displayTokenList.some(token => token.equals(previousToken))) {
        return displayTokenList[0]
      }

      return previousToken
    })
  }, [displayTokenList, chainId])

  useEffect(() => {
    setSelectedZapToken(previousZapToken => {
      if (zapDisplayTokenList.length === 0) {
        return undefined
      }

      if (selectedToken && previousZapToken && selectedToken.equals(previousZapToken)) {
        return selectedToken && zapDisplayTokenList[0].equals(selectedToken)
          ? zapDisplayTokenList[1]
          : zapDisplayTokenList[0]
      }

      if (previousZapToken && !zapDisplayTokenList.some(token => token.equals(previousZapToken))) {
        return selectedToken && zapDisplayTokenList[0].equals(selectedToken)
          ? zapDisplayTokenList[1]
          : zapDisplayTokenList[0]
      } else if (!previousZapToken && zapDisplayTokenList.length >= 1) {
        if (zapDisplayTokenList.length > 1) {
          return selectedToken && zapDisplayTokenList[0]?.equals(selectedToken)
            ? zapDisplayTokenList[1]
            : zapDisplayTokenList[0]
        } else {
          setSelectedToken(
            displayTokenList[0]?.equals(zapDisplayTokenList[0]) ? displayTokenList[1] : displayTokenList[0],
          )
          return zapDisplayTokenList[0]
        }
      }

      return previousZapToken
    })
  }, [displayTokenList, selectedToken, zapDisplayTokenList])

  const swapSelected = useMemo(() => {
    return (
      (selectedTab === CollateralActionType.SWAP && manageType === ManageType.COLLATERAL) ||
      (selectedTab === LoanActionType.SWAP && manageType === ManageType.LOAN)
    )
  }, [selectedTab, manageType])
  useEffect(() => {
    if (swapSelected) setIsZapActivated(true)
    else setIsZapActivated(false)
  }, [swapSelected])
  const [borrowBalances, borrowFiatBalances] = useMemo(() => {
    const balance: Record<string, CurrencyAmount<Token>> = {}
    const fiatBalance: Record<string, Fraction> = {}
    position.borrowAmounts.map(amount => {
      balance[amount.token.address] = amount.amountTokenWei
      fiatBalance[amount.token.address] = amount.amountUSD
    })
    return [balance, fiatBalance]
  }, [position])

  const [supplyBalances, supplyFiatBalances] = useMemo(() => {
    const balance: Record<string, CurrencyAmount<Token>> = {}
    const fiatBalance: Record<string, Fraction> = {}
    position.supplyAmounts.map(amount => {
      balance[amount.token.address] = amount.amountTokenWei
      fiatBalance[amount.token.address] = amount.amountUSD
    })
    return [balance, fiatBalance]
  }, [position])

  const selectToken = useCallback(
    (token: Token) => {
      if (selectedZapToken && token.symbol === selectedZapToken.symbol) {
        setSelectedZapToken(displayTokenList[0]?.symbol === token.symbol ? displayTokenList[1] : displayTokenList[0])
      }
      setSelectedToken(token)
      setTokenSelectOpen(false)
      setZapInputValue('')
    },
    [displayTokenList, selectedZapToken, setTokenSelectOpen],
  )

  const selectZapToken = useCallback(
    (token: Token) => {
      if (selectedToken && token.symbol === selectedToken.symbol) {
        setSelectedToken(displayTokenList[0]?.symbol === token.symbol ? displayTokenList[1] : displayTokenList[0])
      }
      setSelectedZapToken(token)
      setZapTokenSelectOpen(false)
      setZapInputValue('')
    },
    [displayTokenList, selectedToken, setZapTokenSelectOpen],
  )

  const currencySelectModalData = useMemo<CurrencySelectModalData>(() => {
    if (zapTokenSelectOpen) {
      if (manageType === ManageType.COLLATERAL) {
        if (selectedTab === CollateralActionType.DEPOSIT) {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: supplyBalances,
            fiatBalanceMap: supplyFiatBalances,
            title: t('positionBalanceTitle'),
          }
        } else if (selectedTab === CollateralActionType.WITHDRAW) {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: dolomiteBalanceData,
            fiatBalanceMap: dolomiteFiatBalanceMap,
            title: t('dolomiteBalance'),
          }
        } else {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: supplyBalances,
            fiatBalanceMap: supplyFiatBalances,
            title: t('positionBalanceTitle'),
          }
        }
      } else {
        if (selectedTab === LoanActionType.BORROW) {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: supplyBalances,
            fiatBalanceMap: supplyFiatBalances,
            title: t('positionBalanceTitle'),
          }
        } else if (selectedTab === LoanActionType.REPAY) {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: borrowBalances,
            fiatBalanceMap: borrowFiatBalances,
            title: t('positionBalanceTitle'),
          }
        } else {
          return {
            tokens: zapDisplayTokenList,
            currencySelect: currency => selectZapToken(currency.wrapped),
            onDismiss: () => setZapTokenSelectOpen(false),
            balanceMap: borrowBalances,
            fiatBalanceMap: borrowFiatBalances,
            title: t('positionBalanceTitle'),
          }
        }
      }
    } else {
      if (manageType === ManageType.COLLATERAL) {
        if (selectedTab === CollateralActionType.DEPOSIT) {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: dolomiteBalanceData,
            fiatBalanceMap: dolomiteFiatBalanceMap,
            title: t('dolomiteBalance'),
          }
        } else if (selectedTab === CollateralActionType.WITHDRAW) {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: supplyBalances,
            fiatBalanceMap: supplyFiatBalances,
            title: t('positionBalanceTitle'),
          }
        } else {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: dolomiteBalanceData,
            fiatBalanceMap: dolomiteFiatBalanceMap,
            title: t('dolomiteBalance'),
          }
        }
      } else {
        if (selectedTab === LoanActionType.BORROW) {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: isZapActivated ? borrowBalances : dolomiteBalanceData,
            fiatBalanceMap: isZapActivated ? borrowFiatBalances : dolomiteFiatBalanceMap,
            title: isZapActivated ? t('positionBalanceTitle') : t('dolomiteBalance'),
          }
        } else if (selectedTab === LoanActionType.REPAY) {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: isZapActivated ? supplyBalances : borrowBalances,
            fiatBalanceMap: isZapActivated ? supplyFiatBalances : borrowFiatBalances,
            title: t('positionBalanceTitle'),
          }
        } else {
          return {
            tokens: displayTokenList,
            currencySelect: currency => selectToken(currency.wrapped),
            onDismiss: () => setTokenSelectOpen(false),
            balanceMap: borrowBalances,
            fiatBalanceMap: borrowFiatBalances,
            title: t('positionBalanceTitle'),
          }
        }
      }
    }
  }, [
    isZapActivated,
    zapTokenSelectOpen,
    manageType,
    selectedTab,
    zapDisplayTokenList,
    supplyBalances,
    supplyFiatBalances,
    t,
    selectZapToken,
    dolomiteBalanceData,
    dolomiteFiatBalanceMap,
    displayTokenList,
    selectToken,
    borrowBalances,
    borrowFiatBalances,
  ])

  const selectedSpecialAsset = useSpecialAsset(selectedToken)
  const selectedSpecialZapAsset = useSpecialAsset(selectedZapToken)
  const debouncedInputValue = useDebounce(inputValue, 200)

  const parsedZapInputValue = useMemo(() => {
    const rawParsedZapAmount = tryParseAmount(zapInputValue, selectedZapToken)
    if (!rawParsedZapAmount) {
      return undefined
    }

    if (manageType === ManageType.COLLATERAL) {
      if (selectedTab === CollateralActionType.DEPOSIT) {
        return rawParsedZapAmount
      } else if (selectedTab === CollateralActionType.WITHDRAW) {
        return rawParsedZapAmount
      } else if (selectedTab === CollateralActionType.SWAP) {
        return rawParsedZapAmount
      }
    } else if (manageType === ManageType.LOAN) {
      if (selectedTab === LoanActionType.BORROW) {
        return rawParsedZapAmount
      } else if (selectedTab === LoanActionType.REPAY) {
        return rawParsedZapAmount // TODO - determine if there's a reason to do the max calculations done for the main input value (I think not but should double check)
      } else if (selectedTab === LoanActionType.SWAP) {
        return rawParsedZapAmount
      } else {
        throw new Error(`Invalid loan action type: ${selectedTab}`)
      }
    }

    throw new Error(`Invalid manage type: ${manageType}`)
  }, [manageType, zapInputValue, selectedZapToken, selectedTab])

  const [isMaxSelected, setIsMaxSelected] = useState(false)
  useEffect(() => {
    if (inputValue === '') {
      setIsMaxSelected(false)
    }
  }, [inputValue])
  useEffect(() => {
    if (selectedToken) {
      setIsMaxSelected(false)
    }
  }, [selectedToken])

  const serializedBorrowAmounts = useMemo(() => serializeBorrowPositionAmounts(position.borrowAmounts), [position])
  const serializedSupplyAmounts = useMemo(() => serializeBorrowPositionAmounts(position.supplyAmounts), [position])

  const selectedWalletBalance = useMemo(() => {
    return dolomiteBalanceData[selectedToken?.address ?? ''] ?? undefined
  }, [dolomiteBalanceData, selectedToken])

  const selectedPositionBalance = useMemo(() => {
    if (!selectedToken) {
      return undefined
    }
    const supplyAmounts = deserializeBorrowPositionAmounts(serializedSupplyAmounts)
    const borrowAmounts = deserializeBorrowPositionAmounts(serializedBorrowAmounts)
    const positionAmounts =
      manageType === ManageType.COLLATERAL
        ? supplyAmounts
        : selectedTab === LoanActionType.REPAY && isZapActivated
        ? supplyAmounts
        : borrowAmounts
    return positionAmounts.find(s => s.token.equals(selectedToken))?.amountTokenWei
  }, [selectedToken, serializedSupplyAmounts, serializedBorrowAmounts, manageType, selectedTab, isZapActivated])

  const maxRepayBalance = useMemo(() => {
    if (manageType === ManageType.LOAN && selectedTab === LoanActionType.REPAY && isZapActivated) {
      if (!selectedToken) {
        return undefined
      }
      const supplyAmounts = deserializeBorrowPositionAmounts(serializedSupplyAmounts)
      return supplyAmounts.find(s => s.token.equals(selectedToken))?.amountTokenWei
    } else {
      return selectedWalletBalance?.asFraction.lessThan(selectedPositionBalance?.asFraction ?? ZERO_FRACTION)
        ? selectedWalletBalance
        : selectedPositionBalance
    }
  }, [
    isZapActivated,
    manageType,
    selectedPositionBalance,
    selectedTab,
    selectedToken,
    selectedWalletBalance,
    serializedSupplyAmounts,
  ])

  const parsedInputValue = useMemo(() => {
    const rawParsedAmount = tryParseAmount(debouncedInputValue, selectedToken)
    if (!rawParsedAmount) {
      return undefined
    }

    const borrowAmounts = deserializeBorrowPositionAmounts(serializedBorrowAmounts)
    const supplyAmounts = deserializeBorrowPositionAmounts(serializedSupplyAmounts)
    if (manageType === ManageType.COLLATERAL) {
      if (selectedTab === CollateralActionType.DEPOSIT) {
        return rawParsedAmount
      } else if (selectedTab === CollateralActionType.WITHDRAW || selectedTab === CollateralActionType.SWAP) {
        const supplyAmount = supplyAmounts.find(amount => amount.token.equals(rawParsedAmount.currency))
        if (!supplyAmount) {
          console.warn('Could not find supply amount for withdrawal')
          return rawParsedAmount
        }

        // potentially withdraw all is amount ~max
        const isMax = isMaxSelected || isInputValueCloseToBalance(rawParsedAmount, supplyAmount.amountTokenWei)
        return isMax ? supplyAmount.amountTokenWei : rawParsedAmount
      } else {
        throw new Error(`Invalid collateral action type: ${selectedTab}`)
      }
    } else if (manageType === ManageType.LOAN) {
      if (selectedTab === LoanActionType.BORROW) {
        return rawParsedAmount
      } else if (selectedTab === LoanActionType.REPAY) {
        const borrowAmount = borrowAmounts.find(amount => amount.token.equals(rawParsedAmount.currency))
        if (!borrowAmount) {
          //console.warn('Could not find borrow amount for repayment')
          return rawParsedAmount
        }

        // potentially repay all is amount ~max
        const isMax = isMaxSelected || isInputValueCloseToBalance(rawParsedAmount, borrowAmount.amountTokenWei)
        return isMax ? maxRepayBalance : rawParsedAmount
      } else if (selectedTab === LoanActionType.SWAP) {
        return rawParsedAmount
      } else {
        throw new Error(`Invalid loan action type: ${selectedTab}`)
      }
    }

    throw new Error(`Invalid manage type: ${manageType}`)
  }, [
    debouncedInputValue,
    isMaxSelected,
    maxRepayBalance,
    manageType,
    serializedBorrowAmounts,
    serializedSupplyAmounts,
    selectedTab,
    selectedToken,
  ])

  const selectedPositionZapBalance = useMemo(() => {
    if (!selectedZapToken) {
      return undefined
    }
    const borrowAmounts = deserializeBorrowPositionAmounts(serializedBorrowAmounts)
    const supplyAmounts = deserializeBorrowPositionAmounts(serializedSupplyAmounts)
    const positionAmounts =
      manageType === ManageType.COLLATERAL
        ? selectedTab === LoanActionType.SWAP || selectedTab === CollateralActionType.DEPOSIT
          ? supplyAmounts
          : borrowAmounts
        : (selectedTab === LoanActionType.REPAY && isZapActivated) || selectedTab === LoanActionType.SWAP
        ? borrowAmounts
        : supplyAmounts
    return (
      positionAmounts.find(s => s.token.equals(selectedZapToken))?.amountTokenWei ??
      tryParseAmount('0', selectedZapToken)
    )
  }, [selectedZapToken, serializedBorrowAmounts, serializedSupplyAmounts, manageType, selectedTab, isZapActivated])

  const selectedZapWalletBalance = useMemo(() => {
    return dolomiteBalanceData[selectedZapToken?.address ?? ''] ?? undefined
  }, [dolomiteBalanceData, selectedZapToken])

  const changedPositionBalanceValue = useMemo(() => {
    const isAdditionToPositionBalance =
      (selectedTab === CollateralActionType.DEPOSIT && manageType === ManageType.COLLATERAL) ||
      (selectedTab === LoanActionType.BORROW && manageType === ManageType.LOAN) ||
      (selectedTab === CollateralActionType.SWAP && manageType === ManageType.LOAN)

    const displayChange =
      parsedInputValue &&
      !(selectedTab === CollateralActionType.DEPOSIT && manageType === ManageType.COLLATERAL && isZapActivated)

    return parsedInputValue && displayChange
      ? isAdditionToPositionBalance
        ? selectedPositionBalance?.add(parsedInputValue) ?? parsedInputValue
        : selectedPositionBalance?.subtract(parsedInputValue) ?? parsedInputValue.multiply(-1)
      : undefined
  }, [manageType, parsedInputValue, selectedPositionBalance, selectedTab, isZapActivated])

  const changedPositionAdvancedBalanceValue = useMemo(() => {
    const isAdditionToPositionBalance =
      (selectedTab === CollateralActionType.DEPOSIT && manageType === ManageType.COLLATERAL) ||
      (selectedTab === LoanActionType.BORROW && manageType === ManageType.LOAN) ||
      (selectedTab === CollateralActionType.SWAP && manageType === ManageType.COLLATERAL)

    const displayChange =
      parsedZapInputValue && !(selectedTab === CollateralActionType.WITHDRAW && manageType === ManageType.COLLATERAL)

    return parsedZapInputValue && displayChange
      ? isAdditionToPositionBalance
        ? selectedPositionZapBalance?.add(parsedZapInputValue)
        : selectedPositionZapBalance?.subtract(parsedZapInputValue)
      : undefined
  }, [manageType, parsedZapInputValue, selectedPositionZapBalance, selectedTab])

  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)

  const newPositionHealth = useNewBorrowPositionHealth(
    position,
    parsedInputValue,
    parsedZapInputValue,
    manageType,
    selectedTab,
    fiatValueMap,
    marketRiskInfoMap,
    riskParams?.minCollateralization ?? DEFAULT_MIN_COLLATERALIZATION,
    isZapActivated,
  )

  const swapPositionHealth = newPositionHealth
  /*const swapPositionHealth = useSwapBorrowPositionHealth(
    position,
    parsedInputValue,
    parsedZapInputValue,
    manageType,
    selectedTab,
    fiatValueMap,
    marketRiskInfoMap,
    riskParams?.minCollateralization ?? DEFAULT_MIN_COLLATERALIZATION,
  )*/

  useEffect(() => {
    if (!expanded) {
      setIsZapActivated(false)
      setTokenSelectOpen(false)
      setZapTokenSelectOpen(false)
      setInputValue('')
      setZapInputValue('')
    }
  }, [expanded])

  const changedWalletBalanceValue = useMemo(() => {
    const isAdditionToWalletBalance =
      (selectedTab === CollateralActionType.DEPOSIT && manageType === ManageType.COLLATERAL) ||
      (selectedTab === LoanActionType.REPAY && manageType === ManageType.LOAN)

    const displayChange =
      parsedInputValue &&
      !(selectedTab === CollateralActionType.WITHDRAW && manageType === ManageType.COLLATERAL && isZapActivated) &&
      !(selectedTab === LoanActionType.BORROW && manageType === ManageType.LOAN && isZapActivated) &&
      !(selectedTab === LoanActionType.REPAY && manageType === ManageType.LOAN && isZapActivated)

    return parsedInputValue && selectedWalletBalance && displayChange
      ? isAdditionToWalletBalance
        ? selectedWalletBalance.subtract(parsedInputValue)
        : selectedWalletBalance.add(parsedInputValue)
      : undefined
  }, [manageType, parsedInputValue, selectedTab, selectedWalletBalance, isZapActivated])

  const changedAdvancedWalletBalanceValue = useMemo(() => {
    const isAdditionToWalletBalance =
      (selectedTab === CollateralActionType.WITHDRAW && manageType === ManageType.COLLATERAL) ||
      (selectedTab === LoanActionType.REPAY && manageType === ManageType.LOAN)

    const displayChange =
      parsedZapInputValue && manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.WITHDRAW

    return parsedZapInputValue && selectedZapWalletBalance && displayChange
      ? isAdditionToWalletBalance
        ? selectedZapWalletBalance.add(parsedZapInputValue)
        : selectedZapWalletBalance.subtract(parsedZapInputValue)
      : undefined
  }, [manageType, parsedZapInputValue, selectedZapWalletBalance, selectedTab])

  const newPositionHealthFactor = useMemo(() => {
    return getPositionHealth(newPositionHealth)
  }, [newPositionHealth])

  const swapPositionHealthFactor = useMemo(() => {
    return getPositionHealth(swapPositionHealth)
  }, [swapPositionHealth])

  const isIsolationModeCompatible = isZapActivated
    ? position.specialInfo.specialAsset?.chainIdToAddressMap[chainId] ===
      (manageType === ManageType.LOAN && selectedTab === LoanActionType.SWAP
        ? selectedSpecialAsset?.chainIdToAddressMap[chainId]
        : selectedSpecialZapAsset?.chainIdToAddressMap[chainId])
    : position.specialInfo.specialAsset?.chainIdToAddressMap[chainId] ===
      selectedSpecialAsset?.chainIdToAddressMap[chainId]

  const updateInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*(\.\d*)?$/ // Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      setIsMaxSelected(false)
      setInputValue(e.target.value)
      setZapInputValue('')
    }
  }, [])

  // const updateAdvancedInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  //   const re = /^\d*(\.\d*)?$/ // Only allow numbers and a single decimal point
  //
  //   if (e.target.value === '' || re.test(e.target.value)) {
  //     setZapInputValue(e.target.value)
  //   }
  // }, [])

  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(prev => prev + 1)
  }, [])

  const zapClient = useActiveDolomiteZapClient()
  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    parsedInputValue,
    selectedZapToken,
    isZapActivated,
    zapRefreshIncrementor,
    position.marginAccount.accountNumber.toString(),
    fiatValueMap,
  )
  const bestZap = useMemo(() => zaps?.[0], [zaps])
  useEffect(() => {
    if (bestZap && selectedZapToken) {
      const expectedAmountOut = CurrencyAmount.fromRawAmount(selectedZapToken, bestZap.expectedAmountOut.toFixed(0))
      setZapInputValue(expectedAmountOut.toFixed(selectedZapToken.decimals))
    } else {
      setZapInputValue('')
    }
  }, [bestZap, selectedZapToken])

  const defaultMarginAccount = useDefaultMarginAccount()
  const { callback: submitTransferIntoPosition, error: submitTransferIntoError } = useTransferAmountForBorrowPosition(
    defaultMarginAccount.accountNumber,
    position.marginAccount.accountNumber,
    parsedInputValue,
    BalanceCheckFlag.FromAccount,
    true,
    position.specialInfo.isolationModeVaultAddress,
  )
  const { callback: submitTransferFromPosition, error: submitTransferFromError } = useTransferAmountForBorrowPosition(
    position.marginAccount.accountNumber,
    defaultMarginAccount.accountNumber,
    parsedInputValue,
    BalanceCheckFlag.ToAccount,
    false,
    position.specialInfo.isolationModeVaultAddress,
  )
  const { callback: submitRepayAllForBorrowPosition, error: submitRepayAllError } = useRepayAllForBorrowPosition(
    defaultMarginAccount.accountNumber,
    position.marginAccount.accountNumber,
    parsedInputValue?.currency,
    BalanceCheckFlag.Both,
    position.specialInfo.isolationModeVaultAddress,
  )
  const { callback: submitCloseBorrowPosition, error: submitCloseError } = useCloseBorrowPosition(
    position.marginAccount.accountNumber,
    defaultMarginAccount.accountNumber,
    useMemo(() => (parsedInputValue?.currency ? [parsedInputValue.currency] : undefined), [parsedInputValue]),
    position.specialInfo.isolationModeVaultAddress,
  )
  const defaultAccountNumber = useDefaultMarginAccount().accountNumber

  const { callback: submitZap, error: submitZapError } = useZapExactTokensForTokens(
    position.marginAccount.accountNumber,
    defaultAccountNumber,
    zaps,
    position.specialInfo.specialAsset,
    manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.DEPOSIT
      ? ExtraZapInfo.AddCollateral
      : manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.WITHDRAW
      ? ExtraZapInfo.RemoveCollateral
      : manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.SWAP
      ? ExtraZapInfo.SwapCollateral
      : ExtraZapInfo.SwapDebt,
    isMaxSelected,
    ZapEventType.None,
    false,
  )

  const handleSubmit = useCallback(async () => {
    if (!parsedInputValue) {
      console.error('Invalid input value for submission')
      return
    }

    const amount = formatAmount(parsedInputValue, 6, true)
    let isClose = false
    const unwrappedSymbol = cleanCurrencySymbol(parsedInputValue.currency) ?? ''
    const unwrappedZapSymbol = cleanCurrencySymbol(parsedZapInputValue?.currency) ?? ''
    let callback: (() => Promise<string>) | null
    let error: string | null
    let actionText: string
    setZapErrorMessage(undefined)
    if (isZapActivated) {
      callback = submitZap
      error = submitZapError
      const zapAmount = formatAmount(parsedZapInputValue ?? ZERO_FRACTION, 6, true)
      if (manageType === ManageType.COLLATERAL) {
        if (selectedTab === CollateralActionType.DEPOSIT) {
          actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} and deposit into borrow position`
        } else if (selectedTab === CollateralActionType.WITHDRAW) {
          actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} and withdraw from borrow position`
        } else {
          actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} within borrow position`
        }
      } else {
        if (selectedTab === LoanActionType.BORROW) {
          actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} for borrow position`
        } else if (selectedTab === LoanActionType.REPAY) {
          actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} to repay borrow position`
        } else {
          actionText = `Borrow ${amount} ${unwrappedSymbol} and zap to ~${zapAmount} ${unwrappedZapSymbol} to repay within borrow position`
        }
      }
    } else if (manageType === ManageType.COLLATERAL) {
      if (selectedTab === CollateralActionType.DEPOSIT) {
        callback = submitTransferIntoPosition
        error = submitTransferIntoError
        actionText = `Deposit ${amount} ${unwrappedSymbol} into borrow position`
      } else if (selectedTab === CollateralActionType.WITHDRAW) {
        const supplyAmount = position.supplyAmounts.find(amount => amount.token.equals(parsedInputValue.currency))
        if (!supplyAmount) {
          console.warn('Could not find supply amount for withdrawal:', parsedInputValue.currency.symbol)
          return
        }

        // potentially close borrow position if ~max
        const isMax = isMaxSelected || isInputValueCloseToBalance(parsedInputValue, supplyAmount.amountTokenWei)
        if (isMax) {
          callback = submitCloseBorrowPosition
          error = submitCloseError
          actionText = `Withdraw all ${unwrappedSymbol} collateral from borrow position`
          isClose = position.supplyAmounts.length === 1 && position.borrowAmounts.length === 0
        } else {
          callback = submitTransferFromPosition
          error = submitTransferFromError
          actionText = `Withdraw ${amount} ${unwrappedSymbol} collateral from borrow position`
        }
      } else {
        console.error('Invalid collateral action type', selectedTab)
        return
      }
    } else if (manageType === ManageType.LOAN) {
      if (selectedTab === LoanActionType.BORROW) {
        callback = submitTransferFromPosition
        error = submitTransferFromError
        actionText = `Increase borrow by ${amount} ${unwrappedSymbol} for borrow position`
      } else if (selectedTab === LoanActionType.REPAY) {
        // potentially repay all borrow position if ~max
        const borrowAmount = position.borrowAmounts.find(amount => amount.token.equals(parsedInputValue.currency))
        if (!borrowAmount) {
          console.warn('Could not find borrow amount for repayment:', parsedInputValue.currency.symbol)
          return
        }

        const isMax = isMaxSelected || isInputValueCloseToBalance(parsedInputValue, borrowAmount.amountTokenWei)
        if (isMax && !selectedWalletBalance?.lessThan(borrowAmount.amountTokenWei)) {
          callback = submitRepayAllForBorrowPosition
          error = submitRepayAllError
          actionText = `Repay all ${unwrappedSymbol} debt for borrow position`
        } else {
          callback = submitTransferIntoPosition
          error = submitTransferIntoError
          actionText = `Repay ${amount} ${unwrappedSymbol} debt for borrow position`
        }
      } else {
        console.error('Invalid loan action type', selectedTab)
        return
      }
    } else {
      console.error('Invalid manage type', manageType)
      return
    }

    if (!callback) {
      console.error('Callback is not defined for submitting borrow action:', error)
      return
    }

    setSelectedTokenForTransactionConfirmation(parsedInputValue.currency)
    setIsAttemptingTx(true)
    setIsDialogOpen(true)
    setTransactionPendingText(actionText)
    callback()
      .then(txHash => {
        setTxHash(txHash)
        setIsAttemptingTx(false)
        setInputValue('')
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
        isClose && addClosing(position.id)
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setZapErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setZapErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setZapErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }

        setIsDialogOpen(false)
        setIsAttemptingTx(false)
      })
  }, [
    parsedInputValue,
    parsedZapInputValue,
    isZapActivated,
    manageType,
    setSelectedTokenForTransactionConfirmation,
    setIsAttemptingTx,
    setIsDialogOpen,
    setTransactionPendingText,
    submitZap,
    submitZapError,
    selectedTab,
    submitTransferIntoPosition,
    submitTransferIntoError,
    position.supplyAmounts,
    position.borrowAmounts,
    position.id,
    isMaxSelected,
    submitCloseBorrowPosition,
    submitCloseError,
    submitTransferFromPosition,
    submitTransferFromError,
    selectedWalletBalance,
    submitRepayAllForBorrowPosition,
    submitRepayAllError,
    setTxHash,
    addClosing,
  ])

  const [isInputValueValid, inputError] = useMemo(() => {
    const inputMarketId = tokenAddressToMarketIdMap[selectedToken?.address ?? '']?.toString()
    if (changedWalletBalanceValue?.lessThan(ZERO_FRACTION)) {
      return [false, INSUFFICIENT_FUNDS]
    } else if (
      changedPositionBalanceValue &&
      manageType === ManageType.COLLATERAL &&
      changedPositionBalanceValue.lessThan(ZERO_FRACTION)
    ) {
      return [false, INSUFFICIENT_FUNDS]
    } else if (
      changedPositionBalanceValue &&
      manageType === ManageType.LOAN &&
      changedPositionBalanceValue.lessThan(ZERO_FRACTION)
    ) {
      return [false, OVER_PAYMENT]
    } else if (
      (selectedTab === CollateralActionType.SWAP ? swapPositionHealth : newPositionHealth)?.lessThanOrEqual(
        ONE_FRACTION,
      )
    ) {
      return [false, UNDERCOLLATERALIZED]
    } else if ((inputValue.split('.')[1]?.length ?? 0) > (selectedToken?.decimals ?? 0)) {
      return [false, DECIMALS_EXCEEDED]
    } else if (!parsedInputValue) {
      return [false, INVALID_VALUE]
    } else if (
      isZapActivated &&
      manageType === ManageType.COLLATERAL &&
      selectedTab === CollateralActionType.DEPOSIT &&
      inputMarketId &&
      zapClient?.getIsAsyncAssetByMarketId(new BigNumber(inputMarketId))
    ) {
      return [false, INTENT_BARRIER]
    } else if (
      isZapActivated &&
      manageType === ManageType.COLLATERAL &&
      selectedTab === CollateralActionType.WITHDRAW &&
      inputMarketId &&
      zapClient?.getIsAsyncAssetByMarketId(new BigNumber(inputMarketId))
    ) {
      return [false, INTENT_BARRIER]
    }

    return [true, undefined]
  }, [
    changedPositionBalanceValue,
    changedWalletBalanceValue,
    manageType,
    newPositionHealth,
    parsedInputValue,
    inputValue,
    selectedToken,
    swapPositionHealth,
    selectedTab,
    isZapActivated,
    tokenAddressToMarketIdMap,
    zapClient,
  ])

  const isClosingTransaction = useMemo(() => {
    if (!parsedInputValue) {
      return false
    }
    if (selectedTab === CollateralActionType.SWAP) {
      return false
    }
    const supplyAmount = position.supplyAmounts.find(amount => amount.token.equals(parsedInputValue.currency))
    if (!supplyAmount) {
      return false
    }
    const isMax = isMaxSelected || isInputValueCloseToBalance(parsedInputValue, supplyAmount.amountTokenWei)
    return isMax && position.supplyAmounts.length === 1 && position.borrowAmounts.length === 0
  }, [isMaxSelected, parsedInputValue, position.borrowAmounts.length, position.supplyAmounts, selectedTab])

  const isBorrowStateValid = useMemo(() => {
    const isBorrowingEnabled = marketRiskInfoMap[selectedToken?.address ?? '']?.isBorrowingDisabled !== true
    return (
      isBorrowingEnabled ||
      !(
        (selectedTab === LoanActionType.BORROW || selectedTab === LoanActionType.SWAP) &&
        manageType === ManageType.LOAN
      )
    )
  }, [marketRiskInfoMap, selectedToken, selectedTab, manageType])

  // we either are 1) NOT in isolation mode or 2) in a compatible isolation mode
  const isIsolationModeCompatibilityValid = useMemo(() => {
    if (isZapActivated) {
      if (position.specialInfo.isolationModeVaultAddress && selectedSpecialZapAsset?.isIsolationMode) {
        return (
          position.specialInfo.specialAsset?.chainIdToAddressMap[chainId] ===
            selectedSpecialZapAsset.chainIdToAddressMap[chainId] ||
          !selectedSpecialZapAsset.chainIdToAddressMap[chainId]
        )
      } else {
        return !selectedSpecialZapAsset?.isIsolationMode
      }
    }
    if (position.specialInfo.isolationModeVaultAddress && selectedSpecialAsset?.isIsolationMode) {
      return (
        position.specialInfo.specialAsset?.chainIdToAddressMap[chainId] ===
          selectedSpecialAsset.chainIdToAddressMap[chainId] || !selectedSpecialAsset.chainIdToAddressMap[chainId]
      )
    } else {
      return !selectedSpecialAsset?.isIsolationMode
    }
  }, [
    chainId,
    isZapActivated,
    position.specialInfo.isolationModeVaultAddress,
    position.specialInfo.specialAsset,
    selectedSpecialAsset,
    selectedSpecialZapAsset,
  ])

  const insufficientLiquidityError = useMemo(() => {
    if (isZapActivated) {
      const protocolBalance = protocolBalanceInfoMap[selectedToken?.address ?? '']
      if (!parsedInputValue || !protocolBalance || selectedSpecialAsset?.isIsolationMode) {
        return false
      }
      return protocolBalance.lessThan(parsedInputValue)
    }
    return false
  }, [isZapActivated, parsedInputValue, protocolBalanceInfoMap, selectedToken, selectedSpecialAsset?.isIsolationMode])

  const showSupplyCapError = useMemo(() => {
    if (isZapActivated) {
      const totalSupplyWei = marketTotalWeiMap[selectedZapToken?.address ?? '']
      const supplyMaxWei = marketRiskInfoMap[selectedZapToken?.address ?? '']?.supplyMaxWei
      if (!supplyMaxWei || !totalSupplyWei || supplyMaxWei.equalTo(ZERO_FRACTION) || !parsedZapInputValue) {
        return false
      }
      return totalSupplyWei.supplyWei.add(parsedZapInputValue).greaterThan(supplyMaxWei)
    }

    return false
  }, [parsedZapInputValue, marketRiskInfoMap, marketTotalWeiMap, selectedZapToken, isZapActivated])

  useEffect(() => {
    const tokenTvl = tokenTvlMap[parsedInputValue?.currency.address ?? '']?.borrowLiquidity
    if (manageType !== ManageType.LOAN || !parsedInputValue || !tokenTvl) {
      setNewBorrowRate(undefined)
      setNewSupplyRate(undefined)
      return
    }
    if (isZapActivated && !parsedZapInputValue) {
      setNewBorrowRate(undefined)
      setNewSupplyRate(undefined)
      return
    }

    if (isZapActivated) {
      if (manageType === ManageType.LOAN && selectedTab === LoanActionType.BORROW) {
        const { borrowInterestRate } = getNewInterestRateWithSlippage(
          parsedInputValue,
          CurrencyAmount.fromRawAmount(parsedInputValue.currency, 0),
          interestRateMap,
          tokenAddressToMarketIdMap,
          marketTotalWeiMap,
          riskParams,
          showYieldAsApr,
        )
        const { supplyInterestRate } = getNewInterestRateWithSlippage(
          CurrencyAmount.fromRawAmount(parsedZapInputValue!.currency, 0),
          parsedZapInputValue!,
          interestRateMap,
          tokenAddressToMarketIdMap,
          marketTotalWeiMap,
          riskParams,
          showYieldAsApr,
        )
        setNewBorrowRate({
          token: parsedInputValue.currency,
          rate: borrowInterestRate,
        })
        setNewSupplyRate({
          token: parsedZapInputValue!.currency,
          rate: supplyInterestRate,
        })
      } else if (selectedTab === LoanActionType.REPAY) {
        const { supplyInterestRate } = getNewInterestRateWithSlippage(
          CurrencyAmount.fromRawAmount(parsedInputValue.currency, 0),
          parsedInputValue.multiply(-1),
          interestRateMap,
          tokenAddressToMarketIdMap,
          marketTotalWeiMap,
          riskParams,
          showYieldAsApr,
        )
        const { borrowInterestRate } = getNewInterestRateWithSlippage(
          parsedZapInputValue!.multiply(-1),
          CurrencyAmount.fromRawAmount(parsedZapInputValue!.currency, 0),
          interestRateMap,
          tokenAddressToMarketIdMap,
          marketTotalWeiMap,
          riskParams,
          showYieldAsApr,
        )
        setNewBorrowRate({
          token: parsedZapInputValue!.currency,
          rate: borrowInterestRate,
        })
        setNewSupplyRate({
          token: parsedInputValue.currency,
          rate: supplyInterestRate,
        })
      } else {
        setNewBorrowRate(undefined)
        setNewSupplyRate(undefined)
      }
    } else {
      const multiplier = selectedTab === LoanActionType.BORROW ? 1 : -1
      const rates = getNewInterestRateWithSlippage(
        parsedInputValue.multiply(multiplier),
        parsedInputValue.multiply(multiplier),
        interestRateMap,
        tokenAddressToMarketIdMap,
        marketTotalWeiMap,
        riskParams,
        showYieldAsApr,
      )
      setNewBorrowRate({
        token: parsedInputValue.currency,
        rate: rates.borrowInterestRate,
      })
      setNewSupplyRate({
        token: parsedInputValue.currency,
        rate: rates.supplyInterestRate,
      })
    }
  }, [
    showYieldAsApr,
    tokenTvlMap,
    parsedInputValue,
    manageType,
    isZapActivated,
    parsedZapInputValue,
    selectedTab,
    interestRateMap,
    tokenAddressToMarketIdMap,
    marketTotalWeiMap,
    riskParams,
    setNewBorrowRate,
    setNewSupplyRate,
  ])

  const actionDescription = useMemo(() => {
    return manageType === ManageType.COLLATERAL
      ? selectedTab === CollateralActionType.DEPOSIT
        ? 'Adding collateral will transfer assets from your Dolomite Balance to this borrow position, increasing position health.'
        : selectedTab === CollateralActionType.WITHDRAW
        ? 'Removed collateral will transfer from this borrow position to your Dolomite Balance, decreasing position health and the amount you can borrow.'
        : 'Swapping collateral will transfer collateral from one asset in your borrow position to another assert, keeping postion health similar.'
      : selectedTab === LoanActionType.BORROW
      ? 'Borrowing will add assets to your Dolomite Balance and increase the amount owed on this borrow position, decreasing position health.'
      : selectedTab === LoanActionType.REPAY
      ? 'Assets from your Dolomite Balance will be used to reduce the amount owed on this borrow position, increasing your position health.'
      : 'Swapping assets will transfer debt from one borrowed asset from this borrow position to another asset, keeping postion health similar.'
  }, [manageType, selectedTab])

  //TODO - currently a naive approach, for several options it needs to take into account position health and max that
  // out, or remove the option if based on position health. In some cases it's split, for example when removing
  // collateral, it can be bounded by either the amount of collateral in the position OR the minimum position health
  // after removing the collateral.
  const setMax = useCallback(() => {
    if (selectedTab === CollateralActionType.DEPOSIT && manageType === ManageType.COLLATERAL) {
      const decimals = selectedWalletBalance?.currency.decimals ?? 6
      setInputValue(selectedWalletBalance?.toFixed(decimals) ?? '0')
    } else if (selectedTab === LoanActionType.BORROW && manageType === ManageType.LOAN) {
      const decimals = selectedWalletBalance?.currency.decimals ?? 6
      setInputValue(selectedWalletBalance?.toFixed(decimals) ?? '0')
    } else if (selectedTab === LoanActionType.REPAY && manageType === ManageType.LOAN) {
      if (isZapActivated) {
        const decimals = selectedPositionBalance?.currency.decimals ?? 6
        setInputValue(selectedPositionBalance ? selectedPositionBalance.toFixed(decimals) : '0')
      } else {
        const decimals = maxRepayBalance?.currency.decimals ?? 6
        setInputValue(maxRepayBalance?.toFixed(decimals) ?? '0')
      }
    } else if (selectedTab === CollateralActionType.WITHDRAW && manageType === ManageType.COLLATERAL) {
      const decimals = selectedPositionBalance?.currency.decimals ?? 6
      setInputValue(selectedPositionBalance?.toFixed(decimals) ?? '0')
    } else if (selectedTab === CollateralActionType.SWAP && manageType === ManageType.COLLATERAL) {
      const decimals = selectedPositionBalance?.currency.decimals ?? 6
      setInputValue(selectedPositionBalance?.toFixed(decimals) ?? '0')
    } else if (selectedTab === LoanActionType.SWAP && manageType === ManageType.LOAN) {
      const decimals = selectedPositionBalance?.currency.decimals ?? 6
      setInputValue(selectedPositionBalance?.toFixed(decimals) ?? '0')
    }
    setIsMaxSelected(true)
  }, [isZapActivated, selectedTab, manageType, selectedWalletBalance, maxRepayBalance, selectedPositionBalance])

  const isLoadingZapTradeData =
    isZapActivated &&
    !!selectedZapToken &&
    !!inputValue &&
    !bestZap &&
    !!parsedInputValue &&
    parsedInputValue.greaterThan(0)

  const displayIsolationModeWarning = useMemo(() => {
    if (isZapActivated) {
      if (manageType === ManageType.COLLATERAL) {
        if (selectedTab === CollateralActionType.DEPOSIT) {
          return !!selectedSpecialZapAsset?.isIsolationMode && !isIsolationModeCompatible
        } else if (selectedTab === CollateralActionType.WITHDRAW) {
          return false
        } else {
          return !!selectedSpecialZapAsset?.isIsolationMode && !isIsolationModeCompatible
        }
      } else {
        if (selectedTab === LoanActionType.BORROW) {
          return (
            !!selectedSpecialAsset?.isIsolationMode ||
            (!!selectedSpecialZapAsset?.isIsolationMode && !isIsolationModeCompatible)
          )
        } else if (selectedTab === LoanActionType.REPAY) {
          return false
        } else {
          !!selectedSpecialAsset?.isIsolationMode
        }
      }
    }
    return !!selectedSpecialAsset?.isIsolationMode && !isIsolationModeCompatible
  }, [
    isIsolationModeCompatible,
    isZapActivated,
    manageType,
    selectedSpecialAsset?.isIsolationMode,
    selectedSpecialZapAsset?.isIsolationMode,
    selectedTab,
  ])

  const showGmExplainer = useMemo(() => {
    return !!(
      isZapActivated &&
      (selectedSpecialAsset?.isolationModeInfo?.isAsync || selectedSpecialZapAsset?.isolationModeInfo?.isAsync)
    )
  }, [
    isZapActivated,
    selectedSpecialAsset?.isolationModeInfo?.isAsync,
    selectedSpecialZapAsset?.isolationModeInfo?.isAsync,
  ])

  const [inputFiatValueAmount] = useFiatValueWithLoadingIndicator(parsedInputValue, selectedToken)
  const minGmTradeError = useMemo(() => {
    return showGmExplainer && selectedToken && inputFiatValueAmount?.lessThan(1)
  }, [inputFiatValueAmount, selectedToken, showGmExplainer])

  useEffect(() => {
    if (zapError?.includes('slippageTolerance')) {
      setSlippageError(t('zapSlippageError'))
    }
  }, [zapError, t])

  const errorMessage = useMemo(() => {
    return (
      !!zapErrorMessage ??
      !!submitTransferIntoError ??
      !!submitTransferFromError ??
      !!submitRepayAllError ??
      !!submitCloseError ??
      //!!gmFeeError ||
      !!minGmTradeError ??
      !!slippageError ??
      (submitZapError && parsedInputValue?.greaterThan(0) && !bestZap ? submitZapError : undefined)
    )
  }, [
    zapErrorMessage,
    submitTransferIntoError,
    submitTransferFromError,
    submitRepayAllError,
    submitCloseError,
    //gmFeeError,
    minGmTradeError,
    slippageError,
    submitZapError,
    parsedInputValue,
    bestZap,
  ])

  const copyErrorToClipboard = () => {
    navigator.clipboard
      .writeText(typeof errorMessage === 'boolean' ? '' : errorMessage ?? '')
      .then(() => setHasCopiedError(true))
  }

  const tooltipText = useMemo(() => {
    if (!isBorrowStateValid) {
      return 'This asset cannot be borrowed'
    }
    if (!isIsolationModeCompatibilityValid) {
      if (manageType === ManageType.LOAN) {
        return 'Isolation mode assets can not be borrowed.'
      } else {
        return 'Isolation mode assets can only be added as collateral to an existing position in the same isolation mode or when opening a new position.'
      }
    }
    if (!inputValue) {
      return 'No amount entered'
    }
    if (insufficientLiquidityError) {
      return 'Insufficient supply liquidity for token'
    }
    if (showSupplyCapError) {
      return 'Supply cap exceeded'
    }
    /*if (gmFeeError) {
      return 'Insufficient ETH in wallet for GM fee'
    }*/
    if (minGmTradeError) {
      return 'Minimum GM trade amount is $1'
    }
    if (!isInputValueValid) {
      if (inputError === UNDERCOLLATERALIZED) {
        return 'Not enough collateral'
      } else if (inputError === OVER_PAYMENT) {
        return 'Over payment of debt'
      } else if (inputError === INVALID_VALUE) {
        return 'The value inputted is invalid'
      } else if (inputError === DECIMALS_EXCEEDED) {
        return 'Too many numbers after decimal'
      } else if (inputError === INTENT_BARRIER) {
        return INTENT_BARRIER
      }
      return 'Insufficient funds'
    }
    if (isAttemptingTx) {
      return 'Awaiting signature...'
    }
    if (isTxPending) {
      return 'Awaiting confirmation...'
    }
    if (isClosingTransaction) {
      return 'This will remove the last of the collateral in the borrow position and close it.'
    }
    return undefined
  }, [
    isBorrowStateValid,
    isIsolationModeCompatibilityValid,
    inputValue,
    insufficientLiquidityError,
    showSupplyCapError,
    //gmFeeError,
    minGmTradeError,
    isInputValueValid,
    isAttemptingTx,
    isTxPending,
    isClosingTransaction,
    manageType,
    inputError,
  ])

  const displayErrorMessage = !isLoadingZapTradeData && !!parsedInputValue && !!errorMessage && !tooltipText // If errors aren't displaying check the connection to tooltipText first. Corey's hesitancy may be warranted.

  // TODO - add tooltip to token ticker and logo that shows the current interest rate and index price
  return (
    <ExpandedSection
      expanded={expandedInputArea}
      isZapActivated={isZapActivated}
      showTradeDetails={
        !!bestZap ||
        isLoadingZapTradeData ||
        (isZapActivated && !!selectedSpecialZapAsset?.isIsolationMode && !!selectedSpecialAsset?.isIsolationMode)
      }
      displayingTokenSelection={tokenSelectOpen || zapTokenSelectOpen}
      onClick={e => e.stopPropagation()}
      isolationMode={displayIsolationModeWarning || showGmExplainer}
    >
      {(tokenSelectOpen || zapTokenSelectOpen) && (
        <CurrencyModal
          tokens={currencySelectModalData.tokens}
          balances={currencySelectModalData.balanceMap}
          fiatBalances={currencySelectModalData.fiatBalanceMap}
          isOpen={zapTokenSelectOpen || tokenSelectOpen}
          onDismiss={currencySelectModalData.onDismiss}
          currencySelect={currencySelectModalData.currencySelect}
          balanceTitle={currencySelectModalData.title}
          borrow={true}
        />
      )}
      <BorrowExpandedContentWrapper>
        <BorrowContentTop>
          <ActionDescription
            small={manageType === ManageType.COLLATERAL && selectedTab !== CollateralActionType.SWAP && !!inputValue}
          >
            {actionDescription}
          </ActionDescription>
        </BorrowContentTop>
        <ChangingHealth
          inputValue={inputValue}
          newPositionHealth={selectedTab === CollateralActionType.SWAP ? swapPositionHealth : newPositionHealth}
          newPositionHealthFactor={
            selectedTab === CollateralActionType.SWAP ? swapPositionHealthFactor : newPositionHealthFactor
          }
          position={position}
          isLoadingZap={isLoadingZapTradeData}
          isolationModeWarning={displayIsolationModeWarning || showGmExplainer}
        />
        <InputOuter isolationMode={displayIsolationModeWarning || showGmExplainer}>
          <IsolationModeWrapper>
            <IsolationModeExplainer expanded={true} visible={displayIsolationModeWarning} />
            <GmFeeExplainer expanded={true} visible={showGmExplainer} />
          </IsolationModeWrapper>
          <TopRow>
            <BalanceRow>
              <StyledTooltip
                title={`The amount of ${cleanCurrencySymbol(selectedToken) + ' ' || 'assets'} ${
                  manageType === ManageType.COLLATERAL
                    ? 'currently in this borrow position as collateral'
                    : selectedTab === LoanActionType.REPAY && isZapActivated
                    ? 'currently in this borrow position as collateral'
                    : 'you currently have borrowed in this borrow position'
                }.`}
                placement={'top'}
              >
                <BalanceTitle>
                  {manageType === ManageType.LOAN
                    ? selectedTab === LoanActionType.REPAY && isZapActivated
                      ? t('positionCollateral')
                      : t('positionDebt')
                    : t('positionCollateral')}
                </BalanceTitle>
              </StyledTooltip>
              <BalanceValue>
                {manageType === ManageType.LOAN && selectedTab === LoanActionType.SWAP && !selectedPositionBalance
                  ? '0.00'
                  : formatAmount(selectedPositionBalance, 6, true, '-')}
              </BalanceValue>
              {changedPositionBalanceValue && (
                <NewValueWrapper
                  isNegative={
                    manageType === ManageType.LOAN
                      ? selectedTab === LoanActionType.REPAY && isZapActivated
                        ? !changedPositionBalanceValue.asFraction.greaterThan(
                            selectedPositionBalance?.asFraction ?? ZERO_FRACTION,
                          )
                        : changedPositionBalanceValue.asFraction.greaterThan(
                            selectedPositionBalance?.asFraction ?? ZERO_FRACTION,
                          )
                      : changedPositionBalanceValue.asFraction.lessThan(
                          selectedPositionBalance?.asFraction ?? ZERO_FRACTION,
                        )
                  }
                >
                  <ArrowRight />
                  <NewValue error={changedPositionBalanceValue.lessThan(ZERO_FRACTION)}>
                    {formatAmount(changedPositionBalanceValue, 6, true)}
                  </NewValue>
                </NewValueWrapper>
              )}
              <TokenSymbol>{cleanCurrencySymbol(selectedToken)}</TokenSymbol>
            </BalanceRow>
            <BalanceRow>
              <StyledTooltip
                title={`Your ${cleanCurrencySymbol(selectedToken) + ' ' ||
                  ''}balance that you currently have available on Dolomite.`}
                placement={'top'}
              >
                <BalanceTitle>{t('dolomiteBalanceText')}</BalanceTitle>
              </StyledTooltip>
              <BalanceValue>{formatAmount(selectedWalletBalance, 6, true, '-')}</BalanceValue>
              {changedWalletBalanceValue && !(selectedTab === CollateralActionType.SWAP) && (
                <NewValueWrapper
                  isNegative={changedWalletBalanceValue.lessThan(selectedWalletBalance?.asFraction ?? ZERO_FRACTION)}
                >
                  <ArrowRight />
                  <NewValue error={changedWalletBalanceValue.lessThan(ZERO_FRACTION)}>
                    {formatAmount(changedWalletBalanceValue, 6, true)}
                  </NewValue>
                </NewValueWrapper>
              )}
              <TokenSymbol>{cleanCurrencySymbol(selectedToken)}</TokenSymbol>
            </BalanceRow>
            <MaxButton
              visible={!(manageType === ManageType.LOAN && selectedTab === LoanActionType.BORROW)}
              onClick={() => {
                setMax()
              }}
            >
              {t('max')}
            </MaxButton>
          </TopRow>
          <InputWrapper>
            <InputOverflowFix>
              <TempToken expanded={expandedInputArea}>
                {cleanCurrencySymbol(selectedToken) ?? '-'}
                <ArrowDown flipped={tokenSelectOpen} />
              </TempToken>
              <StyledInput
                onChange={updateInput}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'0.00'}
                value={
                  /*selectedTab === CollateralActionType.SWAP && manageType === ManageType.LOAN
                    ? zapInputValue
                    :*/ inputValue
                }
                variant='amountInput'
                disableUnderline={true}
                endAdornment={''}
                disabled={
                  /*(selectedTab === CollateralActionType.SWAP && manageType === ManageType.LOAN) ||*/
                  !selectedToken || isTxPending || isAttemptingTx
                }
              />
            </InputOverflowFix>
            <TokenSelector
              disabled={!selectedToken || isTxPending || isAttemptingTx}
              onClick={() => !tokenSelectOpen && selectedToken && setTokenSelectOpen(true)}
              ref={wrapperRef}
              visible={expandedInputArea}
            >
              <TokenSelectRow>{cleanCurrencySymbol(selectedToken) ?? '-'}</TokenSelectRow>
              <ArrowDown flipped={false} />
            </TokenSelector>
          </InputWrapper>
          <BorrowSubmitButton
            manageType={manageType}
            selectedTab={selectedTab}
            isClosingTransaction={isClosingTransaction}
            dolomiteBalanceData={dolomiteBalanceData}
            isIsolationModeCompatibilityValid={isIsolationModeCompatibilityValid}
            isGmCompatibilityValid={/*!gmFeeError && */ !minGmTradeError}
            inputValue={inputValue}
            isInputValueValid={isInputValueValid}
            isBorrowStateValid={isBorrowStateValid}
            inputError={inputError}
            isAttemptingTx={isAttemptingTx}
            isTxPending={isTxPending}
            isZapActivated={isZapActivated}
            handleSubmit={handleSubmit}
            tooltipText={tooltipText ?? ''}
          />
          <AdvancedToggle>
            <Checkbox
              onClick={() => {
                setInputValue('')
                setZapInputValue('')
                setIsZapActivated(swapSelected ? true : !isZapActivated)
              }}
              disabled={isInvalidChain}
            >
              <Checked isChecked={isZapActivated} />
            </Checkbox>
            <ZapWrapper>
              <BoltIcon /> {chainId === ChainId.BERACHAIN ? 'BAP' : 'ZAP'}
            </ZapWrapper>
            -{' '}
            {isInvalidChain
              ? `Zap coming soon to ${NETWORK_LABELS[chainId]}`
              : manageType === ManageType.COLLATERAL
              ? selectedTab === CollateralActionType.DEPOSIT
                ? t('tradeAndAdd')
                : selectedTab === CollateralActionType.WITHDRAW
                ? t('removeAndTrade')
                : t('swapCollateral')
              : selectedTab === LoanActionType.BORROW
              ? t('tradeAndDeposit')
              : selectedTab === LoanActionType.REPAY
              ? t('repayUsingCollateral')
              : t('swapBorrowed')}
            <TooltipResizer>
              <StyledTooltipWithIcon
                tooltipText={
                  isInvalidChain
                    ? `Zap is not currently available on ${NETWORK_LABELS[chainId]}, but will be coming very soon!`
                    : manageType === ManageType.COLLATERAL
                    ? selectedTab === CollateralActionType.DEPOSIT
                      ? t('tradeAndAddTooltip')
                      : selectedTab === CollateralActionType.WITHDRAW
                      ? t('removeAndTradeTooltip')
                      : t('swapCollateralTooltip')
                    : selectedTab === LoanActionType.BORROW
                    ? t('tradeAndDepositTooltip')
                    : selectedTab === LoanActionType.REPAY
                    ? t('repayUsingCollateralTooltip')
                    : t('swapLoanTooltip')
                }
              />
            </TooltipResizer>
          </AdvancedToggle>
          <AdvancedSection
            expanded={isZapActivated}
            displayingTokenSelection={zapTokenSelectOpen}
            showTradeDetails={!!bestZap || isLoadingZapTradeData}
          >
            <TopRow>
              <BalanceRow>
                <StyledTooltip
                  title={`The amount of ${cleanCurrencySymbol(selectedZapToken) + ' ' || 'assets'} ${
                    manageType === ManageType.COLLATERAL
                      ? 'you currently have borrowed in this borrow position'
                      : selectedTab === LoanActionType.REPAY && isZapActivated
                      ? 'you currently have borrowed in this borrow position'
                      : 'currently in this borrow position as collateral'
                  }.`}
                  placement={'top'}
                >
                  <BalanceTitle>
                    {(manageType === ManageType.LOAN && selectedTab === LoanActionType.REPAY && isZapActivated) ||
                    (manageType === ManageType.LOAN && selectedTab === LoanActionType.SWAP)
                      ? t('positionDebt')
                      : t('positionCollateral')}
                  </BalanceTitle>
                </StyledTooltip>
                <BalanceValue>{formatAmount(selectedPositionZapBalance, 6, true, '-')}</BalanceValue>
                {changedPositionAdvancedBalanceValue && (
                  <NewValueWrapper
                    isNegative={
                      manageType === ManageType.LOAN
                        ? (selectedTab === LoanActionType.REPAY && isZapActivated) ||
                          selectedTab === LoanActionType.SWAP
                          ? !changedPositionAdvancedBalanceValue.asFraction.lessThan(
                              selectedPositionZapBalance?.asFraction ?? ZERO_FRACTION,
                            )
                          : changedPositionAdvancedBalanceValue.asFraction.lessThan(
                              selectedPositionZapBalance?.asFraction ?? ZERO_FRACTION,
                            )
                        : changedPositionAdvancedBalanceValue.asFraction.lessThan(
                            selectedPositionZapBalance?.asFraction ?? ZERO_FRACTION,
                          )
                    }
                  >
                    <ArrowRight />
                    <NewValue
                      error={
                        changedPositionAdvancedBalanceValue.lessThan(ZERO_FRACTION) &&
                        !(
                          manageType === ManageType.LOAN &&
                          (selectedTab === LoanActionType.REPAY || selectedTab === LoanActionType.SWAP) &&
                          isZapActivated
                        )
                      }
                      isNegative={
                        manageType === ManageType.LOAN &&
                        (selectedTab === LoanActionType.REPAY || selectedTab === LoanActionType.SWAP) &&
                        isZapActivated &&
                        changedPositionAdvancedBalanceValue.lessThan(ZERO_FRACTION)
                          ? false
                          : undefined
                      }
                    >
                      {manageType === ManageType.LOAN &&
                      (selectedTab === LoanActionType.REPAY || selectedTab === LoanActionType.SWAP) &&
                      isZapActivated &&
                      changedPositionAdvancedBalanceValue.lessThan(ZERO_FRACTION)
                        ? formatAmount(changedPositionAdvancedBalanceValue, 6, true).replace('-', '+')
                        : formatAmount(changedPositionAdvancedBalanceValue, 6, true)}
                    </NewValue>
                  </NewValueWrapper>
                )}
                <TokenSymbol>{cleanCurrencySymbol(selectedZapToken)}</TokenSymbol>
              </BalanceRow>
              <BalanceRow>
                <StyledTooltip
                  title={`Your ${cleanCurrencySymbol(selectedZapToken) + ' ' ||
                    ''}balance that you currently have available on Dolomite.`}
                  placement={'top'}
                >
                  <BalanceTitle>{t('dolomiteBalanceText')}</BalanceTitle>
                </StyledTooltip>
                <BalanceValue>
                  {selectedZapWalletBalance ? formatAmount(selectedZapWalletBalance, 6, true) : '-'}
                </BalanceValue>
                {changedAdvancedWalletBalanceValue && !(selectedTab === CollateralActionType.SWAP) && (
                  <NewValueWrapper
                    isNegative={changedAdvancedWalletBalanceValue.lessThan(
                      selectedZapWalletBalance?.asFraction ?? ZERO_FRACTION,
                    )}
                  >
                    <ArrowRight />
                    <NewValue error={changedAdvancedWalletBalanceValue.lessThan(ZERO_FRACTION)}>
                      {formatAmount(changedAdvancedWalletBalanceValue, 6, true)}
                    </NewValue>
                  </NewValueWrapper>
                )}
                <TokenSymbol>{cleanCurrencySymbol(selectedZapToken)}</TokenSymbol>
              </BalanceRow>
            </TopRow>
            <InputWrapper>
              <InputOverflowFix>
                <TempToken expanded={expandedInputArea}>
                  {cleanCurrencySymbol(selectedToken) ?? '-'}
                  <ArrowDown flipped={tokenSelectOpen} />
                </TempToken>
                <StyledInput
                  onChange={updateInput /*updateAdvancedInput*/}
                  multiline={false}
                  fullWidth
                  spellCheck={false}
                  placeholder={'0.00'}
                  value={
                    /*selectedTab === CollateralActionType.SWAP && manageType === ManageType.LOAN
                      ? inputValue
                      :*/ zapInputValue
                  }
                  variant={'amountInput'}
                  disableUnderline={true}
                  endAdornment={''}
                  disabled={/*!(selectedTab === CollateralActionType.SWAP && manageType === ManageType.LOAN)*/ true}
                />
              </InputOverflowFix>
              <TokenSelector
                disabled={!selectedZapToken || isTxPending || isAttemptingTx}
                onClick={() => !zapTokenSelectOpen && selectedZapToken && setZapTokenSelectOpen(true)}
                ref={wrapperRef}
                visible={expandedInputArea}
              >
                <TokenSelectRow>{cleanCurrencySymbol(selectedZapToken) ?? '-'}</TokenSelectRow>
                <ArrowDown flipped={false} />
              </TokenSelector>
            </InputWrapper>
            <AdvancedDescription
              manageType={manageType}
              selectedTab={selectedTab}
              asset1={cleanCurrencySymbol(selectedToken)}
              amount1={parsedInputValue ? formatAmount(parsedInputValue, 6, true) : undefined}
              asset2={cleanCurrencySymbol(selectedZapToken)}
              amount2={parsedZapInputValue ? formatAmount(parsedZapInputValue, 6, true) : undefined}
            />
            <ErrorMessage isVisible={displayErrorMessage || !!slippageError} tall={!!slippageError}>
              <ErrorText>{errorMessage ?? ' '}</ErrorText>
              <CopyError onClick={() => copyErrorToClipboard()}>
                Copy to clipboard{hasCopiedError && <Checkmark />}
              </CopyError>
            </ErrorMessage>
            {isZapActivated && selectedSpecialZapAsset?.isIsolationMode && selectedSpecialAsset?.isIsolationMode ? (
              <IsoToIsoError open={false} showError={displayErrorMessage}>
                <IsoToIsoErrorInner>
                  <WarningRoundedIcon />
                  <IsoToIsoErrorText>
                    Cannot zap from one isolation mode asset to another isolation mode asset.
                  </IsoToIsoErrorText>
                </IsoToIsoErrorInner>
              </IsoToIsoError>
            ) : (
              (bestZap || isLoadingZapTradeData) && (
                <BorrowTradeDetails
                  bestZap={bestZap}
                  priceImpact={bestZap?.priceImpact}
                  isLoading={isLoadingZapTradeData}
                  refreshZapData={refreshZapData}
                  showError={displayErrorMessage || !!slippageError}
                />
              )
            )}
          </AdvancedSection>
        </InputOuter>
      </BorrowExpandedContentWrapper>
    </ExpandedSection>
  )
}

export default React.memo(BorrowExpandedContent, BorrowExpandedContentComparator)
