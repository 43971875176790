import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import getLogoOrDefault from '../../../components/common/TokenLogos'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'
import { useAllActiveTokensArray } from '../../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { useFiatValuesWithLoadingIndicator } from '../../../hooks/useFiatValue'
import { ZERO_FRACTION } from '../../../constants'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import cleanCurrencyName from '../../../utils/cleanCurrencyName'
import { TableLoader } from '../../../components/Loader'
import { Fraction } from '@dolomite-exchange/sdk-core'
import Column from '../../../components/Orders/Column'
import { useUserHideZeroBalances } from '../../../state/user/hooks'
import { formatAmount } from '../../../utils/formatAmount'
import BoltIcon from '@mui/icons-material/Bolt'
import { lighten } from 'polished'
import { useModalOpen, useToggleModal } from '../../../state/application/hooks'
import { ApplicationModal } from '../../../state/application/actions'
import ZapModal from '../../Borrow/ZapModal'
import DepositWithdrawModal from '../../../components/DepositWithdrawModal'
import invariant from 'tiny-invariant'

const DepositedBalancesWrapper = styled.div<{ isBorrow?: boolean }>`
  background-color: #292938;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 15px;
  padding: 25px 35px 25px;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  h3 {
    margin: 0 !important;
  }

  ${({ isBorrow }) =>
    isBorrow &&
    `
      @media screen and (max-width: 919px) {
        width: 315px;
        display: inline-block;
        margin-right: 20px;
      }
      @media screen and (max-width: 1100px) {
        width: calc(50% - 10px);
        margin: 0 20px 0 0;
      }
  `};

  @media screen and (max-width: 680px) {
    width: 100%;
  }

  @media screen and (max-width: 580px) {
    margin: ${({ isBorrow }) => (isBorrow ? '0 0 15px' : '0 15px 20px')};
    width: calc(100% - 30px);
  }
`

const HeaderWrapper = styled.div`
  margin-bottom: 15px;
`

const HeaderInner = styled.div`
  margin-top: 0;
  text-align: left;
  display: inline-block;
`

const BalanceTooltip = styled.span`
  margin-left: 3px;
  height: 15px;
  margin-top: 15px;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props}>
    {props.children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 13px !important;
    background-color: #3d3e54 !important;
  }
`

const StyledInfoIcon = styled(HelpOutlinedIcon)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #606375 !important;
  cursor: pointer !important;
  margin-bottom: -2px !important;
`

const ColumnBase = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
`

const TickerColumn = styled(ColumnBase)`
  text-align: left;
  width: 70%;
  margin-right: -20%;
  cursor: default;
`

const BalanceColumn = styled(ColumnBase)`
  text-align: right;
  width: 50%;
  cursor: default;
`

const BalanceRowWrapper = styled.div`
  margin: 15px 0;
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 7px;
  margin-top: 5px;
  vertical-align: top;
  width: 20px;

  img {
    width: 100%;
  }
`

const TickerWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 30px);
`

const Ticker = styled.div`
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 580px) {
    font-size: 17px;
  }
`

const Name = styled.div`
  color: #d5d6e1;
  font-size: 12px;
  font-weight: 100;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const Balance = styled.div`
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 580px) {
    font-size: 17px;
  }
`

const DollarBalance = styled.div`
  font-weight: 100;
  font-size: 12px;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const LoadingWrapper = styled.div`
  color: ${({ theme }) => theme.text3};
`

const LoadingBalancesText = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 15px;
  margin-bottom: 18px;
`

const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: -5px;
  color: ${({ theme }) => theme.text3};

  > div {
    cursor: pointer;
  }
`

const ZeroBalances = styled.div<{ visible: boolean }>`
  text-align: left;
  font-size: 10px;
  color: ${({ theme }) => theme.text2};
  margin-top: 6px;
  margin-bottom: 2px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const BalancesWrapper = styled.div`
  max-height: 540px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: ${({ theme }) => `${theme.bg4} ${theme.bg2}`};
  scrollbar-width: thin;

  @media screen and (max-width: 580px) {
    overflow: visible;
    max-height: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 2px;
    background: ${({ theme }) => theme.bg1};
    left: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bg1};
    width: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg1};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bg4};
    width: 4px;
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg4};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

const WrapperOffset = styled.div`
  margin-top: -15px;
`

const BalanceWrapper = styled.div`
  width: 100%;
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 10px;
  width: 10px;
  border: 1px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 3px;
  margin-top: 1px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 4px;
  width: 4px;
  background: ${({ isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 2px;
  margin-top: 2px;
`

const TopButtons = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const Button = styled.div<{ small?: boolean; right?: boolean }>`
  vertical-align: top;
  margin-top: 10px;
  margin-right: ${({ right }) => (right ? '0' : '10px')};
  width: ${({ small }) => (small ? 'calc(50% - 5px)' : '100%')};
  display: inline-block;
  text-align: center;
  padding: 9px 12px;
  height: 39.5px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;

  svg {
    color: ${({ theme }) => theme.blue1};
    margin-right: -5px;
    margin-left: -5px;
  }

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  @media screen and (max-width: 660px) {
    box-shadow: none;
  }
`

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: top;
`

interface BalanceRowProps {
  token: Token
  tokenBalance?: CurrencyAmount<Token>
  fiatBalance?: Fraction
}

const BalanceRowComparator = (prevProps: BalanceRowProps, nextProps: BalanceRowProps) => {
  return (
    prevProps.token.equals(nextProps.token) &&
    prevProps.tokenBalance === nextProps.tokenBalance &&
    prevProps.fiatBalance === nextProps.fiatBalance
  )
}

const BalanceRow = React.memo<BalanceRowProps>(({ token, tokenBalance, fiatBalance }: BalanceRowProps) => {
  const symbol = cleanCurrencySymbol(token) ?? ''
  return (
    <BalanceRowWrapper>
      <TickerColumn>
        <div>
          <TokenLogo>
            <img src={getLogoOrDefault(symbol)} alt={`${symbol} logo`} />
          </TokenLogo>
          <TickerWrapper>
            <Ticker>{symbol}</Ticker>
            <Name>{cleanCurrencyName(token)}</Name>
          </TickerWrapper>
        </div>
      </TickerColumn>
      <StyledTooltip title={`${tokenBalance?.toFixed()} ${symbol}`} placement={'right'} style={{ cursor: 'pointer' }}>
        <BalanceColumn>
          <div>
            <Balance>{formatAmount(tokenBalance, 4, true, '0.0000')}</Balance>
            <DollarBalance>{formatAmount(fiatBalance, 2, true, '$0.00', true)}</DollarBalance>
          </div>
        </BalanceColumn>
      </StyledTooltip>
    </BalanceRowWrapper>
  )
}, BalanceRowComparator)
BalanceRow.displayName = 'BalanceRow'

const widths = {
  widths: [35, 25],
  starts: [0, 75],
}

function nameComparator(a: Token, b: Token, sortDirection: boolean) {
  const sorter = sortDirection ? 1 : -1
  const aName = cleanCurrencyName(a)?.toUpperCase() ?? ''
  const bName = cleanCurrencyName(b)?.toUpperCase() ?? ''
  const aSymbol = cleanCurrencySymbol(a)?.toUpperCase() ?? ''
  const bSymbol = cleanCurrencySymbol(b)?.toUpperCase() ?? ''
  if (aSymbol === bSymbol) {
    return (aName < bName ? 1 : -1) * sorter
  } else {
    return (aSymbol < bSymbol ? 1 : -1) * sorter
  }
}

enum SortField {
  DolomiteBalance = 'DolomiteBalance',
  Name = 'Name',
}

export default function DepositedBalances({ isBorrow }: { isBorrow?: boolean }) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const tokens = useAllActiveTokensArray()
  const node = useRef<HTMLDivElement>()
  const isSwapModalOpen = useModalOpen(ApplicationModal.SWAP)
  const toggleShowSwapModal = useToggleModal(ApplicationModal.SWAP)
  const isDepositModalOpen = useModalOpen(ApplicationModal.DEPOSIT_WITHDRAW)
  const toggleShowDepositModal = useToggleModal(ApplicationModal.DEPOSIT_WITHDRAW)
  const [isWithdrawModal, setWithdrawModal] = useState<boolean>(false)
  const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const [fiatBalanceData] = useFiatValuesWithLoadingIndicator(dolomiteBalanceData, tokens)
  const [sortField, setSortField] = useState<SortField>(SortField.DolomiteBalance)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [hideZeroBalances, setHideZeroBalances] = useUserHideZeroBalances()
  const sortedTokens = useMemo(() => {
    return Array.of(...tokens).sort((a: Token, b: Token) => {
      const aFiat = fiatBalanceData[a.address] ?? ZERO_FRACTION
      const bFiat = fiatBalanceData[b.address] ?? ZERO_FRACTION
      const sort = sortDirection ? 1 : -1
      if (sortField === SortField.Name) {
        return nameComparator(a, b, sortDirection)
      } else {
        invariant(sortField === SortField.DolomiteBalance, `Invalid sort field, found ${sortField}`)
        if (aFiat.equalTo(bFiat)) {
          return nameComparator(a, b, false)
        } else {
          return (aFiat.lessThan(bFiat) ? 1 : -1) * sort
        }
      }
    })
  }, [tokens, fiatBalanceData, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: SortField) => {
      setSortField(newField)
      setSortDirection(oldSortDirection => {
        if (sortField !== newField) {
          // reset it. For balance fields, we want to default to descending
          return newField === SortField.DolomiteBalance
        }
        // Flip it
        return !oldSortDirection
      })
    },
    [sortField],
  )

  const arrow = useCallback(
    (field: SortField) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const depositModal = useCallback(() => {
    setWithdrawModal(false)
    toggleShowDepositModal()
  }, [toggleShowDepositModal])
  const withdrawModal = useCallback(() => {
    setWithdrawModal(true)
    toggleShowDepositModal()
  }, [toggleShowDepositModal])

  /* TODO - split color in top half vs bottom half */
  return (
    <DepositedBalancesWrapper isBorrow={isBorrow} ref={node as any}>
      <ZapModal open={isSwapModalOpen} closeZap={toggleShowSwapModal} />
      <DepositWithdrawModal open={isDepositModalOpen} close={toggleShowDepositModal} isWithdraw={isWithdrawModal} />
      <HeaderWrapper>
        <HeaderInner>
          <h3>{t('dolomiteBalances')}</h3>
        </HeaderInner>
        <BalanceTooltip>
          <StyledTooltip title={t('balancesDeposited')} placement='right'>
            <StyledInfoIcon />
          </StyledTooltip>
        </BalanceTooltip>
        {account && (
          <TopButtons>
            <Button onClick={toggleShowSwapModal}>
              <ButtonText>Swap</ButtonText> <BoltIcon />
            </Button>
            <Button onClick={depositModal} small>
              <ButtonText>Deposit</ButtonText>
            </Button>
            <Button onClick={withdrawModal} small right>
              <ButtonText>Withdraw</ButtonText>
            </Button>
          </TopButtons>
        )}
        <ZeroBalances visible={!!account} onClick={() => setHideZeroBalances(!hideZeroBalances)}>
          <Checkbox>
            <Checked isChecked={hideZeroBalances} />
          </Checkbox>
          Hide Zero Balances
        </ZeroBalances>
        <ColumnTitles>
          <Column width={50} textAlign={'left'} onClick={() => handleSort(SortField.Name)}>
            {t('token')} {arrow(SortField.Name)}
          </Column>
          <Column width={50} textAlign={'right'} onClick={() => handleSort(SortField.DolomiteBalance)}>
            {t('balanceText')} {arrow(SortField.DolomiteBalance)}
          </Column>
        </ColumnTitles>
      </HeaderWrapper>
      {account ? (
        Object.keys(fiatBalanceData).length === 0 || Object.keys(dolomiteBalanceData).length === 0 ? (
          <TableLoader rows={5} height={35} spacing={55} wrapperHeight={272} marginTop={5} widths={widths} />
        ) : (
          <BalancesWrapper>
            <WrapperOffset>
              {Object.keys(dolomiteBalanceData).length !== 0 ? (
                sortedTokens.map((token: Token) => {
                  const tokenBalance = dolomiteBalanceData[token.address]
                  const fiatBalance = fiatBalanceData[token.address]
                  if (hideZeroBalances && !tokenBalance?.greaterThan(ZERO_FRACTION)) {
                    return null
                  }
                  return (
                    <BalanceWrapper key={`balance-${token.chainId}-${token.address}`}>
                      <BalanceRow token={token} tokenBalance={tokenBalance} fiatBalance={fiatBalance} />
                    </BalanceWrapper>
                  )
                })
              ) : (
                <LoadingBalancesText>{t('loadingBalances')}</LoadingBalancesText>
              )}
            </WrapperOffset>
          </BalancesWrapper>
        )
      ) : (
        <LoadingWrapper>{t('connectYourWallet')}</LoadingWrapper>
      )}
    </DepositedBalancesWrapper>
  )
}
