import { useMemo } from 'react'
import JSBI from 'jsbi'
import { gql } from '@apollo/client'
import { TokenInfo, TokenList } from '@uniswap/token-lists'
import getLogoOrDefault from '../components/common/TokenLogos'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import useKeyByMapWithChainId from '../hooks/useKeyByMapWithChainId'
import { ChainId } from '../constants'
import { getMockedSpecialAssets, NAME_OVERRIDE_MAP, SYMBOL_OVERRIDE_MAP } from '../constants/isolation/special-assets'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

interface DolomiteMarginTokenGql {
  id: string
  chainId: number
  marketId: string
  symbol: string
  name: string
  decimals: string
}

interface DolomiteMarginToken {
  address: string
  marketId: JSBI
  symbol: string
  name: string
  decimals: number
  chainId: number
}

interface TokensResponse {
  tokens: DolomiteMarginTokenGql[]
}

export function useDolomiteMarginTokenDataAsTokenList(): TokenList {
  const { data: dolomiteTokenData } = useDolomiteMarginTokenData()
  const key = useKeyByMapWithChainId(dolomiteTokenData)
  return useMemo<TokenList>(() => {
    const tokens = Object.values(dolomiteTokenData).reduce<TokenInfo[]>((memo, token) => {
      memo.push({
        chainId: token.chainId,
        address: token.address,
        name: token.name,
        symbol: token.symbol,
        decimals: token.decimals,
        logoURI: getLogoOrDefault(token.symbol),
        tags: undefined,
      })
      return memo
    }, [])

    if (tokens.length > 0 && process.env.NODE_ENV !== 'production') {
      const chainId = tokens[0].chainId
      const specialAsset = getMockedSpecialAssets()
      specialAsset.forEach(specialAsset => {
        const wrappedToken = specialAsset.isolationModeInfo?.getWrappedToken(chainId)
        if (wrappedToken && !dolomiteTokenData[wrappedToken.address]) {
          tokens.push({
            chainId: wrappedToken.chainId,
            address: wrappedToken.address,
            name: wrappedToken.name ?? '',
            symbol: wrappedToken.symbol ?? '',
            decimals: wrappedToken.decimals,
            logoURI: getLogoOrDefault(wrappedToken.symbol ?? ''),
            tags: undefined,
          })
        }
      })
    }

    return {
      name: 'Dolomite',
      timestamp: '2022-10-12T00:00:00.000Z',
      version: {
        major: 1,
        minor: 0,
        patch: 0,
      },
      tokens,
    }
    // eslint-disable-next-line
  }, [key])
}

const TOKENS_GQL = gql`
  query allTokens($blockNumber: Int!) {
    tokens(first: 1000, orderBy: symbol, orderDirection: asc, block: { number_gte: $blockNumber }) {
      id
      chainId
      marketId
      symbol
      name
      decimals
    }
  }
`

export function useDolomiteMarginTokenData(): {
  loading: boolean
  error: boolean
  data: Record<string, DolomiteMarginToken>
} {
  const queryState = useGraphqlResult<TokensResponse>(
    GraphqlClientType.Dolomite,
    TOKENS_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const tokenGqls = result?.tokens ?? []
    const tokenMap = tokenGqls.reduce<Record<string, DolomiteMarginToken>>((memo, tokenGql) => {
      const tokenAddress = toChecksumAddress(tokenGql.id)

      memo[tokenAddress] = {
        chainId: tokenGql.chainId,
        address: tokenAddress,
        marketId: JSBI.BigInt(tokenGql.marketId),
        symbol: getSymbolOverride(tokenGql.chainId, tokenAddress, tokenGql.symbol),
        name: getNameOverride(tokenGql.chainId, tokenAddress, tokenGql.name),
        decimals: getDecimalOverride(tokenGql.chainId, tokenAddress, Number(tokenGql.decimals)),
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: tokenMap,
    }
  }, [queryState])
}

function getSymbolOverride(chainId: ChainId, address: string, originalSymbol: string): string {
  return SYMBOL_OVERRIDE_MAP[chainId][address] ?? originalSymbol
}

function getNameOverride(chainId: ChainId, address: string, originalName: string): string {
  return NAME_OVERRIDE_MAP[chainId][address] ?? originalName
}

function getDecimalOverride(chainId: ChainId, address: string, originalDecimals: number): number {
  return originalDecimals
}
