import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import burn from './burn/reducer'
import chain from './chain/reducer'
import graphql from './graphql/reducer'
import swap from './trade/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'
import multicall from './multicall/reducer'

const PERSISTED_KEYS: string[] = ['transactions', 'user']

const store = configureStore({
  reducer: {
    application,
    burn,
    chain,
    graphql,
    multicall,
    swap,
    transactions,
    user,
  },
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
