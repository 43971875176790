import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import Modal from '../../components/Modal'
import { BorrowPosition, BorrowPositionAmount } from '../../types/borrowPositionData'
import { toPng } from 'html-to-image'
import getLogoOrDefault from '../../components/common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import DolomiteNameAndLogo from '../../assets/images/dolomite-name-and-logo-white.svg'
import { Fraction } from '@dolomite-exchange/v2-sdk'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Tilt from 'react-parallax-tilt'
import { formatAmount } from '../../utils/formatAmount'
import Triangles from '../../assets/svg/triangles-bg.svg'
import useNetInterestRate from '../../hooks/useNetInterestRate'
import { ChainId, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import calculateAprToApy from '../../utils/calculateAprToApy'
import { copyBlobToClipboard, getBlobFromImageSource } from 'copy-image-clipboard'
import { X } from 'react-feather'
import CircularProgress from '@material-ui/core/CircularProgress'
import ShareBackgroundMountain from '../../assets/images/share-background-mountain.png'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import DolomiteLogo from '../../assets/dolomite-logo-white.svg'
import { useTranslation } from 'react-i18next'
import { useCalculatePositionMinerals, useTokensWithMineralRewards } from '../../hooks/useLiquidityMining'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { NETWORK_LABELS } from '../../constants/chainId'
import { useActiveWeb3React } from '../../hooks'
import BoboScreaming from '../../assets/images/bobo-crying-internal-scream.png'
import BobosPointing from '../../assets/images/bobos-pointing.png'

enum Backgrounds {
  FADE,
  GREEN,
  TRIANGLES,
  PLAIN,
  MOUNTAIN,
  BLACK,
}

const SharePositionWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0 25px;
`

const ScreensWrapper = styled.div<{ isEditing: boolean }>`
  width: calc(200%);
  display: block;
  transform: ${({ isEditing }) => (isEditing ? 'translateX(0)' : 'translateX(-50%)')};
  transition: transform 0.3s ease-in-out;
`

const EditScreen = styled.div`
  width: calc(50%);
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
`

const ShareScreen = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
`

const Preview = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.bg6};
  margin-left: -25px;
  width: calc(100% + 50px);
  padding: 20px 0;

  @media screen and (max-width: 400px) {
    padding: 10px 0;
  }

  @media screen and (max-width: 365px) {
    padding: 5px 0;
  }
`

const PreviewWrapper = styled.div`
  width: 400px;
  /*margin: 20px auto 10px;*/
  min-height: 200px;
  background: #292938;
  /*background: rgb(30, 28, 41);
    background: linear-gradient(45deg, rgba(30, 28, 41, 1) 0%, rgba(63, 64, 86, 1) 100%);
    background: rgb(41, 41, 56);
    background: linear-gradient(0deg, rgba(143, 201, 66, 1) 0%, rgba(41, 41, 56, 0) 100%);*/
  padding: 10px 15px;
  position: relative;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  overflow: hidden;

  @media screen and (max-width: 450px) {
    transform: scale(0.9);
  }

  @media screen and (max-width: 400px) {
    transform: scale(0.8);
  }

  @media screen and (max-width: 365px) {
    transform: scale(0.7);
  }
`

const OptionsWrapper = styled.div`
  width: 52%;
  margin-right: 3%;
  margin-top: 10px;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0;
  }
`

const OptionsTitle = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 10px;
`

const MultiToggleBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg6};
`

const ToggleOption = styled.div<{ selected?: boolean }>`
  text-align: center;
  padding: 6px 0;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme, selected }) => (selected ? theme.bg5 : theme.bg6)};
  transition: background 0.1s ease-in-out;
  user-select: none;
  flex-grow: 1;

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg5 : theme.bg7)};
  }
`

const ExplainerArrowsWrapper = styled.div`
  width: 100%;
  height: 15px;
  position: relative;
  margin-top: -8px;
  margin-bottom: -10px;
`

const ExplainerArrowLeft = styled.div`
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    left: 50px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #3a3a4f; /*${({ theme }) => theme.bg6};*/
`

const ExplainerArrowRight = styled.div`
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    left: 165px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #3a3a4f; /*${({ theme }) => theme.bg6};*/
`

const ExplainerText = styled.div`
    width: 100%;
    font-size: 11px;
    line-height: 13px;
    color: ${({ theme }) => theme.text2};
    margin-top: 5px;
    margin-bottom: 10px;
    background: #3a3a4f; /*${({ theme }) => theme.bg6};*/
    padding: 10px 12px;
    border-radius: 5px;
    margin-left: 0;
`

const Option = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

const OptionText = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
`

const ExtraOption = styled.div`
  width: fit-content;
  margin-left: 10px;
  color: ${({ theme }) => theme.text2};
  position: relative;
  font-size: 13px;
`

const TokenSelectOuter = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 115px;
  position: relative;
  height: 33px;
  margin-top: -7px;
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

const TokenSelector = styled.div<{ expanded: boolean; isLoading?: boolean; disabled: boolean }>`
  background-color: #3a3a4f;
  border-radius: 4px;
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  height: 33px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 110px;
  ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none; cursor: default;`}
  z-index: 1;

  ${({ isLoading }) =>
    isLoading &&
    `
    > div {
      opacity: 0.5;
      
      :hover {
        background: none !important;
      }
    }
  `}

  ${({ expanded }) =>
    expanded &&
    `
    border-bottom-left-radius: 4px;
    height: fit-content;
  `}
`

const TokenSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 10px;
  height: 33px;

  &:hover {
    background-color: #474956;
  }
`

const BackgroundsWrapper = styled.div`
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const BackgroundsTitle = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 10px;
`

const BackgroundSelector = styled.div`
  width: 100%;
  height: 204px;
  border-radius: 4px;
  /*box-shadow: 0 5px 5px -6px rgb(0 0 0 / 20%), 0px 8px 10px -3px rgb(0 0 0 / 14%), 0px 3px 14px -2px rgb(0 0 0 / 12%);*/
`

const BackgroundOption = styled.div<{ selected: boolean }>`
  width: calc(50% - 8px);
  height: calc(33.33% - 8px);
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  margin: 4px;
  ${({ selected }) => (selected ? 'border: 2px solid #4987d7' : 'none')};
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  :hover {
    opacity: 1;
  }
`

const BackgroundOptionFade = styled(BackgroundOption)`
  background: linear-gradient(45deg, rgba(30, 28, 41, 1) 0%, rgba(63, 64, 86, 1) 100%);
`

const BackgroundOptionGreen = styled(BackgroundOption)<{ isPositive: boolean }>`
  background: ${({ isPositive }) =>
    isPositive
      ? 'linear-gradient(0deg, rgba(143, 201, 66, 1) 0%, rgba(41, 41, 56, 0) 100%)'
      : 'linear-gradient(0deg, rgba(252, 59, 38, 1) 0%, rgba(252, 59, 38, 0) 100%)'};
`

const BackgroundOptionTriangles = styled(BackgroundOption)`
  img {
    width: 100%;
    height: 100%;
  }
`

const BackgroundOptionPlain = styled(BackgroundOption)`
  background: #292938;
`

const BackgroundOptionMountain = styled(BackgroundOption)`
  background: url(${ShareBackgroundMountain});
  background-size: cover;
`

const BackgroundOptionBlack = styled(BackgroundOption)`
  background: #000000;
  overflow: hidden;

  img {
    height: 180% !important;
    margin-top: -10%;
    margin-left: -10%;
    opacity: 0.3;
  }
`

const BackgroundPanel = styled.div<{ selectedBackground: Backgrounds; isPositive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  background: ${({ selectedBackground, isPositive }) =>
    selectedBackground === Backgrounds.FADE
      ? 'linear-gradient(45deg, rgba(30, 28, 41, 1) 0%, rgba(63, 64, 86, 1) 100%)'
      : selectedBackground === Backgrounds.GREEN
      ? isPositive
        ? 'linear-gradient(0deg, rgba(143, 201, 66, 1) 0%, rgba(41, 41, 56, 0) 100%)'
        : 'linear-gradient(0deg, rgba(252, 59, 38, 1) 0%, rgba(252, 59, 38, 0) 100%)'
      : selectedBackground === Backgrounds.MOUNTAIN
      ? `url(${ShareBackgroundMountain})`
      : selectedBackground === Backgrounds.PLAIN
      ? '#292938'
      : selectedBackground === Backgrounds.TRIANGLES
      ? '#292938'
      : selectedBackground === Backgrounds.BLACK
      ? '#000000'
      : '#292938'};
  background-size: cover;
  /*background: linear-gradient(45deg, rgba(30, 28, 41, 1) 0%, rgba(63, 64, 86, 1) 100%);
    background: linear-gradient(0deg, rgba(143, 201, 66, 1) 0%, rgba(41, 41, 56, 0) 100%);*/
  /*background: linear-gradient(0deg, rgba(252, 59, 38, 1) 0%, rgba(41, 41, 56, 0) 100%);*/
  opacity: ${({ selectedBackground }) =>
    selectedBackground === Backgrounds.TRIANGLES ? '1' : selectedBackground === Backgrounds.MOUNTAIN ? '0.15' : '0.3'};
  ${({ selectedBackground }) =>
    selectedBackground === Backgrounds.BLACK &&
    `img {
  height: 230% !important;
    margin-top: -20%;
    margin-left: -10%;
    opacity: 0.3;
  }`}
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }
`

const ContentPanel = styled.div`
  position: absolute;
  padding: 18px 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Title = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  font-weight: 700;
`

const TitleLeft = styled(Title)``
const TitleRight = styled(Title)``

const APRWrapper = styled.div<{ isVisible: boolean; isPositive: boolean }>`
  width: 100%;
  text-align: left;
  font-size: 38px;
  color: ${({ theme, isPositive }) => (isPositive ? theme.green1 : theme.red1)};
  margin-top: -8px;
  margin-bottom: 4px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

const APRText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  margin-left: 3px;
`

const MineralsAmountWrapper = styled.div`
  color: ${({ theme }) => theme.blue2};
  font-size: 12px;
  font-weight: 600;
  margin-top: -12px;
  margin-bottom: 2px;
`

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -3px;
`

const BorrowContent = styled.div`
  width: 100%;
  margin: 10px 0;
  position: relative;
`

const BorrowLeft = styled.div`
  width: 48%;
  margin-right: 2%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  color: ${({ theme }) => theme.text1};
`

const BorrowRight = styled.div`
  width: 48%;
  margin-left: 2%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  color: ${({ theme }) => theme.text1};
`

const PositionDate = styled.div`
  position: absolute;
  left: 20px;
  bottom: 12px;
  font-weight: 300;
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  z-index: 1;
  width: 100%;
`

const PositionType = styled.div`
  position: absolute;
  right: 20px;
  bottom: 12px;
  font-weight: 300;
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  z-index: 1;
  white-space: nowrap;
`

const TokenRow = styled.div`
  width: 100%;
`

const TokenInfo = styled.div`
  width: calc(50% + 20px);
  margin-right: -20px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`

const TokenAmount = styled.div<{ oversized: boolean; isVisible: boolean }>`
  width: 50%;
  display: ${({ isVisible }) => (isVisible ? 'inline-block' : 'none')};
  vertical-align: top;
  text-align: right;
  font-size: ${({ oversized }) => (oversized ? '14px' : '16px')};
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`

const TokenLogo = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;

  img {
    height: 20px;
    width: auto;
  }
`

const TokenSymbol = styled.div<{ oversized?: boolean }>`
  display: inline-block;
  vertical-align: top;
  font-size: ${({ oversized }) => (oversized ? '14px' : '16px')};
  width: calc(100% - 25px);
`

const LogoWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  img,
  svg {
    height: 22px;
  }
`

const LogoText = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  text-align: right;
  margin-bottom: -3px;
`

const NetworkText = styled.div`
  font-size: 7px;
  color: ${({ theme }) => theme.text3};
  font-weight: 700;
  text-align: right;
  margin-top: -7px;
`

const GenerateButton = styled.div`
  display: inline-block;
  vertical-align: top;
  float: right;
  margin: -40px 5px 10px 0;
  padding: 12px 18px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.green2};
  width: 110px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.green1};
  }

  > div {
    height: 22px !important;
    width: 22px !important;
    margin-bottom: -5px;
  }

  svg {
    color: white;
  }

  @media screen and (max-width: 500px) {
    margin: 15px 0 10px 0;
    width: 100%;
    text-align: center;
  }
`

const FinalImageWrapper = styled.div`
  padding: 20px 25px;
  position: relative;
  background-color: ${({ theme }) => theme.bg6};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
`

const FinalImageTitile = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.text1};
  font-weight: 300;
  margin-bottom: 10px;
`

const FinalImage = styled.img`
  width: 100%;
`

const ShareButtonsWrapper = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
`

const ShareButton = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 10px 5px 0;
  padding: 8px 15px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.blue1};
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.blue2};
  }
`

const CheckBoxWrapper = styled.div<{ isDisabled: boolean }>`
  display: inline-block;
  cursor: pointer;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  svg {
    height: 20px;
    width: 20px;
  }
`

const BobosWrapper = styled.div`
  overflow: hidden;
  height: 200px;
  margin: -10px -15px -15px;
  width: 400px;
  position: absolute;
  z-index: 9;
  opacity: 1;

  img {
    width: 100%;
  }
`

const BobosSelector = styled.div<{ isSelected: boolean }>`
  width: calc(50% - 8px);
  height: calc(33.33% - 8px);
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  margin: 4px;
  ${({ isSelected, theme }) => (isSelected ? `border: 2px solid ${theme.green1}` : 'none')};
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  position: relative;

  :hover {
    opacity: 1;
  }

  img {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`

const CheckBox = ({
  isChecked,
  isDisabled,
  onClick,
}: {
  isChecked: boolean
  isDisabled?: boolean
  onClick: () => void
}) => {
  return (
    <CheckBoxWrapper isDisabled={isDisabled ?? false} onClick={onClick}>
      {isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    </CheckBoxWrapper>
  )
}

function getCurrentTime() {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`
}

function getCurrentTimeFormatted() {
  const now = new Date()
  const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.']
  const month = months[now.getMonth()]
  const day = now.getDate()
  const year = now.getFullYear()
  let hour = now.getHours()
  const minute = now.getMinutes()
  const ampm = hour >= 12 ? 'pm' : 'am'

  hour = hour % 12
  hour = hour ? hour : 12 // Convert midnight (0:00) to 12:00

  return `${month} ${day} ${year} ${hour}:${minute < 10 ? '0' : ''}${minute}${ampm}`
}

interface ShareBorrowPositionProps {
  isOpen: boolean
  memoizedOnDismiss: () => void
  position: BorrowPosition
}

function ShareBorrowPositionModalComparator(prevProps: ShareBorrowPositionProps, nextProps: ShareBorrowPositionProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.memoizedOnDismiss === nextProps.memoizedOnDismiss &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD
  )
}

enum APROptions {
  APR,
  APY,
  NONE,
}

enum InterestRateOptions {
  RELATIVE,
  SIMPLE,
}

const ShareBorrowPositionModal = React.memo(function ShareBorrowPositionModalComponent({
  isOpen,
  memoizedOnDismiss,
  position,
}: ShareBorrowPositionProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [showAmounts, setShowAmounts] = useState(true)
  const [showUSDAmounts, setShowUSDAmounts] = useState(false)
  const [showRatios, setShowRatios] = useState<BorrowPositionAmount | undefined>(undefined) // To show just the ratios of assets, it needs to be scaled to one of the assets, so have them select from a dropdown.
  const [showUSDRatios, setShowUSDRatios] = useState<BorrowPositionAmount | undefined>(undefined) // To show just the ratios of assets, it needs to be scaled to one of the assets, so have them select from a dropdown.
  const [showDate, setShowDate] = useState(true)
  const [showMinerals, setShowMinerals] = useState(true)
  const [selectedAprOption, setSelectedAprOption] = useState(APROptions.APR)
  const [selectedInterestRate, setSelectedInterestRate] = useState(InterestRateOptions.RELATIVE)
  const [selectedBackground, setSelectedBackground] = useState(Backgrounds.BLACK)
  const [isEditing, setIsEditing] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [imageSource, setImageSource] = useState<any>('')
  const [showImageCopied, setShowImageCopied] = useState(false)
  const [showBobos, setShowBobos] = useState(false)
  const [oraclePriceMap] = useDefaultFiatValuesWithLoadingIndicator(position.supplyAmounts.map(supply => supply.token))
  const weeklyMineralRewards = useCalculatePositionMinerals(position, showRatios, showUSDRatios, oraclePriceMap)
  const showAPR = selectedAprOption !== APROptions.NONE
  const rawInterestRate = useNetInterestRate(position)
  const relativeNetInterestRate =
    selectedAprOption === APROptions.APY ? calculateAprToApy(rawInterestRate ?? ZERO_PERCENT) : rawInterestRate
  const simpleNetInterestRate =
    selectedAprOption === APROptions.APY ? calculateAprToApy(position.netInterestRate) : position.netInterestRate
  const interestRate =
    selectedInterestRate === InterestRateOptions.RELATIVE ? relativeNetInterestRate : simpleNetInterestRate

  const [tokenSelectOpen, setTokenSelectOpen] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }
    setIsLoadingImage(true)

    toPng(ref.current, {
      cacheBust: true,
      pixelRatio: 3,
    })
      .then(dataUrl => {
        const base64URL = dataUrl.replace('image/jpeg', 'image/octet-stream')
        setImageSource(base64URL)
        setIsEditing(false)
        setIsLoadingImage(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoadingImage(false)
      })
  }, [])

  const saveToDevice = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, {
      cacheBust: true,
      pixelRatio: 3,
    })
      .then(dataUrl => {
        const link = document.createElement('a')
        link.download = `Dolomite-borrow-position-${getCurrentTime()}.png`
        link.href = dataUrl
        link.click()
      })
      .catch(err => {
        console.log(err)
      })
  }, [ref])

  const saveToClipboard = useCallback(() => {
    getBlobFromImageSource(imageSource)
      .then(blob => {
        return copyBlobToClipboard(blob)
      })
      .then(() => {
        setShowImageCopied(true)
        setTimeout(() => {
          setShowImageCopied(false)
        }, 3000)
      })
      .catch(e => {
        console.log('Error: ', e.message)
      })
  }, [imageSource])

  useEffect(() => {
    if (showAmounts) {
      setShowUSDAmounts(false)
      setShowRatios(undefined)
      setShowUSDRatios(undefined)
    }
  }, [showAmounts])

  useEffect(() => {
    if (showUSDAmounts) {
      setShowAmounts(false)
      setShowRatios(undefined)
      setShowUSDRatios(undefined)
    }
  }, [showUSDAmounts])

  useEffect(() => {
    if (showRatios) {
      setShowAmounts(false)
      setShowUSDAmounts(false)
      setShowUSDRatios(undefined)
    }
  }, [showRatios])

  useEffect(() => {
    if (showUSDRatios) {
      setShowAmounts(false)
      setShowUSDAmounts(false)
      setShowRatios(undefined)
    }
  }, [showUSDRatios])

  const timeDisplayString = getCurrentTimeFormatted()

  const oversizedText = useMemo(() => {
    let hasFoundOversized = false
    for (let i = 0; i < position.supplyAmounts.length; i++) {
      const tokenSymbol = cleanCurrencySymbol(position.supplyAmounts[i].token)
      if (tokenSymbol && tokenSymbol.length > 6) {
        hasFoundOversized = true
        break
      }
    }
    for (let i = 0; i < position.borrowAmounts.length; i++) {
      const tokenSymbol = cleanCurrencySymbol(position.borrowAmounts[i].token)
      if (tokenSymbol && tokenSymbol.length > 6) {
        hasFoundOversized = true
        break
      }
    }
    return hasFoundOversized
  }, [position.borrowAmounts, position.supplyAmounts])

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={memoizedOnDismiss} maxHeight={90} maxWidthPx={510}>
      <SharePositionWrapper>
        <ModalTitle>{t('shareBorrowPosition')}</ModalTitle>
        <ScreensWrapper isEditing={true}>
          <EditScreen>
            <Preview>
              <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10}>
                <PreviewWrapper ref={ref}>
                  {chainId === ChainId.BERACHAIN && showBobos && (
                    <BobosWrapper>
                      <img src={BobosPointing} />
                    </BobosWrapper>
                  )}
                  <BackgroundPanel
                    selectedBackground={selectedBackground}
                    isPositive={interestRate ? !interestRate.lessThan(ZERO_PERCENT) : true}
                  >
                    {selectedBackground === Backgrounds.TRIANGLES && <img src={Triangles} />}
                    {selectedBackground === Backgrounds.BLACK && <img src={DolomiteLogo} />}
                  </BackgroundPanel>
                  <ContentPanel>
                    {interestRate ? (
                      <APRWrapper isVisible={showAPR} isPositive={!interestRate.lessThan(ZERO_PERCENT)}>
                        {interestRate.greaterThan(ZERO_FRACTION) ? '+' : ''}
                        {interestRate.equalTo(ZERO_PERCENT) ? '0.00' : interestRate.toFixed(2)}%
                        <APRText>{selectedAprOption === APROptions.APY ? 'APY' : 'APR'}</APRText>
                      </APRWrapper>
                    ) : (
                      'loading...'
                    )}
                    {weeklyMineralRewards && weeklyMineralRewards.greaterThan('0') && showMinerals && (
                      <MineralsAmountWrapper>
                        +{formatAmount(weeklyMineralRewards, 2, true, '0.00')} {t('mineralsPerWeek')}
                      </MineralsAmountWrapper>
                    )}
                    <LogoWrapper>
                      <LogoText>{t('borrowingOn')}</LogoText>
                      <img src={DolomiteNameAndLogo} alt={''} />
                      <NetworkText>{NETWORK_LABELS[chainId]}</NetworkText>
                    </LogoWrapper>
                    <TitleRow>
                      <TitleLeft>{t('collateral')}</TitleLeft>
                      <TitleRight>{t('borrowing')}</TitleRight>
                    </TitleRow>
                    <BorrowContent>
                      <BorrowLeft>
                        {position.supplyAmounts.slice(0, 3).map((supplyAmount, i) => (
                          <TokenRow key={supplyAmount.token.symbol}>
                            <TokenInfo>
                              <TokenLogo>
                                <img
                                  src={getLogoOrDefault(cleanCurrencySymbol(supplyAmount.token) ?? '')}
                                  alt={`${cleanCurrencySymbol(supplyAmount.token)} logo`}
                                />
                              </TokenLogo>
                              <TokenSymbol oversized={oversizedText}>
                                {cleanCurrencySymbol(supplyAmount.token)}
                              </TokenSymbol>
                            </TokenInfo>
                            <TokenAmount
                              oversized={oversizedText}
                              isVisible={showAmounts || showUSDAmounts || !!showRatios || !!showUSDRatios}
                            >
                              {showRatios
                                ? showRatios.token.symbol === supplyAmount.token.symbol
                                  ? formatAmount(new Fraction(1))
                                  : formatAmount(
                                      supplyAmount.amountTokenWei.asFraction.divide(
                                        showRatios.amountTokenWei.asFraction,
                                      ),
                                    )
                                : showUSDRatios
                                ? showUSDRatios.token.symbol === supplyAmount.token.symbol
                                  ? formatAmount(new Fraction(1).multiply(100), 2) + '%'
                                  : formatAmount(
                                      supplyAmount.amountUSD.divide(showUSDRatios.amountUSD).multiply(100),
                                      2,
                                    ) + '%'
                                : showUSDAmounts
                                ? '$' + formatAmount(supplyAmount.amountUSD, 2)
                                : formatAmount(supplyAmount.amountTokenWei)}
                            </TokenAmount>
                          </TokenRow>
                        ))}
                      </BorrowLeft>
                      <BorrowRight>
                        {position.borrowAmounts.slice(0, 3).map((borrowAmount, i) => (
                          <TokenRow key={borrowAmount.token.symbol}>
                            <TokenInfo>
                              <TokenLogo>
                                <img
                                  src={getLogoOrDefault(cleanCurrencySymbol(borrowAmount.token) ?? '')}
                                  alt={`${cleanCurrencySymbol(borrowAmount.token)} logo`}
                                />
                              </TokenLogo>
                              <TokenSymbol oversized={oversizedText}>
                                {cleanCurrencySymbol(borrowAmount.token)}
                              </TokenSymbol>
                            </TokenInfo>
                            <TokenAmount
                              oversized={oversizedText}
                              isVisible={showAmounts || showUSDAmounts || !!showRatios || !!showUSDRatios}
                            >
                              {showRatios
                                ? showRatios.token.symbol === borrowAmount.token.symbol
                                  ? formatAmount(new Fraction(1))
                                  : formatAmount(
                                      borrowAmount.amountTokenWei.asFraction.divide(
                                        showRatios.amountTokenWei.asFraction,
                                      ),
                                    )
                                : showUSDRatios
                                ? showUSDRatios.token.symbol === borrowAmount.token.symbol
                                  ? formatAmount(new Fraction(1).multiply(100), 2) + '%'
                                  : formatAmount(
                                      borrowAmount.amountUSD.divide(showUSDRatios.amountUSD).multiply(100),
                                      2,
                                    ) + '%'
                                : showUSDAmounts
                                ? '$' + formatAmount(borrowAmount.amountUSD, 2)
                                : formatAmount(borrowAmount.amountTokenWei)}
                            </TokenAmount>
                          </TokenRow>
                        ))}
                      </BorrowRight>
                    </BorrowContent>
                  </ContentPanel>
                  {showDate && <PositionDate>{timeDisplayString}</PositionDate>}
                  <PositionType>
                    {showAmounts
                      ? t('displayingCollateral')
                      : showUSDAmounts
                      ? t('displayingUsdValue')
                      : showRatios
                      ? t('relativeToAsset', { asset: cleanCurrencySymbol(showRatios.token) })
                      : showUSDRatios
                      ? t('ratioRelativeToUsd', { asset: cleanCurrencySymbol(showUSDRatios.token) })
                      : ''}
                  </PositionType>
                </PreviewWrapper>
              </Tilt>
            </Preview>
            <OptionsWrapper>
              <OptionsTitle>{t('options')}</OptionsTitle>
              <MultiToggleBox>
                <ToggleOption
                  selected={selectedAprOption === APROptions.APR}
                  onClick={() => setSelectedAprOption(APROptions.APR)}
                >
                  APR
                </ToggleOption>
                <ToggleOption
                  selected={selectedAprOption === APROptions.APY}
                  onClick={() => setSelectedAprOption(APROptions.APY)}
                >
                  APY
                </ToggleOption>
                <ToggleOption
                  selected={selectedAprOption === APROptions.NONE}
                  onClick={() => setSelectedAprOption(APROptions.NONE)}
                >
                  {t('none')}
                </ToggleOption>
              </MultiToggleBox>
              <MultiToggleBox>
                <ToggleOption
                  selected={selectedInterestRate === InterestRateOptions.RELATIVE}
                  onClick={() => setSelectedInterestRate(InterestRateOptions.RELATIVE)}
                >
                  {t('relative')}
                </ToggleOption>
                <ToggleOption
                  selected={selectedInterestRate === InterestRateOptions.SIMPLE}
                  onClick={() => setSelectedInterestRate(InterestRateOptions.SIMPLE)}
                >
                  {t('simple')}
                </ToggleOption>
              </MultiToggleBox>
              <ExplainerArrowsWrapper>
                {selectedInterestRate === InterestRateOptions.RELATIVE && <ExplainerArrowLeft />}
                {selectedInterestRate === InterestRateOptions.SIMPLE && <ExplainerArrowRight />}
              </ExplainerArrowsWrapper>
              <ExplainerText>
                {selectedInterestRate === InterestRateOptions.RELATIVE
                  ? t('relativeAprExplainer')
                  : t('simpleAprExplainer')}
              </ExplainerText>
              <Option>
                <CheckBox
                  isChecked={showAmounts}
                  onClick={() => {
                    setShowAmounts(!showAmounts)
                  }}
                />
                <OptionText>{t('showAmounts')}</OptionText>
              </Option>
              {weeklyMineralRewards && weeklyMineralRewards.greaterThan('0') && (
                <Option>
                  <CheckBox
                    isChecked={showMinerals}
                    onClick={() => {
                      setShowMinerals(!showMinerals)
                    }}
                  />
                  <OptionText>{t('showMinerals')}</OptionText>
                </Option>
              )}
              <Option>
                <CheckBox
                  isChecked={showUSDAmounts}
                  onClick={() => {
                    setShowUSDAmounts(!showUSDAmounts)
                  }}
                />
                <OptionText>{t('showUsdAmounts')}</OptionText>
              </Option>
              <Option>
                <CheckBox
                  isChecked={!!showRatios}
                  onClick={() => {
                    setShowRatios(showRatios === undefined ? position.supplyAmounts[0] : undefined)
                  }}
                />
                <OptionText>
                  {t('showRatios')} <StyledTooltipWithIcon tooltipText={t('showRatiosTooltip')} />
                </OptionText>
                <ExtraOption>
                  &#8627; {t('ratioScaledTo')}:
                  <TokenSelectOuter>
                    <TokenSelector
                      expanded={!!showRatios && tokenSelectOpen}
                      onClick={e => {
                        e.stopPropagation()
                        !tokenSelectOpen && setTokenSelectOpen(true)
                      }}
                      ref={wrapperRef}
                      isLoading={!showRatios}
                      disabled={!showRatios}
                    >
                      {!tokenSelectOpen && (
                        <TokenSelectRow onClick={() => setTokenSelectOpen(false)}>
                          {cleanCurrencySymbol(showRatios?.token)}
                        </TokenSelectRow>
                      )}
                      {!!showRatios &&
                        [...position.supplyAmounts, ...position.borrowAmounts]
                          .filter(amount => amount.token.symbol !== showRatios.token.symbol)
                          .map((amount, index) => {
                            return (
                              <TokenSelectRow
                                key={`token-dropdown-${index}`}
                                onClick={() => {
                                  setShowRatios(amount)
                                  setTokenSelectOpen(false)
                                }}
                              >
                                {cleanCurrencySymbol(amount.token)}
                              </TokenSelectRow>
                            )
                          })}
                      {tokenSelectOpen && (
                        <TokenSelectRow onClick={() => setTokenSelectOpen(false)}>
                          {cleanCurrencySymbol(showRatios?.token)}
                        </TokenSelectRow>
                      )}
                      <ArrowDown flipped={!!showRatios && tokenSelectOpen} />
                    </TokenSelector>
                  </TokenSelectOuter>
                </ExtraOption>
              </Option>
              <Option>
                <CheckBox
                  isChecked={!!showUSDRatios}
                  onClick={() => {
                    setShowUSDRatios(showUSDRatios === undefined ? position.supplyAmounts[0] : undefined)
                  }}
                />
                <OptionText>
                  {t('showUsdRatios')}
                  <StyledTooltipWithIcon tooltipText={t('showUsdRatiosTooltip')} />
                </OptionText>
                <ExtraOption>
                  &#8627; {t('ratioScaledTo')}:
                  <TokenSelectOuter>
                    <TokenSelector
                      expanded={!!showUSDRatios && tokenSelectOpen}
                      onClick={e => {
                        e.stopPropagation()
                        !tokenSelectOpen && setTokenSelectOpen(true)
                      }}
                      ref={wrapperRef}
                      isLoading={!showUSDRatios}
                      disabled={!showUSDRatios}
                    >
                      {!tokenSelectOpen && (
                        <TokenSelectRow onClick={() => setTokenSelectOpen(false)}>
                          {cleanCurrencySymbol(showUSDRatios?.token)}
                        </TokenSelectRow>
                      )}
                      {!!showUSDRatios &&
                        [...position.supplyAmounts, ...position.borrowAmounts]
                          .filter(amount => amount.token.symbol !== showUSDRatios.token.symbol)
                          .map((amount, index) => {
                            return (
                              <TokenSelectRow
                                key={`token-dropdown-${index}`}
                                onClick={() => {
                                  setShowUSDRatios(amount)
                                  setTokenSelectOpen(false)
                                }}
                              >
                                {cleanCurrencySymbol(amount.token)}
                              </TokenSelectRow>
                            )
                          })}
                      {tokenSelectOpen && (
                        <TokenSelectRow onClick={() => setTokenSelectOpen(false)}>
                          {cleanCurrencySymbol(showUSDRatios?.token)}
                        </TokenSelectRow>
                      )}
                      <ArrowDown flipped={!!showUSDRatios && tokenSelectOpen} />
                    </TokenSelector>
                  </TokenSelectOuter>
                </ExtraOption>
              </Option>
              <Option>
                <CheckBox
                  isChecked={showDate}
                  onClick={() => {
                    setShowDate(!showDate)
                  }}
                />
                <OptionText>{t('showCurrentDate')}</OptionText>
              </Option>
            </OptionsWrapper>
            <BackgroundsWrapper>
              <BackgroundsTitle>{t('backgrounds')}</BackgroundsTitle>
              <BackgroundSelector>
                <BackgroundOptionFade
                  selected={selectedBackground === Backgrounds.FADE}
                  onClick={() => setSelectedBackground(Backgrounds.FADE)}
                />
                <BackgroundOptionGreen
                  isPositive={interestRate ? !interestRate.lessThan(ZERO_PERCENT) : true}
                  selected={selectedBackground === Backgrounds.GREEN}
                  onClick={() => setSelectedBackground(Backgrounds.GREEN)}
                />
                <BackgroundOptionTriangles
                  selected={selectedBackground === Backgrounds.TRIANGLES}
                  onClick={() => setSelectedBackground(Backgrounds.TRIANGLES)}
                >
                  <img src={Triangles} />
                </BackgroundOptionTriangles>
                <BackgroundOptionPlain
                  selected={selectedBackground === Backgrounds.PLAIN}
                  onClick={() => setSelectedBackground(Backgrounds.PLAIN)}
                />
                <BackgroundOptionMountain
                  selected={selectedBackground === Backgrounds.MOUNTAIN}
                  onClick={() => setSelectedBackground(Backgrounds.MOUNTAIN)}
                />
                <BackgroundOptionBlack
                  selected={selectedBackground === Backgrounds.BLACK}
                  onClick={() => setSelectedBackground(Backgrounds.BLACK)}
                >
                  <img src={DolomiteLogo} alt={''} draggable={'false'} />
                </BackgroundOptionBlack>
                {chainId === ChainId.BERACHAIN && (
                  <BobosSelector isSelected={showBobos} onClick={() => setShowBobos(!showBobos)}>
                    <img src={BobosPointing} />
                  </BobosSelector>
                )}
              </BackgroundSelector>
            </BackgroundsWrapper>
            <GenerateButton onClick={onButtonClick}>
              {isLoadingImage ? <CircularProgress /> : 'Generate'}
            </GenerateButton>
          </EditScreen>
          <Modal isOpen={!isEditing} onDismiss={() => setIsEditing(true)} maxHeight={100} maxWidthPx={420}>
            <FinalImageWrapper>
              <FinalImageTitile>{t('yourGeneratedImage')}:</FinalImageTitile>
              <FinalImage id={'generated-image'} src={imageSource} />
              <Close onClick={() => setIsEditing(true)} />
              <ShareButtonsWrapper>
                <ShareButton onClick={saveToDevice}>{t('save')}</ShareButton>
                <ShareButton onClick={saveToClipboard}>
                  {showImageCopied ? `${t('copied')} ✓` : t('copyToClipboard')}
                </ShareButton>
              </ShareButtonsWrapper>
            </FinalImageWrapper>
          </Modal>
        </ScreensWrapper>
      </SharePositionWrapper>
    </Modal>
  )
},
ShareBorrowPositionModalComparator)

function ShareBorrowPositionModalWrapper({ isOpen, memoizedOnDismiss, position }: ShareBorrowPositionProps) {
  if (!isOpen || !position) {
    return <></>
  }
  return <ShareBorrowPositionModal isOpen={isOpen} memoizedOnDismiss={memoizedOnDismiss} position={position} />
}

export default ShareBorrowPositionModalWrapper
