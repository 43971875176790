import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@material-ui/core/Input'
import styled from 'styled-components/macro'
import { PageSizeContext } from '../Leaderboard'
import { X } from 'react-feather'
import Column from '../../components/Orders/Column'
import { styled as muiStyled } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack'
import { getEtherscanLink, shortenAddress, shortenTextIfNecessary } from '../../utils'
import {
  LeaderboardItem,
  useLiquidityMiningLeaderboard,
  useLiquidityMiningLeaderboardRankByAccount,
} from '../../types/liquidityMiningLeaderboard'
import { useMineralToken } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { formatAmount } from '../../utils/formatAmount'
import { ExternalLink } from '../../theme'

const LeaderboardWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
`

const TableWrapper = styled.div`
  background-color: #292938;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px;
`

export const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
  color: ${({ theme }) => theme.text3};

  @media screen and (max-width: 660px) {
    margin-bottom: 0;
    font-size: 13px;
  }
`

const LeftWrapper = styled.div`
  width: calc(100% - 110px);
  display: inline-block;
  vertical-align: top;
`
const LeaderboardTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
`

const LeaderboardSubtitle = styled.div`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 8px;
`

const RankWrapper = styled.div`
  vertical-align: top;
  display: inline-block;
  text-align: right;
  width: 110px;
  margin-bottom: 10px;
  margin-top: -5px;
  font-weight: 300;
  font-size: 12px;
`

const Rank = styled.div`
  font-size: 28px;
  margin-top: -5px;
  font-weight: 600;
`

const PageSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Arrow = styled.div<{ faded: boolean }>`
  color: ${({ theme }) => theme.text1};
  opacity: ${({ faded }) => (faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;

  :hover {
    cursor: ${({ faded }) => (faded ? 'auto' : 'pointer')} !important;
  }
`

const InputWrapper = styled.div`
  position: relative;
  vertical-align: top;
  width: 100%;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 375px) {
    input {
      font-size: 12px !important;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 4px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const RowWrapper = styled.div`
  height: 33px;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 35px;
  width: calc(100% + 70px);
  margin: 3px 0 3px -35px;

  :nth-child(even) {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const Name = styled.span`
  vertical-align: top;
  margin-left: 8px;
  font-weight: 400;
`

const EnsIcon = styled.img`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-block;
`

const Rank69 = styled.span`
  background-image: linear-gradient(to left, violet, green, yellow, orange, red);
  background-clip: text;
  color: transparent;
`

const Forward = muiStyled(ArrowForwardIcon)(() => ({
  fontSize: 12,
  color: '#f9f9f9',
}))

const Backward = muiStyled(ArrowBackwardIcon)(() => ({
  fontSize: 12,
  color: '#f9f9f9',
}))

function LeaderboardRow({ leaderboardItem }: { leaderboardItem: LeaderboardItem }) {
  const { chainId, account } = useActiveWeb3React()
  // TODO: use this - needs styling changes on desktop + mobile
  const isUser = account?.toLowerCase() === leaderboardItem.userAddress.toLowerCase() ? '👉 ' : ''
  return (
    <RowWrapper>
      <Column
        width={10}
        textAlign={'left'}
        style={
          leaderboardItem.rank >= 1 && leaderboardItem.rank <= 3
            ? {
                marginLeft: '-5px',
                marginRight: '5px',
                marginTop: '-1px',
                fontSize: '18px',
              }
            : {}
        }
      >
        {leaderboardItem.rank === 1 ? (
          `🥇`
        ) : leaderboardItem.rank === 2 ? (
          `🥈`
        ) : leaderboardItem.rank === 3 ? (
          `🥉`
        ) : leaderboardItem.rank === 69 ? (
          <Rank69>69</Rank69>
        ) : (
          `${leaderboardItem.rank}`
        )}
      </Column>
      <Column width={65} textAlign={'left'}>
        <ExternalLink href={getEtherscanLink(chainId, leaderboardItem.userAddress, 'address')}>
          <EnsIcon
            src={leaderboardItem.profilePhoto ?? `https://cdn.stamp.fyi/avatar/eth:${leaderboardItem.userAddress}`}
          />
          <Name>
            {shortenTextIfNecessary(leaderboardItem.userName, 6) ?? shortenAddress(leaderboardItem.userAddress, 4)}
          </Name>
        </ExternalLink>
      </Column>
      <Column width={25} textAlign={'right'}>
        {formatAmount(leaderboardItem.totalClaimAmount.asFraction)}
      </Column>
    </RowWrapper>
  )
}

const LeaderboardFillerText = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 300;
  margin: 15px 0;
`

const ROWS_PER_PAGE = 50

export default function Leaderboard() {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>('')
  const [page, setPage] = useState(1)
  const mineralToken = useMineralToken()
  const { data: userRank } = useLiquidityMiningLeaderboardRankByAccount(mineralToken, account)
  const { data: leaderboardData } = useLiquidityMiningLeaderboard(mineralToken)
  const sortedLeaderboardData = useMemo(() => {
    return leaderboardData !== undefined
      ? leaderboardData.sort((a, b) => {
          if (a && b) {
            return a.totalClaimAmount.greaterThan(b.totalClaimAmount) ? -1 : 1
          } else {
            return -1
          }
        })
      : undefined
  }, [leaderboardData]) // TODO - better memoization

  const len = sortedLeaderboardData?.length ?? 0
  const lastPage = Math.floor(len / ROWS_PER_PAGE) + (len % ROWS_PER_PAGE === 0 ? 0 : 1)
  const filteredLeaderboardData = useMemo(() => {
    return inputValue.length > 0
      ? sortedLeaderboardData?.filter(leaderboardRow => {
          return (
            leaderboardRow.userAddress.toUpperCase().includes(inputValue.toUpperCase()) ||
            leaderboardRow.userName?.toUpperCase().includes(inputValue.toUpperCase())
          )
        })
      : sortedLeaderboardData
  }, [inputValue, sortedLeaderboardData]) // TODO - better memoization

  const paginatedLeaderboardData = useMemo(() => {
    return filteredLeaderboardData
      ? filteredLeaderboardData.slice(ROWS_PER_PAGE * (page - 1), page * ROWS_PER_PAGE)
      : undefined
  }, [page, filteredLeaderboardData])

  return (
    <PageSizeContext.Consumer>
      {({ x }) => {
        const isMobile = x <= 660
        return (
          <LeaderboardWrapper>
            <LeaderboardTitle>{t('leaderboard')}</LeaderboardTitle>
            <LeaderboardSubtitle>{t('leaderboardSubtitle')}</LeaderboardSubtitle>
            <TableWrapper>
              <LeftWrapper>
                <InputWrapper>
                  <InputOverflowFix>
                    <StyledInput
                      onChange={(e: any) => setInputValue(e.target.value)}
                      multiline={false}
                      fullWidth
                      spellCheck={false}
                      placeholder={'Search for account'}
                      value={inputValue}
                      variant=''
                      disableUnderline={true}
                      endAdornment={''}
                    />
                  </InputOverflowFix>
                  <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
                    <Close />
                  </Clear>
                </InputWrapper>
              </LeftWrapper>
              <RankWrapper>
                {userRank && t('rank')}
                <Rank>{userRank?.equalTo(69) ? <Rank69>69</Rank69> : userRank?.toFixed(0) ?? ''}</Rank>
              </RankWrapper>
              <ColumnTitles>
                <Column width={10} textAlign={'left'}>
                  #
                </Column>
                <Column width={65} textAlign={'left'}>
                  {t('account')}
                </Column>
                <Column width={25} textAlign={'right'}>
                  {t('collectedMinerals')}
                </Column>
              </ColumnTitles>
              {paginatedLeaderboardData && paginatedLeaderboardData.length > 0 ? (
                paginatedLeaderboardData.map(item => (
                  <LeaderboardRow leaderboardItem={item} key={`minerals-leaderboard-${item.userAddress}`} />
                ))
              ) : leaderboardData?.length === 0 ? (
                <LeaderboardFillerText>Loading...</LeaderboardFillerText>
              ) : paginatedLeaderboardData?.length === 0 ? (
                <LeaderboardFillerText>{t('noResults')}</LeaderboardFillerText>
              ) : (
                t('loading')
              )}
              <PageSelect>
                <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
                  <Backward />
                </Arrow>
                {page}
                <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
                  <Forward />
                </Arrow>
              </PageSelect>
            </TableWrapper>
          </LeaderboardWrapper>
        )
      }}
    </PageSizeContext.Consumer>
  )
}
