import { init as initWeb3Onboard } from '@web3-onboard/react'
import { ChainId } from './constants'
import EthereumLogo from './assets/svg/ethereum_logo.svg'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import frameModule from '@web3-onboard/frame'
import gnosisModule from '@web3-onboard/gnosis'
import injectedModule from '@web3-onboard/injected-wallets'
import torusModule from '@web3-onboard/torus'
import walletConnectModule from '@web3-onboard/walletconnect'
import { CHAIN_ID_TO_URLS_MAP } from './connectors'

import { ChainIdMap, NETWORK_LABELS, NETWORK_LOGOS, NETWORKS } from './constants/chainId'
import { Chain } from '@web3-onboard/common'

const chainIdMap: ChainIdMap<Chain | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: {
    id: `0x${ChainId.ARBITRUM_ONE.toString(16)}`,
    token: 'ETH',
    label: NETWORK_LABELS[ChainId.ARBITRUM_ONE],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.ARBITRUM_ONE][0],
    icon: NETWORK_LOGOS[ChainId.ARBITRUM_ONE],
  },
  [ChainId.BASE]: {
    id: `0x${ChainId.BASE.toString(16)}`,
    token: 'ETH',
    label: NETWORK_LABELS[ChainId.BASE],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.BASE][0],
    icon: NETWORK_LOGOS[ChainId.BASE],
  },
  [ChainId.BERACHAIN]: {
    id: `0x${ChainId.BERACHAIN.toString(16)}`,
    token: 'BERA',
    label: NETWORK_LABELS[ChainId.BERACHAIN],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.BERACHAIN][0],
    icon: NETWORK_LOGOS[ChainId.BERACHAIN],
  },
  [ChainId.MANTLE]: {
    id: `0x${ChainId.MANTLE.toString(16)}`,
    token: 'MNT',
    label: NETWORK_LABELS[ChainId.MANTLE],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.MANTLE][0],
    icon: NETWORK_LOGOS[ChainId.MANTLE],
  },
  [ChainId.POLYGON_ZKEVM]: {
    id: `0x${ChainId.POLYGON_ZKEVM.toString(16)}`,
    token: 'ETH',
    label: NETWORK_LABELS[ChainId.POLYGON_ZKEVM],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.POLYGON_ZKEVM][0],
    icon: NETWORK_LOGOS[ChainId.POLYGON_ZKEVM],
  },
  [ChainId.X_LAYER]: {
    id: `0x${ChainId.X_LAYER.toString(16)}`,
    token: 'OKB',
    label: NETWORK_LABELS[ChainId.X_LAYER],
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.X_LAYER][0],
    icon: NETWORK_LOGOS[ChainId.X_LAYER],
  },
}

const chains = Object.values(chainIdMap)
  .filter((c): c is Chain => !!c && NETWORKS[parseInt(c.id, 16) as ChainId].isActive)
  .concat({
    id: `0x${ChainId.MAINNET.toString(16)}`,
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: CHAIN_ID_TO_URLS_MAP[ChainId.MAINNET][0],
    icon: EthereumLogo,
  })

const walletConnect = walletConnectModule({
  projectId: (process.env.WALLET_CONNECT_PROJECT_ID ?? process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID)!,
  optionalChains: chains.map(c => c && (parseInt(c.id, 16) as ChainId)).filter(chainId => chainId !== ChainId.MAINNET),
  version: 2,
  dappUrl: process.env.NODE_ENV === 'production' ? 'https://app.dolomite.io' : 'http://localhost:3000',
  additionalRequiredMethods: ['eth_call', 'eth_sendTransaction', 'personal_sign', 'eth_estimateGas'],
})

const coinbaseWalletSdk = coinbaseWalletModule({ supportedWalletType: 'all' })

const frame = frameModule()

const torus = torusModule()

const gnosis = gnosisModule()

const customTheme = {
  '--w3o-background-color': '#292938',
  '--w3o-foreground-color': '#2f2f40',
  '--w3o-text-color': '#f9f9f9',
  '--w3o-border-color': '#606375',
  '--w3o-action-color': '#8fc942',
  '--w3o-border-radius': '8px',
}

const accountCenterSettings = {
  enabled: false,
  minimal: false, // enabled by default for mobile
}

const accountCenterOptions = {
  desktop: accountCenterSettings,
  mobile: accountCenterSettings,
}

export function getWeb3Onboard() {
  return initWeb3Onboard({
    wallets: [injectedModule(), walletConnect, coinbaseWalletSdk, torus, gnosis, frame],
    chains,
    appMetadata: {
      name: 'Dolomite',
      // DO NOT REMOVE OR CHANGE THIS SVG
      icon: `<svg width='100%' height='100%' viewBox='0 0 284 284' xmlns='http://www.w3.org/2000/svg'>
    <defs/>
    <g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Group-2' transform='translate(8.000000, 6.000000)' fill='#0FFFFF' fill-rule='nonzero' stroke='#FFFFFF'
           stroke-width='15'>
            <g id='dolomite-logo-svg-white' transform='translate(0.000000, 2.000000)'>
                <path d='M0,134 C0,59.9668491 59.9668491,0 134,0 C208.033151,0 268,59.9668491 268,134 C268,208.033151 208.033151,268 134,268 C59.9668491,268 0,208.033151 0,134 Z'
                      id='svg_1' fill-opacity='0'/>
                <path d='M4,96.885334 C29.2032803,89.6194797 24.5885905,87.8895209 29.9132269,89.6194797 C35.2378632,91.3494465 126.439744,261.561575 127,264'
                      id='svg_8' fill-opacity='0'/>
                <path d='M30,91 C33.139535,91.6458791 68.3495323,113.350333 72.2196715,118.317074 C76.0898106,123.283814 81.3672665,139.248335 85.5892329,142.441241 C89.8112073,145.634147 93.6813384,144.569848 97.5514776,143.150777 C101.421617,141.731706 114.439351,124.348122 117.957655,121.864751 C121.475959,119.381381 125.697925,120.800444 127.808912,124.702885 C129.9199,128.605319 195.39939,246.263554 198,251'
                      id='svg_9' fill-opacity='0'/>
                <path d='M120,123 L110,232 L120,123 Z' id='svg_10'/>
                <path d='M141,144.360818 C144.897746,141.529672 145.252092,142.945249 147.378134,140.467993 C149.504184,137.990737 150.212868,131.974539 151.275889,128.435604 C152.33891,124.89667 156.591002,125.958349 159.425736,127.020027 C162.260461,128.081714 165.095195,129.497291 167.575583,133.036226 C170.05597,136.57516 225.333178,201.3377 231.356983,204.168854 C237.380779,207 238.100098,206.379109 245,207'
                      id='svg_12' fill-opacity='0'/>
            </g>
        </g>
    </g>
</svg>`,
      description: 'Dolomite',
    },
    connect: {
      autoConnectLastWallet: true,
    },
    theme: customTheme,
    accountCenter: accountCenterOptions,
  })
}
