import { useWethToken } from '../Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { useMemo } from 'react'
import { useGmxBalanceWithSupplies, useGmxDepositInfo, useGmxStakingInfos, useGmxToken } from './useGmxProtocol'
import { BASIS_POINTS_DIVISOR } from '../../utils/processGmxAndGlpData'
import { SECONDS_PER_YEAR, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { Percent } from '@dolomite-exchange/v2-sdk'
import useGlpProxyVaultAccount from './useGlpProxyVaultAccount'
import { InterestRatePart } from '../../types/InterestRatePart'

export default function useGmxYield(): InterestRatePart[] {
  const vaultAccount = useGlpProxyVaultAccount()
  const gmxToken = useGmxToken()
  const wethToken = useWethToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(
    useMemo(() => (gmxToken ? [gmxToken, wethToken] : []), [gmxToken, wethToken]),
  )
  const gmxPrice = fiatPriceMap[gmxToken?.address ?? '']
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(vaultAccount)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(vaultAccount)
  const [depositBalanceData] = useGmxDepositInfo(vaultAccount)

  return useMemo(() => {
    if (
      !balanceAndSupplyData?.supplyData.stakedGmxTracker ||
      !depositBalanceData?.bnGmxInFeeGmx ||
      !depositBalanceData.stakedBnGmxInFeeGmx ||
      !stakingData?.stakedGmxTracker ||
      !stakingData.feeGmxTracker ||
      !gmxPrice ||
      !wethPrice
    ) {
      return []
    }

    const stakedGmxTrackerSupplyUsd = balanceAndSupplyData.supplyData.stakedGmxTracker.multiply(gmxPrice)
    const stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(gmxPrice)
    const feeGmxSupplyUsd = stakingData.feeGmxTracker.totalSupply.multiply(gmxPrice)

    let gmxAprForEsGmx = ZERO_PERCENT
    if (stakedGmxTrackerSupplyUsd.greaterThan(ZERO_FRACTION)) {
      const rawApr = stakedGmxTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(stakedGmxTrackerSupplyUsd)
      gmxAprForEsGmx = new Percent(rawApr.numerator, rawApr.denominator)
    }
    const feeGmxTrackerAnnualRewardsUsd = stakingData.feeGmxTracker.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(wethPrice)

    let gmxAprForNativeToken = ZERO_PERCENT
    if (feeGmxSupplyUsd.greaterThan(ZERO_FRACTION)) {
      const rawApr = feeGmxTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(feeGmxSupplyUsd)
      gmxAprForNativeToken = new Percent(rawApr.numerator, rawApr.denominator)
    }

    let boostPercentage = ZERO_PERCENT
    if (depositBalanceData.stakedBnGmxInFeeGmx.greaterThan(ZERO_FRACTION)) {
      const boostFraction = depositBalanceData.bnGmxInFeeGmx.divide(depositBalanceData.stakedBnGmxInFeeGmx)
      boostPercentage = new Percent(boostFraction.numerator, boostFraction.denominator)
    }

    const gmxBoostAprForNativeToken = gmxAprForNativeToken.multiply(boostPercentage).divide(BASIS_POINTS_DIVISOR)
    return [
      {
        label: 'ETH Base Yield',
        interestRate: gmxAprForNativeToken,
        isBorrowRateImpacted: false,
        metadata: null,
      },
      {
        label: 'ETH Boosted Yield',
        interestRate: gmxBoostAprForNativeToken,
        isBorrowRateImpacted: false,
        metadata: null,
      },
      {
        label: 'esGMX Yield',
        interestRate: gmxAprForEsGmx,
        isBorrowRateImpacted: false,
        metadata: null,
      },
    ]
  }, [balanceAndSupplyData, depositBalanceData, gmxPrice, stakingData, wethPrice])
}
