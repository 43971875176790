import { gql } from '@apollo/client'
import { useMemo } from 'react'
import { NO_VARIABLES, useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType } from '../state/graphql/actions'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { useActiveWeb3React } from '../hooks'
import { RefreshFrequency } from '../state/chain/hooks'
import JSBI from 'jsbi'
import { useDistributorAddressByClaimToken } from '../hooks/useLiquidityMining'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { reqParseFraction } from '../state/trade/hooks'
import { ChainId } from '../constants'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

const CLAIMS_BY_WALLET_DATA = gql`
  query claimsByWallet($blockNumber: Int!, $walletAddress: String!, $distributorAddress: String!) {
    liquidityMiningClaims(
      block: { number_gte: $blockNumber }
      where: { user: $walletAddress, distributor: $distributorAddress }
    ) {
      epoch
      amount
    }
  }
`

interface ClaimGql {
  user: {
    id: string
  }
  epoch: number
  seasonNumber: number
  amount: string
  multiplier: string | undefined
}

interface ClaimsByWalletResponse {
  liquidityMiningClaims: ClaimGql[]
}

export interface LiquidityMiningClaim {
  epoch: number
  seasonNumber: number
  amount: CurrencyAmount<Token>
  multiplier: Fraction | undefined
  proof: string[] | undefined
  startTimestamp: number
  endTimestamp: number
  isEpochFinalized: boolean
}

export interface LiquidityMiningClaimData {
  /**
   * All claims that have not yet been claimed by the user
   */
  unfinalizedClaims: LiquidityMiningClaim[] | undefined
  /**
   * All claims for the user, including claims that have been claimed as well as unclaimed ones.
   */
  allClaims: LiquidityMiningClaim[] | undefined
  /**
   * The highest epoch seen for the selected chain ID
   */
  maxEpoch: number | undefined
}

interface LiquidityMiningMetadata {
  maxEpochNumber: number
  pendleMetadata:
    | {
        startEpochNumber: number
        maxEpochNumber: number
      }
    | undefined
  deltas: number[] | undefined
}

type WalletToClaimMap = Record<
  string,
  {
    amount: string // big int
    multiplier: string | undefined
    proofs: string[]
  }
>

interface AllLiquidityMiningDataForSeason0 {
  users: WalletToClaimMap
  metadata: {
    epoch: number
    endTimestamp: number
    startTimestamp: number
  }
}

function getEffectiveSymbol(symbol: string | undefined) {
  return symbol?.toLowerCase() === 'min' ? 'mineral' : symbol
}

function getClaimByEpochUrl(
  chainId: ChainId,
  epoch: number,
  season: number,
  claimToken: Token,
  account: string,
): string {
  const symbol = getEffectiveSymbol(claimToken.symbol?.toLowerCase())
  const seasonString = JSBI.BigInt(season).toString()
  return DOLOMITE_API_SERVER_URL.concat(
    `/liquidity-mining/${chainId}/${symbol}/seasons/${seasonString}/epochs/${epoch}/wallets/${account}`,
  )
}

function useClaimsMetadataUrl(claimToken: Token | undefined): string | undefined {
  const symbol = getEffectiveSymbol(claimToken?.symbol?.toLowerCase())
  if (!symbol || !claimToken) {
    return undefined
  }

  return `${DOLOMITE_API_SERVER_URL}/liquidity-mining/${claimToken.chainId}/${symbol}/metadata`
}

export function useAllLiquidityMiningClaims(
  walletAddress: string | undefined,
  seasonNumber: number,
  claimToken: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: LiquidityMiningClaimData
} {
  const { chainId } = useActiveWeb3React()
  const distributorAddress = useDistributorAddressByClaimToken(claimToken)
  const variables = useMemo(() => {
    if (!walletAddress || !distributorAddress) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase(),
      distributorAddress: distributorAddress.toLowerCase(),
    }
  }, [walletAddress, distributorAddress])
  const allFinalizedClaimsQueryState = useGraphqlResult<ClaimsByWalletResponse>(
    GraphqlClientType.Dolomite,
    CLAIMS_BY_WALLET_DATA.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )
  const metadataQueryState = useGraphqlResult<LiquidityMiningMetadata>(
    GraphqlClientType.Fetch,
    useClaimsMetadataUrl(claimToken),
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )

  const epochCallList = useMemo(() => {
    if (typeof metadataQueryState.result?.maxEpochNumber === 'undefined' || !walletAddress || !claimToken) {
      return []
    }
    const calls: GraphqlCall[] = []
    for (let i = 0; i <= metadataQueryState.result.maxEpochNumber; i += 1) {
      calls.push({
        chainId,
        query: getClaimByEpochUrl(chainId, i, seasonNumber, claimToken, walletAddress),
        variables: null,
        clientType: GraphqlClientType.Fetch,
      })
    }

    const pendleStartEpochNumber = metadataQueryState.result.pendleMetadata?.startEpochNumber
    const pendleMaxEpochNumber = metadataQueryState.result.pendleMetadata?.maxEpochNumber
    if (pendleStartEpochNumber !== undefined && pendleMaxEpochNumber !== undefined) {
      for (let pendleEpoch = pendleStartEpochNumber; pendleEpoch <= pendleMaxEpochNumber; pendleEpoch += 1) {
        // Add the revised deltas to the list
        calls.push({
          chainId,
          query: getClaimByEpochUrl(chainId, pendleEpoch, seasonNumber, claimToken, walletAddress),
          variables: null,
          clientType: GraphqlClientType.Fetch,
        })
      }
    }

    const deltas = metadataQueryState.result.deltas ?? []
    for (const delta of deltas) {
      // Add the revised deltas to the list
      calls.push({
        chainId,
        query: getClaimByEpochUrl(chainId, delta, seasonNumber, claimToken, walletAddress),
        variables: null,
        clientType: GraphqlClientType.Fetch,
      })
    }
    return calls
  }, [
    chainId,
    walletAddress,
    metadataQueryState.result?.pendleMetadata?.startEpochNumber,
    metadataQueryState.result?.pendleMetadata?.maxEpochNumber,
    metadataQueryState.result?.maxEpochNumber,
    metadataQueryState.result?.deltas,
    claimToken,
    seasonNumber,
  ])

  const allClaimsQueryState = useGraphqlResultList<AllLiquidityMiningDataForSeason0>(
    epochCallList,
    RefreshFrequency.Slowest,
  )

  return useMemo(() => {
    if (!claimToken) {
      return {
        data: {
          allClaims: undefined,
          unfinalizedClaims: undefined,
          maxEpoch: metadataQueryState.result?.maxEpochNumber,
        },
        loading: false,
        error: false,
      }
    }

    const anyLoading = Boolean(allFinalizedClaimsQueryState.loading)
    const anyError = Boolean(allFinalizedClaimsQueryState.error)
    if (
      !allFinalizedClaimsQueryState.result?.liquidityMiningClaims ||
      allClaimsQueryState.some(state => !state.result)
    ) {
      return {
        data: {
          allClaims: undefined,
          unfinalizedClaims: undefined,
          maxEpoch: metadataQueryState.result?.maxEpochNumber,
        },
        loading: anyLoading,
        error: anyError,
      }
    }

    const epochToIsFinalizedMap = (allFinalizedClaimsQueryState.result.liquidityMiningClaims ?? []).reduce<
      Record<string, boolean | undefined>
    >((memo, claim) => {
      memo[claim.epoch] = true
      return memo
    }, {})

    const allClaims = allClaimsQueryState.reduce<LiquidityMiningClaim[]>((acc, claimState) => {
      const metadata = claimState.result?.metadata
      const claim = claimState.result?.users[walletAddress?.toLowerCase() ?? '']
      if (!claim || !metadata) {
        return acc
      }
      return acc.concat({
        seasonNumber,
        epoch: metadata.epoch,
        amount: CurrencyAmount.fromRawAmount(claimToken, claim.amount),
        multiplier: claim.multiplier ? reqParseFraction(claim.multiplier) : undefined,
        proof: claim.proofs.length === 0 ? undefined : claim.proofs,
        startTimestamp: metadata.startTimestamp,
        endTimestamp: metadata.endTimestamp,
        isEpochFinalized: claim.proofs.length !== 0,
      })
    }, [])

    const unfinalizedClaims = allClaims.reduce<LiquidityMiningClaim[]>((acc, claim) => {
      if (claim && !epochToIsFinalizedMap[claim.epoch] && claim.proof) {
        acc.push(claim)
      }
      return acc
    }, [])

    return {
      loading: anyLoading,
      error: anyError,
      data: {
        unfinalizedClaims,
        allClaims: allClaims,
        maxEpoch: metadataQueryState.result?.maxEpochNumber,
      },
    }
  }, [
    allClaimsQueryState,
    allFinalizedClaimsQueryState,
    claimToken,
    seasonNumber,
    walletAddress,
    metadataQueryState.result?.maxEpochNumber,
  ])
}
